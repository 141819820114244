import React from "react";
import CustomBreadcrumb from "../../../../../../components/src/CustomBreadcrumb.web";
import { Box, Typography,Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchInput from "../../../../../../components/src/SearchInput.web";
import SelectedFolderLessonList from "./SelectedFolderLessonList.web";
import BasicPagination from "../../../../../../components/src/BasicPagination.web";
import Loader from "../../../../../../components/src/Loader.web";
import GenericButton from "../../../../../../components/src/GenericButton.web";
import AddFolderModal from "./AddFolderModal.web";
const useStyles = makeStyles({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
    width: "100%"
  },
  searchBarLayout: {
    display: "flex",
  },
  addFolderButtonLayout: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
});
const SelectedFolderView = (props: any) => {
  const classes = useStyles();
  const {
    locations,
    currentLocation,
    redirect,
    lessons,
    loading,
    moveOutLessonApi,
    deleteLessonFolder,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    openDeleteConfirmationModal,
    pageNoShowFolderLimit,
    handleShowFolderPageChange,
    pageNoShowFolder,
    showFolderLessonSearch,
    handleFolderLessonSearchInput,
    handleShowNotesIcon,
    notesList,
    showMoveOutOption,
    downloadLesson,
    editPractice,
    viewMyLibraryLesson,
    handleOpenEditModal,
    state,
    handleEditClassChange,
    handleEditStartDateChange,
    handleEditDueDateChange,
    handleLessonObjectiveChange,
    handleCloseEditModal,
    editFuncMyLibraryAssignment,
    editFuncMyLibraryQuiz,
    handleEditUploadIcon,
    editFuncMyLibraryLesson,
    showLessons,
    handleLessonNameChange,
    deliverLessonApi,
    selectedLessons,
    handleOpenAddFolderModal,
    openAddFolderModal,
    handleCloseAddFolderModal,
    handleChangeFolderInput,
    createLessonFolder,
    folderName,
    showLessonsForSelectedSubFolder,
    moveLessonToFolder,
    handleOpenFolderList,
    folderList,
    openFolderList,
    handleCloseFolderList,
    folderAnchorEl,
    handleSetFolderId,
    handleOpenMoveConfirmationPopUp,
    openMovePopup,
    handleCloseMoveConfirmationPopUp,
    handleFolderNameSearchInput,
    folderSearchInput,
    render,
    handleCloseSuccessPopup,
    handleOpenSuccessPopup,
    openMoveSuccessPopup
  } = props;
  return (
    <>
      <Box sx={{ px: "35px" }}>
        <Box sx={{ mt: "30px" }}>
          <CustomBreadcrumb
            locations={locations}
            destination={currentLocation}
            redirect={redirect}
          />
        </Box>
        <Box className={classes.addFolderButtonLayout}>
          <Box className={classes.searchBarLayout}>
            <SearchInput
              searchInput={showFolderLessonSearch}
              handleSearchInput={handleFolderLessonSearchInput}
              placeholder={"Search by name"}
            />
          </Box>
        { render!=="Selected subFolder"&& <GenericButton
            data-test-id="open-folder"
            type="COLORED"
            label="+ Add Folder"
            handleClick={() => handleOpenAddFolderModal("", "Add", "")}
            customStyle={{ width: "190px", height: "50px" }}
          />}
        </Box>
        {loading ? (
          <Loader loading />
        ) : (<>
          {!lessons.length ?
            (
              <Typography className={classes.noRecords}>
                No records found
              </Typography>
            ) : (
              <>
                <Box>
                  <SelectedFolderLessonList
                    lessons={lessons}
                    moveOutLessonApi={moveOutLessonApi}
                    deleteLessonFolder={deleteLessonFolder}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                    handleCloseDeleteModal={handleCloseDeleteModal}
                    openDeleteConfirmationModal={openDeleteConfirmationModal}
                    handleShowNotesIcon={handleShowNotesIcon}
                    notesList={notesList}
                    showMoveOutOption={showMoveOutOption}
                    downloadLesson={downloadLesson}
                    editPractice={editPractice}
                    viewMyLibraryLesson={viewMyLibraryLesson}
                    handleOpenEditModal={handleOpenEditModal}
                    state={state}
                    handleEditClassChange={handleEditClassChange}
                    handleEditStartDateChange={handleEditStartDateChange}
                    handleEditDueDateChange={handleEditDueDateChange}
                    handleLessonObjectiveChange={handleLessonObjectiveChange}
                    handleCloseEditModal={handleCloseEditModal}
                    editFuncMyLibraryQuiz={editFuncMyLibraryQuiz}
                    editFuncMyLibraryAssignment={editFuncMyLibraryAssignment}
                    handleEditUploadIcon={handleEditUploadIcon}
                    editFuncMyLibraryLesson={editFuncMyLibraryLesson}
                    showLessons={showLessons}
                    handleLessonNameChange={handleLessonNameChange}
                    deliverLessonApi={deliverLessonApi}
                    selectedLessons={selectedLessons}
                    handleOpenAddFolderModal={handleOpenAddFolderModal}
                    showLessonsForSelectedSubFolder={showLessonsForSelectedSubFolder}
                    moveLessonToFolder={moveLessonToFolder}
                    handleOpenFolderList={handleOpenFolderList}
                    folderList={folderList}
                    openFolderList={openFolderList}
                    handleCloseFolderList={handleCloseFolderList}
                    folderAnchorEl={folderAnchorEl}
                    handleSetFolderId={handleSetFolderId}
                    handleOpenMoveConfirmationPopUp={handleOpenMoveConfirmationPopUp}
                    openMovePopup={openMovePopup}
                    handleCloseMoveConfirmationPopUp={handleCloseMoveConfirmationPopUp}
                    handleFolderNameSearchInput={handleFolderNameSearchInput}
                    folderSearchInput={folderSearchInput}
                    handleCloseSuccessPopup={handleCloseSuccessPopup}
                    handleOpenSuccessPopup={handleOpenSuccessPopup}
                    openMoveSuccessPopup={openMoveSuccessPopup}
                  />
                </Box>
                <Box sx={{ px: "35px", my: "30px" }}>
                  <BasicPagination
                    handlePageChange={handleShowFolderPageChange}
                    pageLimit={pageNoShowFolderLimit}
                    pageNo={pageNoShowFolder}
                  />
                </Box>
              </>)}</>)}
              <Modal open={openAddFolderModal} onClose={handleCloseAddFolderModal}>
          <AddFolderModal
            data-test-id="folder-modal"
            handleCloseAddFolderModal={handleCloseAddFolderModal}
            handleChangeFolderInput={handleChangeFolderInput}
            createLessonFolder={createLessonFolder}
            folderName={folderName}
          />
        </Modal>
      </Box >
    </>
  );
};

export default SelectedFolderView;
