import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface Answer {
  answer: string;
  teacher_correct_answer?: string;
  correct_answer?: boolean | string;
  topic?: string;
}
interface StudentAnswer {
  type: string;
  id: string;
  attributes: {
    updated_at: string;
    account_id: number;
    assignment_id: number;
    assignment_stage_id: number;
    answers: Answer[][];
    correct_answer: boolean | string;
    created_at: string;
    };
}
interface AssignmentMarkupTool {
  type: string;
  id: string;
  attributes: {
    assignment_stage_name: string;
    assignment_stage_id: number;
    assignments_id: number;
    videos: (string | { id: number; url: string })[] | null;
    countdown_timer: number | null;
    videos_url: (string | null)[];
    description: (string | { topic: string; answer: string })[] | null;
    stage_number: string;
    question: string | null;
    assignment_sub_stage_name: string[] | null;
    pairs: Pair[];
    background_image: string | null;
    images: (string | { id: number; url: string })[] | null;
    questions: Question[] | null;
    title: string | null;
    canvas_data: any,
  };
}

interface Question {
  question: {
    assignment_markup_tools_id: number;
    id: number;
    updated_at: string;
    created_at: string;
    question: string;
  };
  question_background_image: string | null;
  options: Option[];
}

interface Pair {
  updated_at: string;
  lesson_markup_tools_id: number | null;
  pair_answer: string;
  quiz_markup_tools_id: number | null;
  pair_question: string;
  pair_correct_answer: string;
  order_no: number;
  id: number;
  assignment_markup_tools_id: number;
  created_at: string;
}

interface AssignmentResponse {
  data: AssignmentMarkupTool[];
  meta: {
    assignment_name: string;
  };
}
interface Questionss {
  question?: string;
  questionId?: string;
  isSubmit?: boolean;
  answer?: string;
}

interface GetAnswerStyleDataAnswer {
  topic: string;
  correct_answer: boolean | null;
  answer: string;
  teacher_correct_answer: string;
}

interface StudentAnswerForCondition {
  id: string;
  type: string;
  attributes: {
    assignment_id: number;
    updated_at: string;
    correct_answer: boolean | null;
    assignment_stage_id: number;
      answers: AnswerForCondition[];
      created_at: string;
      account_id: number;
    };
}

interface GetAnswerStyleData {
  base: AssignmentMarkupTool;
  answers?: GetAnswerStyleDataStudentAnswer[];
  subStage?: AssignmentMarkupTool;
}

interface Option {
  marked_as_right_answer: boolean;
  answer: string;
  assignment_mcq_questions_id: number;
  updated_at: string;
  id: number;
  created_at: string;
  order_no: number | null;
}

interface Feedback {
  type: "assignment_question";
  id: string;
  attributes: FeedbackAttributes;
}

interface AnswerForCondition {
  teacher_correct_answer: string;
  topic: string;
  answer: string;
  correct_answer: boolean | null;
}


interface FeedbackAttributes {
  created_at: string;
  description: string;
  account_id: number;
  updated_at: string;
  assignment_mcq_question_id: number;
}
interface GetAnswerStyleDataStudentAnswer {
  type: string;
  id: string;
  attributes: {
    account_id: number;
    assignment_id: number;
    created_at: string;
    correct_answer: boolean | null;
    assignment_stage_id: number;
    updated_at: string;
    answers: GetAnswerStyleDataAnswer[];
  };
}

export const configJSON = require("./config");

export interface Props {
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    slectedOption: Questionss;
    txtInputValue: string;
    txtSavedValue: string;
    allData: AssignmentMarkupTool[];
    newData: string[];
    rightArray: string[];
    dragItem: null | number;
    isSubmitForChart: boolean;
    enableField: boolean;
    dragSomething: boolean;
    dragSomethingSubmit: boolean;
    dragOverItem: null | number;
    authToken: string;
    currentStep: number;
    totalStep: null | number;
  inputValues: {
    [keys: string]: string;
  };
  allSelectedOption: { answer: string; isSubmit: boolean }[];
  isSubmitWordCloud: boolean;
  submitedAnswer: StudentAnswer[]
  inputValuesForChart: {
    topic?: string; answer: string
  }[];
  openEndedText: {
    topic: string; answer: string; isSubmit: boolean
  };
  pairsMatching: { topic: string; answer: string | null }[];
  trueAnswerForMcq: string | boolean;
 
  feedbackText: string;
  feedbackData: Feedback[];
  assignmentId: string | number;
  assignmentStatus: string;
  assignmentDueDate: string;
  allDataLoading: boolean;
  assignmentName: string;
  studentId: string | number | null;
  currentQuestionId: number | string | undefined;
  openModel: boolean;
  className: string;
  correctAnswerStatus: boolean;
  showCorrectAnswer: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    
    // Customizable Area End
}

export default class AssessmentViewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getStudentAssignmentId:string="";
    getCountryApiId:string="";
    getQuestionFeedbackApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.CountryCodeMessage)
      ];
      this.receive = this.receive.bind(this);
      runEngine.attachBuildingBlock(this, this.subScribedMessages);

      this.state = {
        txtInputValue: "",
        txtSavedValue: "A",
        newData: ["true", "false", "not sure"],
        slectedOption: {
          question: "",
          questionId: "",
          isSubmit: false,
          answer: "",
        },
        dragOverItem: null,
        rightArray: [],
        dragItem: null,
        dragSomething: false,
        allData: [],
        totalStep: null,
        openEndedText: { topic: "", answer: "", isSubmit: false },
        enableField: false,
        authToken: "",
        dragSomethingSubmit: false,
        allSelectedOption: [],
        isSubmitForChart: false,
        currentStep: 0,
        inputValuesForChart: [],
        pairsMatching: [],
        isSubmitWordCloud: false,
        openModel: false,
        submitedAnswer:[],
        inputValues: {},
        feedbackText:"",
        feedbackData: [],
        assignmentStatus: 'Pending',
        assignmentId: '',
        trueAnswerForMcq:"",
        assignmentName: '',
        assignmentDueDate: '13 Days Left',
        className: 'Class 1A Maths',
        studentId: '',
        currentQuestionId: '',
        correctAnswerStatus: false,
        allDataLoading: false,
        showCorrectAnswer: false,
        };
        // Customizable Area End


    }

    async receive(_from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
          let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    
          this.showAlert(
            "Change Value",
            "From: " + this.state.txtSavedValue + " To: " + value
          );
    
          this.setState({ txtSavedValue: value });
        }
    
        // Customizable Area Start
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        if (this.getStudentAssignmentId === apiRequestCallId) {
            this.setState({
            submitedAnswer: responseJson.data
            });
        } else if (this.getCountryApiId === apiRequestCallId) {
            this.getApiData(responseJson);
        } else if (this.getQuestionFeedbackApiCallId === apiRequestCallId){

          if(responseJson && responseJson.data && responseJson.data.length > 0){
            this.setState({feedbackText: responseJson?.data?.[responseJson?.data.length - 1]?.attributes?.description})
          }else {
            this.setState({feedbackText: 'No feedback available'})
          }
            
        }
        // Customizable Area End
    }

    // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    const auth = await getStorageData("authToken");

    this.setState({
      authToken: auth
    });

    const pathname = window.location.pathname;
    const lastSegment = pathname.substring(pathname.lastIndexOf('/') + 1);

    this.setState({
      assignmentId: lastSegment
    });

    const current_role = await getStorageData("role");
    if(current_role !== "Student"){
      const sRole = new Message(getName(MessageEnum.NavigationMessage));
      sRole.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
      sRole.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(sRole);
    }

    this.handleCountryApi(auth);

    let assignmentData = await getStorageData("assignmentData");
    assignmentData = JSON.parse(assignmentData);
    
    this.setState({
      assignmentStatus: assignmentData?.attributes?.assignment_status,
      className: assignmentData?.attributes?.class_name,
    });

    const studentId = window.localStorage.getItem("id");
    
    this.setState({ studentId });
  }

  setData = (data: Pair[]) => {
    if (this.state.rightArray.length < 1) {
      this.setState({
        rightArray: data.map((value) => value.pair_answer),
        pairsMatching: data.map(pair => ({ topic: pair.pair_question, answer: pair.pair_answer }))
      })}
  };


  handleCountryApi = (auth: string) => {
    this.setState({
      allDataLoading: true
    });
      
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: auth,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);

    this.getCountryApiId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.assignmentApiEndpoint + `?assignment_id=${this.state.assignmentId}`);

    runEngine.sendMessage(requestMessage.id, 
      requestMessage
    );
    return true;

  }

handleWordCloudValue = (answers: StudentAnswerForCondition[], index: number) => {
    return answers && answers[answers.length - 1] && answers[answers.length - 1].attributes && answers[answers.length - 1].attributes.answers && 
    answers[answers.length - 1].attributes.answers[index] && 
    answers[answers.length - 1].attributes.answers[index].answer
}



  getApiData = (responseJson: AssignmentResponse): void => {

    const validData = responseJson.data.filter(item => {const stageN = Number(item.attributes.stage_number);
      return !isNaN(stageN);
    });


    this.setState({
      allData: responseJson.data,
      totalStep: validData.length,
      allDataLoading: false,
      assignmentName: responseJson?.meta?.assignment_name
    }, () => { this.handelStudentApiget() });


  };


  handelBack = () => {
    this.setState(prevState => ({
      currentStep: Math.max(prevState.currentStep - 1, 0)
    }));
    
    this.setState({
      slectedOption: {
        question: "",
        questionId: "",
        isSubmit: false,
        answer: "",
      },
      inputValuesForChart: [],
      isSubmitWordCloud: false,
      isSubmitForChart: false,
      dragSomething: false,
      openEndedText: { topic: "", answer: "", isSubmit: false },
      dragSomethingSubmit: false,
    })
  };


  handelNext = () => {
    if (this.state.currentStep < this.state.allData.length - 1) {
      this.setState(prevState => ({
        currentStep: prevState.currentStep + 1
      }));
    }
    this.setState({
      slectedOption: {
        isSubmit: false,
        question: "",
        answer: "",
        questionId: "",
      },
      isSubmitForChart: false,
      dragSomethingSubmit: false,
      isSubmitWordCloud: false,
      dragSomething: false,
      openEndedText: { topic: "", answer: "", isSubmit: false },
      inputValuesForChart: [],
    })

  }

  handelStudentApiget = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authToken,
    };

    const verifyCodeSignMes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    verifyCodeSignMes.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`bx_block_assessmenttest/student_answer/show_assignment_answers?student_id=${this.state.studentId}&assignment_id=${this.state.assignmentId}`);

    verifyCodeSignMes.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

    verifyCodeSignMes.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET");

    this.getStudentAssignmentId = verifyCodeSignMes.messageId;

    runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);

  };

  addFeedback = (questionId?: number | string | undefined) => {
    this.setState({ openModel: true, currentQuestionId: questionId }, () => {
      this.getFeedbackForQuestion();
    });
  }

  setTypeForChart = (dataType: string) => {
    let types;

    if (dataType === "t_chart") {
      types = "T-Chart Question"
    } else if (dataType === "kwl_chart") {
      types = "KWL Chart Question"
    } else {
      types = "Seed Discussion Question"
    }
    return types
  };

  handleBackToViewList = () => {

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TaskList");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    
  }

  getFeedbackForQuestion = () => {

    const { currentQuestionId } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authToken,
    };

    const verifyCodeSignMes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    verifyCodeSignMes.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getFeedbackApiEndPoint}${currentQuestionId}&student_id=${this.state.studentId}`);

    verifyCodeSignMes.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

    verifyCodeSignMes.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);

    this.getQuestionFeedbackApiCallId = verifyCodeSignMes.messageId;

    runEngine.sendMessage(verifyCodeSignMes.id, 
      verifyCodeSignMes
    );
  }

  handleClose = () => {
    this.setState({ openModel: false, feedbackText: '' });
  };


   isAnswerMatch = (answer: string | string[] | undefined, targetAnswer: string) => {
    if (!answer) {
      return false; 
    }
    if (Array.isArray(answer)) {
      return answer.includes(targetAnswer);
    }
    return answer === targetAnswer;
  };

  getAnswerStyle = (valueAnswer: string, data: GetAnswerStyleData, type: string | undefined) => {
    let conditionss = data.answers && data.answers[0] && data.answers[0].attributes && data.answers[0].attributes.answers && data.answers[0].attributes.answers[0]
    const teacherAnswer = conditionss && conditionss.teacher_correct_answer;
    const studentAnswer = conditionss && conditionss.answer;


  if (this.isAnswerMatch(teacherAnswer, valueAnswer) || (type !== "MCQ" && this.isAnswerMatch(studentAnswer, valueAnswer))) {
        return {
          width: "92%", background: "#00A52C", 
          borderRadius: "20px",
           position: "relative" as "relative",
          margin: "20px 0px", 
          padding: "15px",
           fontSize: "14px", fontWeight: 500, color: "#fff",
          cursor: "pointer",
           border: "2px solid #00A52C",
          fontFamily: "Poppins", 
          lineHeight: "16px"
        }
    } else if (valueAnswer !== teacherAnswer && valueAnswer !== studentAnswer) {
        return {
          width: "92%", 
          border: "1px solid #2B3D7D", 
          borderRadius: "20px",
          
          margin: "20px 0px",
           padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
          cursor: "pointer",

          fontFamily: "Poppins", 
          lineHeight: "16px"
        };
    } else {
        return {
          width: "92%", border: "1px solid #FF0000",
           borderRadius: "20px",
          margin: "20px 0px",
           padding: "15px", fontSize: "14px", 
           fontWeight: 500, color: "#fff",
          position: "relative" as "relative",
          background: "#FF0000",
          cursor: "pointer",
          fontFamily: "Poppins", lineHeight: "16px"
        }
    }
};

  getInnerHtml = (answers: StudentAnswerForCondition[], currentIndex: number) => {
    let validAnswer = answers && answers[answers.length - 1] && answers[answers.length - 1].attributes && 
    answers[answers.length - 1].attributes  && answers[answers.length - 1].attributes.answers && 
    answers[answers.length - 1].attributes.answers[currentIndex] && 
    answers[answers.length - 1].attributes.answers[currentIndex].answer

    return validAnswer || ''
  }

  handleTitle = (text: string | null) => {
    return text || ''
  }

  checkIsCorrectAns = (data: GetAnswerStyleData, answer: string) => {
    let conditionss = data.answers && data.answers[0] && data.answers[0].attributes && data.answers[0].attributes.answers && data.answers[0].attributes.answers[0]
    let isCorrect = false;

    if((answer === (conditionss && conditionss.teacher_correct_answer)) && (answer === (conditionss && conditionss.answer))){
      isCorrect = true;
    }

    return isCorrect;
  }

  handleQuestionSubValidation = (data: GetAnswerStyleData) => {
    return data 
    && data.subStage 
    && data.subStage.attributes 
    && data.subStage.attributes.questions 
    && data.subStage.attributes.questions[0] 
    && data.subStage.attributes.questions[0].options
  }

  handleQuestionValidation = (data: GetAnswerStyleData) => {
    return data 
    && data.base 
    && data.base.attributes 
    && data.base.attributes.questions 
    && data.base.attributes.questions[0] 
    && data.base.attributes.questions[0].options
  }

  commonCanvasImageCondition = (assignmentData: AssignmentMarkupTool) => {
    let canvasData = assignmentData && assignmentData.attributes.canvas_data && JSON.parse(assignmentData.attributes.canvas_data)
    let canvas = canvasData && JSON.parse(canvasData.canvas)
    let canvasSize = canvasData && canvasData.canvasSize;
    return { canvas, canvasSize }
  }

  textQuestionCanvasConditions = (data: GetAnswerStyleData) => {
    let canvasData = data.base && data.base.attributes && data.base.attributes.canvas_data
    const parsedCanvasData = typeof canvasData == "object"? canvasData : JSON.parse(canvasData);

    let myCanvas = parsedCanvasData && typeof parsedCanvasData == "object" ?  JSON.parse(parsedCanvasData.canvas) : parsedCanvasData.canvas; 
    let myCanvasSize = canvasData && canvasData.canvasSize;

    let descCanva = parsedCanvasData && typeof parsedCanvasData == "object" ?  JSON.parse(parsedCanvasData.AddTextCanvas) : parsedCanvasData.AddTextCanvas; 
    let descCanvasSize = canvasData && canvasData.addTextCanvasSize;
    
    let canvasDataForSub = data.subStage && data.subStage.attributes && JSON.parse(data.subStage.attributes.canvas_data)
    let canvasSub = canvasDataForSub && JSON.parse(canvasDataForSub.canvas)
    let canvasSizeSub = canvasDataForSub && canvasDataForSub.canvasSize;

    return { myCanvas, myCanvasSize, descCanva, descCanvasSize, canvasSub, canvasSizeSub, };
  }

  canvasParseCondition = (assignmentData: AssignmentMarkupTool) => {
    let canvasDatas = assignmentData && assignmentData.attributes && assignmentData.attributes.canvas_data
    const parsedDatas = canvasDatas && typeof canvasDatas == "object"? canvasDatas : JSON.parse(canvasDatas);
  
    let canvass = parsedDatas && JSON.parse(parsedDatas.canvas)
    let canvassSize = parsedDatas && parsedDatas.canvasSize;
    return { canvass, canvassSize }
  }
    // Customizable Area End
}
