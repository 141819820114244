import React from "react";
// Customizable Area Start
import {
    Grid,
    styled,
    Box,
    Button,
    Typography,
    Dialog,
    DialogContent,
    IconButton,
    Modal, Backdrop, Fade
} from "@material-ui/core";

import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { infoIcon, leftSideIcon, RightSideIcon, studentSubmittedIcon } from './assets'
import { constructYoutubeUrlFromVideoId, extractVideoIdFromThumbnailUrl } from "../../../components/src/Utils.web";
import { closeIcon, tickIcon } from "../../MultipageForms2/src/assets";


interface Question {
    question_background_image: string | null;
    options: Option[];
    question: {
        assignment_markup_tools_id: number;
        updated_at: string;
        question: string;
        created_at: string;
        id: number;
    };
};
interface Pair {
    pair_correct_answer: string;
    id: number;
    order_no: number;
    lesson_markup_tools_id: number | null;
    pair_answer: string;
    quiz_markup_tools_id: number | null;
    pair_question: string;
    created_at: string;
    assignment_markup_tools_id: number;
    updated_at: string;
}
  
interface Option {
    answer: string;
    order_no: number | null;
    created_at: string;
    id: number;
    marked_as_right_answer: boolean;
    assignment_mcq_questions_id: number;
    updated_at: string;
}

interface AssignmentMarkupTool {
    type: string;
    id: string;
    attributes: {
        stage_number: string;
        assignments_id: number;
        assignment_stage_id: number;
        assignment_sub_stage_name: string[] | null;
        title: string | null;
        videos_url: (string | null)[];
        question: string | null;
        description: (string | { topic: string; answer: string })[] | null;
        assignment_stage_name: string;
        questions: Question[] | null;
        countdown_timer: number | null;
        background_image: string | null;
        canvas_data: any;
        pairs: Pair[];
        videos: (string | { id: number; url: string })[] | null;
        images: (string | { id: number; url: string })[] | null;
    };
}

interface Answer {
    topic: string;
    answer: string;
    question?: string;
    correct_answer: boolean | null;
    teacher_correct_answer: string;
}

interface StudentAnswer {
    id: string;
    type: string;
    attributes: {
        assignment_stage_id: number;
        account_id: number;
        assignment_id: number;
        correct_answer: boolean | null;
        answers: Answer[];
        created_at: string;
        updated_at: string;
    };
}
interface ProcessedData {
    base: AssignmentMarkupTool;
    subStage?: AssignmentMarkupTool;
    answers?: StudentAnswer[];
}
  
  const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
import { SuccessSnackbar } from "../../../components/src/CustomSuccessPopup.web";
import StaticCanvas from "../../../components/src/StaticCanvas.web";
// Customizable Area End

import AssessmentTableController, {
    Props,
} from "./AssessmentTableController.web";

// Customizable Area Start
export default class AssessmentTable extends AssessmentTableController {
    constructor(props: Props) {
        super(props);
    }

    textQuestion = (data: ProcessedData) => {
        const { canvass, canvassSize, descCanvass, descCanvassSize, canvassSub, canvassSizeSub, } = this.textQuestionConditionCanva(data);

        return (
            <>
                <Box sx={webStyle.mainWrapper}>
                    <Grid container style={webStyle.mainWrapperContainers as React.CSSProperties}>
                        <Grid item  style={webStyle.mainWrapperRightSide}>
                            <Typography style={webStyle.textQuesTitle} dangerouslySetInnerHTML={{
                                __html: data.base.attributes.title || '',
                            }}/>
                            
                            {canvass && <StaticCanvas canvasData={canvass} id="canvasId" canvasSize={canvassSize ? JSON.parse(canvassSize) : undefined}/>}
                            
                            <br />
                            <Typography
                                style={webStyle.textQuesDescription}
                                dangerouslySetInnerHTML={{
                                    __html: typeof data?.base?.attributes?.description?.[0] === 'string' && data.base.attributes.description?.[0] || ''
                                }}
                            />
                            
                            {descCanvass && <StaticCanvas canvasData={descCanvass} id="canvasId" canvasSize={descCanvassSize ? JSON.parse(descCanvassSize) : undefined}/>}

                        </Grid>
                        {
                            data?.subStage && data?.subStage?.attributes.assignment_stage_name === "MCQ" && <Box style={webStyle.addMultimediaMcq} >
                                <Typography style={webStyle.firstHedingTexts as React.CSSProperties}>Text / Passage Question</Typography>
                                <Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{__html: data?.subStage.attributes?.questions?.[0]?.question?.question || ''}}/>
                                
                                {canvassSub && <StaticCanvas canvasData={canvassSub} id="canvasId" canvasSize={canvassSizeSub ? JSON.parse(canvassSizeSub) : undefined}/>}

                                {this.renderOptions(data)}
                                <Button data-test-id="btnAddFeedbackTextQuestion" style={webStyle.submitButtonActive as React.CSSProperties} onClick={()=>this.addFeedback(data?.subStage && data?.subStage.attributes?.questions?.[0]?.question?.assignment_markup_tools_id)}>Feedback</Button>
                            </Box>
                        }
                        {
                            data?.subStage && data?.subStage?.attributes.assignment_stage_name === "open_ended_question" && 
                            this.openEndedQuestion(data.subStage, data.answers, true)
                        }
                    </Grid>
                </Box>
            </>
        );
    };

    wordCloud = (data: AssignmentMarkupTool, answers: StudentAnswer[]) => {
        const { canvasData, canvasDataSize } = this.canvasConditionParsed(data)
        return (
            <Box sx={webStyle.mainWrapper}>
                <Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}>

                    <Grid item style={webStyle.mainContainersWidth}>
                        <Grid style={webStyle.mainWrapperChartQuestion}>
                            <Grid style={webStyle.firstTextGrids}>
                                <Typography style={webStyle.firstHedingTexts}>Word Cloud</Typography>
                            </Grid>
                            <Grid>
                                <Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{
                                    __html: data.attributes.title || '',
                                }}/>
                            </Grid>
                            {canvasData && <StaticCanvas canvasData={canvasData} id="canvasId" canvasSize={canvasDataSize ? JSON.parse(canvasDataSize) : undefined}/>}
                           
                            {data.attributes.description && data.attributes.description.map((value, index) => (
                                <Grid item key={index}>
                                    {Object.keys(value).map((keys, subIndex) => (
                                        <input
                                            disabled
                                            key={subIndex}
                                            style={webStyle.optionForInput}
                                            placeholder={keys}
                                            value={this.handleWordCloudValue(answers, index) || ''}
                                        />
                                    ))}
                                </Grid>
                            ))} <Grid item>
                                    <Button style={webStyle.submitButtonActive as React.CSSProperties
                                       } 
                                       data-test-id="btnAddFeedbackwordCloud"
                                       onClick={()=>this.addFeedback(data.id)}
                                    >Feedback</Button>
                                </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Box>
        )
    }


    chartQuestion = (data: AssignmentMarkupTool, answers: StudentAnswer[]) => {
        let dataType = data.attributes.assignment_stage_name
        const { canvasData, canvasDataSize } = this.canvasConditionParsed(data)

        let type = this.setTypeForChart(dataType)

        return (
            <Box sx={webStyle.mainWrapper}>
                <Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}>

                    <Grid item style={webStyle.mainContainersWidth}>
                        <Grid style={webStyle.mainWrapperChartQuestion}>
                            <Grid style={webStyle.firstTextGrids}>
                                <Typography style={webStyle.firstHedingTexts}>{type}</Typography>
                            </Grid>
                            <Grid><Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{ __html: this.handleEmptyTitle(data.attributes.title)}}/></Grid>
                            {canvasData && <StaticCanvas canvasData={canvasData} id="canvasId" canvasSize={canvasDataSize ? JSON.parse(canvasDataSize) : undefined}/>}
                            
                            {data.attributes.description && 
                                data.attributes.description.map((value: string | { topic: string; answer: string }, index: number) => (
                                <Grid item key={index}>
                                    {
                                        typeof value !== 'string' &&
                                        <>
                                            <Typography style={webStyle.chartQuestion} dangerouslySetInnerHTML={{ 
                                                __html: value.topic }}
                                            />
                                            <Typography 
                                                style={webStyle.whChartQuestionAns as React.CSSProperties}
                                                dangerouslySetInnerHTML={{ __html: this.getInnerHtml(answers, index),}} 
                                            />
                                        </>
                                    }
                                </Grid>
                            ))}
                            <Grid item>
                                    <Button style={ webStyle.submitButtonActive as React.CSSProperties} 
                                    onClick={()=>this.addFeedback(data.id)}
                                    data-test-id="BtnSendFeedbackChartQues"
                                    >Feedback</Button>
                                </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Box>
        )
    }

    mcqs = (data: ProcessedData) => {
        const { myCanva, canvaSize } = this.canvasImageCommonCondition(data.base)

        return (
            <Box sx={webStyle.mainWrapper}>
                <Grid justifyContent="center" alignItems="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}>

                    <Grid item style={webStyle.mainContainersWidth}>
                        <Grid style={webStyle.mainWrapperForWordsCloud}>
                            <Grid style={webStyle.firstTextGrids}><Typography style={webStyle.firstHedingTexts}>{data.base.attributes.assignment_stage_name === "MCQ" ? "Multiple Choice Question" : "Polling Question"}</Typography></Grid>
                            <Grid><Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{ __html: data.base.attributes.questions && data.base.attributes.questions[0].question.question || '',}}/></Grid>
                            
                            {myCanva && <StaticCanvas canvasData={myCanva} id="canvasId" canvasSize={canvaSize ? JSON.parse(canvaSize) : undefined}/>}

                            {data.base.attributes.questions && data.base.attributes.questions[0].options.map((value, index) => (
                                <Grid key={index}
                                    style={
                                        this.getMcqPollingStyle(value.answer, data, data.base.attributes.assignment_stage_name)
                                    }
                                >
                                    {
                                        value.answer
                                    }
                                    {(Array.isArray(data.answers?.[0]?.attributes?.answers?.[0]?.answer)
                                        ? data.answers?.[0]?.attributes?.answers?.[0]?.answer.includes(value.answer)
                                        : value.answer === data.answers?.[0]?.attributes?.answers?.[0]?.answer) && (
                                        <Grid style={webStyle.containerForIcon as React.CSSProperties}>
                                            <img src={studentSubmittedIcon} alt="profileIcon" style={webStyle.icoStyle as React.CSSProperties} />
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                           
                                    <Grid item>
                                        <Button
                                            style={
                                                webStyle.submitButtonActive as React.CSSProperties
                                            }
                                            data-test-id='btnSendFeedbackMcq'
                                            onClick={()=>this.addFeedback(data?.base && data?.base.attributes?.questions?.[0]?.question?.assignment_markup_tools_id)}
                                        >
                                            Feedback
                                        </Button>
                                    </Grid>
                                
                        </Grid>
                    </Grid>

                </Grid>
            </Box>
        );
    };


    dragDrop = (data: AssignmentMarkupTool, answers: StudentAnswer[]) => {

        const dataForPair = data.attributes.pairs
        const { myCanva, canvaSize } = this.canvasImageCommonCondition(data)

        return (
            <PropertyDeveloperCss>
                <Box sx={webStyle.mainWrapper}>
                    <Grid justifyContent="center" alignItems="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}>
                        <Grid style={webStyle.mainContainerWidthDrag}><Typography style={webStyle.firstHedingTexts} >

                            You can drag the options in column 'B' with the correct matching. Match column 'A' with its corrected pairs of column 'B'</Typography>
                            <Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{__html: data.attributes.title || ''}}/>

                            {myCanva && <StaticCanvas canvasData={myCanva} id="canvasId" canvasSize={canvaSize ? JSON.parse(canvaSize) : undefined}/>}

                            <Box>
                                <Box className="headingLayout">
                                    <Box className="heading">A</Box>
                                    <Box className="heading">B</Box>
                                </Box>
                                <Grid container justifyContent="space-between" spacing={3}>
                                    <Grid item md={6}>
                                        {dataForPair.map((question, index) => (
                                            <Box key={index} className="matchingBoxA">
                                                {question.pair_question}
                                            </Box>
                                        ))}
                                    </Grid>
                                    <Grid item md={6}>
                                        {answers?.[0]?.attributes?.answers.map((question, index) => (
                                            <Box
                                                key={index}
                                                className={
                                                    (question && question.answer) === (question && question.teacher_correct_answer) 
                                                    ? 'matchingBoxBCorrect' 
                                                    : 'matchingBoxBWrong'
                                                }
                                            >
                                                {question.answer}
                                            </Box>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    
                                            <Grid item>
                                                <Button
                                                    
                                                    style={
                                                        webStyle.submitButtonActive as React.CSSProperties
                                                           
                                                    }
                                                    onClick={()=>this.addFeedback(data.id)}
                                                    data-test-id='btnSendFeedbackDragDrop'
                                                >
                                                    Feedback
                                                </Button>
                                            </Grid>
                                       
                                </Grid>
                            </Box></Grid>
                    </Grid>
                </Box>
            </PropertyDeveloperCss>
        )
    };

    whChartQuestion = (data: AssignmentMarkupTool, answers: StudentAnswer[]) => {
        const { canvasData, canvasDataSize } = this.canvasConditionParsed(data)
        return (
            <Box sx={webStyle.mainWrapper}>
                <Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}>

                    <Grid item style={webStyle.mainContainerWidthSec}>
                        <Grid style={webStyle.mainWrapperChartQuestion}>
                            <Grid style={webStyle.firstTextGrids}>
                                <Typography style={webStyle.firstHedingTexts}>WH-Chart Question</Typography>
                            </Grid>
                            <Grid><Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{ __html: this.handleEmptyTitle(data.attributes.title),}}/></Grid>

                            {canvasData && <StaticCanvas canvasData={canvasData} id="canvasId" canvasSize={canvasDataSize ? JSON.parse(canvasDataSize) : undefined}/>}

                            <Box 
                                m={-2}
                                display="flex"
                                flexWrap="wrap"
                            >
                                {data.attributes.description && data.attributes.description.map((item, index) => {
                                    if (typeof item !== 'string') {
                                        return (
                                            <Box 
                                                style={{
                                                    flexBasis: 'calc(50% - 16px)',
                                                    display: 'flex',
                                                    justifyContent: 'end',
                                                    flexDirection: 'column',
                                                    margin: 8,
                                                    padding: '0 8px 0 8px',
                                                    boxSizing: 'border-box',
                                                }}
                                                key={index}
                                            >
                                                <Typography   style={webStyle.chartQuestion as React.CSSProperties}
                                                    dangerouslySetInnerHTML={{ __html: item.topic}}/>
                                                <Typography 
                                                    style={webStyle.whChartQuestionAns as React.CSSProperties}
                                                    dangerouslySetInnerHTML={{ __html: this.getInnerHtml(answers, index)}} 
                                                />
                                            </Box>
                                        );
                                    }
                                    return null;
                                })}
                            </Box><Grid item>
                                    <Button data-test-id="btnSendFeedbackWhChart" style={webStyle.submitButtonActive as React.CSSProperties
                                        } onClick={()=>this.addFeedback(data.id)} >Feedback</Button>
                                </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Box>
        )
    };

    openEndedQuestion = (data: AssignmentMarkupTool, answers?: StudentAnswer[], isSubStage?: boolean) => {
        const { myCanva, canvaSize } = this.canvasImageCommonCondition(data)
        return (
            <Box style={!isSubStage ? webStyle.openEndedWrapper : webStyle.openEndedBlankWrapper}>
                <Box style={isSubStage ? webStyle.openEndedMainBoxSubStage : webStyle.openEndedMainBoxStage} >
                    <Typography style={webStyle.firstHedingTexts}>Open-Ended Question</Typography>
                    <Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{ __html: data.attributes.title || '',}}/>

                    {myCanva && <StaticCanvas canvasData={myCanva} id="canvasId" canvasSize={canvaSize ? JSON.parse(canvaSize) : undefined}/>}

                    <Typography style={webStyle.openEndedAns as React.CSSProperties} dangerouslySetInnerHTML={{ __html: answers?.[0]?.attributes?.answers?.[0]?.answer || '',}}/>
                    <Button style={webStyle.submitButtonActive as React.CSSProperties} onClick={()=>this.addFeedback(data.id)}data-test-id='btnAddFeedbackOpenEnded'>Feedback</Button>
                </Box>
            </Box>
        )
    };

    renderMediaContent = (data: AssignmentMarkupTool) => {
        const {attributes} = data;
        const videoID = attributes?.videos_url && attributes.videos_url[0] != null && extractVideoIdFromThumbnailUrl(attributes.videos_url[0]);
        const youtubeUrl = videoID ? constructYoutubeUrlFromVideoId(videoID) : 'defaultFallbackUrl';
        
        const imageUrl = attributes?.images && attributes.images.length > 0 && typeof attributes.images[0] !== 'string' && attributes.images[0]?.url;
        const videoUrl = attributes?.videos && attributes.videos.length > 0 && typeof attributes.videos[0] !== 'string' && attributes.videos[0]?.url;

        return (
            <Box style={webStyle.addTextLayout} >
                {
                    imageUrl && <img
                        data-test-id="img-preview"
                        src={imageUrl}
                        width="100%"
                        height="100%"
                    />
                }
                {
                    videoUrl && <video
                        data-test-id="video"
                        width="100%"
                        controls
                    >
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                }
                {
                    videoID && <iframe
                        data-test-id="youtube"
                        width="100%"
                        height="100%"
                        src={youtubeUrl.replace('watch?v=', 'embed/')}
                        title="YouTube Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                }
            </Box>
        )
    };

    addMultimedia = (data: ProcessedData) => {
        let multimedia_canvas_data = data.subStage &&  data.subStage.attributes.canvas_data && JSON.parse(data.subStage.attributes.canvas_data)
        let multimedia_canvas = multimedia_canvas_data && JSON.parse(multimedia_canvas_data.canvas)
        let canvas_size = multimedia_canvas_data && multimedia_canvas_data.canvasSize
        const { myCanva, canvaSize } = this.canvasImageCommonCondition(data.base)

        return (
            <>
                <Box sx={webStyle.mainWrapper}>
                    <Grid container style={webStyle.mainWrapperContainers as React.CSSProperties}>
                        <Grid style={webStyle.mainWrapperRightSide}>
                            <Typography style={webStyle.videoPreviewTitle}>{data && data.base && data.base.attributes && data.base.attributes.title}</Typography>
                            {myCanva && <StaticCanvas canvasData={myCanva} id="canvasId" canvasSize={canvaSize ? JSON.parse(canvaSize) : undefined}/>}

                            {this.renderMediaContent(data.base)}
                        </Grid>
                        {
                            data?.subStage && data?.subStage?.attributes.assignment_stage_name === "MCQ" &&
                            <Box style={webStyle.addMultimediaMcq} >
                                <Typography style={webStyle.firstHedingTexts as React.CSSProperties}>Text / Passage Question</Typography>
                                <Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{
                                    __html: data?.subStage.attributes?.questions?.[0]?.question?.question || ''
                                }}/>
                                
                                {canvas_size && <StaticCanvas canvasData={multimedia_canvas} id="canvasId" canvasSize={canvas_size ? JSON.parse(canvas_size) : undefined}/>}

                                {this.renderOptions(data)}
                                <Button 
                                    data-test-id="btnSendFeedbackAddMultimedia"
                                    style={webStyle.submitButtonActive as React.CSSProperties}
                                    onClick={()=>this.addFeedback(data?.subStage && data?.subStage.attributes?.questions?.[0]?.question?.assignment_markup_tools_id)}
                                >
                                    Feedback
                                </Button>
                            </Box>
                        }
                        {data?.subStage && data?.subStage?.attributes.assignment_stage_name === "open_ended_question" && this.openEndedQuestion(data.subStage, data.answers, true)}
                        
                    </Grid>
                </Box>
            </>
        );
    };



    vennDiagram = (data: AssignmentMarkupTool, answers: StudentAnswer[]) => {
        const { canvasData, canvasDataSize } = this.canvasConditionParsed(data)

        return (
            <Box sx={webStyle.mainWrapper}>
                <Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}>

                    <Grid item style={webStyle.mainContainersWidth}>
                        <Grid style={webStyle.mainWrapperChartQuestion}>
                            <Grid style={webStyle.firstTextGrids}>
                                <Typography style={webStyle.firstHedingTexts}>Venn Diagram Question</Typography>
                            </Grid>
                            <Grid>
                                <Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{ __html: this.handleEmptyTitle(data.attributes.title),}}/>
                            </Grid>
                            {canvasData && <StaticCanvas canvasData={canvasData} id="canvasId" canvasSize={canvasDataSize ? JSON.parse(canvasDataSize) : undefined}/>}
                            {data.attributes.description && data.attributes.description.map((value: string | { topic: string; answer: string }, index: number) => (
                                <Grid item key={index}>
                                    {typeof value !== 'string' &&
                                        <>
                                            <Typography style={webStyle.chartQuestion}>{`Answer ${index + 1}`}</Typography>
                                            <Typography 
                                                style={webStyle.whChartQuestionAns as React.CSSProperties}
                                                dangerouslySetInnerHTML={{ __html: this.getInnerHtml(answers, index),}} 
                                            />

                                        </>
                                    }
                                </Grid>
                            ))}
                            <Grid item>
                                <Button data-test-id='btnSendFeedbackVenDiagram' style={ webStyle.submitButtonActive as React.CSSProperties} onClick={()=>this.addFeedback(data.id)} >Feedback</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    renderOptions = (data: ProcessedData) => {
        return data.subStage && data.subStage.attributes?.questions?.[0]?.options.map((value, index) => (
            <Grid key={index} style={this.getMcqPollingStyle(value.answer, data, data.subStage && data.subStage.attributes.assignment_stage_name)}
            >
                {value.answer}
                {value.answer === data.answers?.[0]?.attributes?.answers?.[0]?.answer && <Grid style={webStyle.containerForIcon as React.CSSProperties}>
                        <img src={studentSubmittedIcon} alt="profileIcon" style={webStyle.icoStyle as React.CSSProperties} />
                    </Grid>
                }
            </Grid>
        ));
    };

    stepper = () => {
        const { allData, currentStep } = this.state;
        if (!allData.length) return true;

        const processData = (data: AssignmentMarkupTool[]): ProcessedData[] => {
            const getNumericPart = (stageNumber: string): number => 
                parseInt(stageNumber.match(/\d+/)?.[0] || '0', 10);
        
            data.sort((a, b) => {
                const numB = getNumericPart(b.attributes.stage_number);
                const numA = getNumericPart(a.attributes.stage_number);
                return numA - numB;
            });
            const combinedData: { [key: string]: AssignmentMarkupTool[] } = {};

            data.forEach((item) => {
                
                const { stage_number } = item.attributes;
                    const numericPart = getNumericPart(stage_number).toString();
        
                if (!combinedData[numericPart]) {combinedData[numericPart] = []}
                combinedData[numericPart].push(item);
            });
            const mainArray: ProcessedData[] = [];
        
            for (const combinedDataKey in combinedData) {
                const subStages = combinedData[combinedDataKey].filter(stage => !Boolean(Number(stage.attributes.stage_number)));
                const baseStages = combinedData[combinedDataKey].filter(stage => Boolean(Number(stage.attributes.stage_number)));
        
                baseStages.forEach(base => {mainArray.push({base: base});subStages.forEach(subStage => {mainArray.push({base: base,subStage: subStage})});});

                if (subStages.length > 0 && baseStages.length === 0) {subStages.forEach(subStage => {mainArray.push({base: subStage})})}
            }
            return mainArray;
        };
        const processedData = processData(allData);


        const finalData: Array<{
            base: any['base'];
            subStage?: AssignmentMarkupTool;
            answers: any[];
        }> = processedData.map(assignment => {
            const stageId = assignment.base.attributes.assignment_stage_id;
            const subStageId = assignment.subStage?.attributes.assignment_stage_id;

            const sortedAnswers = this.state.submitedAnswer.slice().reverse()

            const answers = sortedAnswers && sortedAnswers.filter(answer =>
                answer.attributes.assignment_stage_id === stageId ||
                (subStageId !== undefined && answer.attributes.assignment_stage_id === subStageId)
            );
        
            return {
                base: assignment.base,
                subStage: assignment.subStage,
                answers
            };
        });
        
        const currentData = finalData[currentStep];
        const dataType = currentData.base.attributes.assignment_stage_name;

        switch (dataType) {
            case "add_text":
                return this.textQuestion(currentData);
            case "MCQ":
                return this.mcqs(currentData);
            case "open_ended_question":
                return this.openEndedQuestion(currentData.base, currentData?.answers);
            case "add_multimedia":
                return this.addMultimedia(currentData);
            case "matching_question":
                this.setData(currentData.base.attributes.pairs)
                return this.dragDrop(currentData.base, currentData.answers);
            
            case "kwl_chart":
                return this.chartQuestion(currentData.base, currentData.answers);
            case "t_chart":
                return this.chartQuestion(currentData.base, currentData.answers);
            case "seed_discussion":
                return this.chartQuestion(currentData.base, currentData.answers);
            case "wh_chart":
                return this.whChartQuestion(currentData.base, currentData.answers);
            
            case "venn_diagram":
                return this.vennDiagram(currentData.base, currentData?.answers);
            case "polling_question":
                return this.mcqs(currentData);
            case "add_word_cloud":
                return this.wordCloud(currentData.base, currentData.answers);
                
            default:
                return true;
        }
    }
// Customizable Area End

    render() {
        // Customizable Area Start
        const { assignmentName, assignmentDueDate, assignmentStatus, className } = this.state;
        // Customizable Area End
            return (
                // Customizable Area Start
                <>
                <SuccessSnackbar
                    message={'Your 2GB storage limit has been reached. Please delete few files to refresh your limit.'} 
                    open={this.state.isLimitModalOpen} 
                    onClose={this.handleCloseSnack} 
                    type={'error'}
                />

                <Modal
          open={this.state.openModel}
          onClose={this.handleClose}
          data-test-id="handleClose"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openModel}>
            <div
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                width: '25%',
                backgroundColor: 'white',
                padding: '16px',
                boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                outline: 'none',
                borderRadius: '8px',
              }}
            >
                <h2>Feedback</h2>
                <textarea
                    value={this.state.feedbackText}
                    rows={18}
                    style={{width:"100%", resize:"none", padding:"3%", margin:"0px 0px 10px"}}
                    placeholder={this.state.feedbackPlaceHolder} 
                    onChange={this.handelFeedbackWrite} 
                    data-test-id="handelFeedbackWrite">
                </textarea>
                <Button data-test-id="btnSendQuestionFeedback" variant="contained" style={ webStyle.submitButtonActive as React.CSSProperties} onClick={this.handleSendQuestionFeedback}>Save</Button>
            </div>
          </Fade>
        </Modal>
                    <Grid>
                        <Grid container justifyContent="space-between" alignItems="center" style={webStyle.hedingContainer}>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item ><ArrowBackIosIcon data-test-id="btnBack" onClick={()=> this.handleBackToViewList()} style={webStyle.firstHedingIcon} /></Grid>
                                    <Grid item style={webStyle.firstHedingTexts}>{assignmentName}</Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Grid item style={webStyle.secHedingText}>Class</Grid>
                                            <Grid item style={webStyle.firstHedingTexts}>{className}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={webStyle.hedingBlock}>
                                        <Grid container direction="column">
                                            <Grid item style={webStyle.secHedingText}>Due date</Grid>
                                            <Grid item><Grid container alignItems="center"><QueryBuilderIcon style={webStyle.firstHedingIcons} /><Typography style={webStyle.firstHedingTexts}>{assignmentDueDate} Days Left</Typography></Grid></Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={webStyle.hedingBlock}>
                                        <Grid container direction="column">
                                            <Grid item style={webStyle.secHedingText}>Status</Grid>
                                            <Grid item style={webStyle.firstHedingTexts}>{assignmentStatus === 'complete' ? 'Completed' : 'Pending'}</Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        assignmentStatus !== 'complete' && <Grid item style={webStyle.hedingBlock}>
                                            <Button data-test-id='btnCheckFeedbackValidation' onClick={() => this.handleSendFeedbackValidation(false)} style={webStyle.buttonBeforeClick as React.CSSProperties}>Send Feedback</Button>
                                        </Grid>
                                    }
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>{this.stepper()}</Grid>
                    {
                        !this.state.allDataLoading && 
                        <Grid container alignItems="center" justifyContent="center" style={webStyle.pginationGrid as React.CSSProperties}>
                            <Grid item>
                                <img style={webStyle.cursorPointer} src={leftSideIcon} data-test-id="handelBack" onClick={this.handelBack} />
                            </Grid>
                            <Grid item style={webStyle.paginationText}>{`${this.state.allData[this.state.currentStep]?.attributes?.stage_number}/${this.state.totalStep}`}</Grid>
                            <Grid item>
                                <img style={webStyle.cursorPointer} src={RightSideIcon} data-test-id="handelNext" onClick={this.handelNext} />
                            </Grid>
                        </Grid>
                    }

                    <BootstrapDialog
                        onClose={this.handleCloseAlertModal}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.isAlertModalOpen}
                    >
                        <IconBtn
                            aria-label="close"
                            data-test-id='btnCloseAlertModal'
                            onClick={this.handleCloseAlertModal}
                        >
                        <img src={closeIcon} alt="close_icon" />
                        </IconBtn>
                        
                        <DialogContent dividers>
                            <img style={webStyle.infoIcon} src={infoIcon} alt="" />
                            <Typography style={webStyle.doYouWantToSendTxt} >Do you want to send your feedback now?</Typography>
                            <Typography style={webStyle.alertModalAssignName} >{assignmentName}</Typography>
                            <Box style={webStyle.alertBtnBox} >
                                <button data-test-id='btnSendFeedbackNow' onClick={() => this.handleSendFeedbackValidation(true)} style={webStyle.sendNowBtn} >Send Now</button>
                                <button data-test-id='btnSaveAndContinue' onClick={this.handleDoneAfterSendFeedback} style={webStyle.saveAndContiBtn} >Save & Continue Later</button>
                            </Box>
                        </DialogContent>
                    </BootstrapDialog>

                    <BootstrapDialog
                        onClose={this.handleCloseSuccessModal}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.isMainFeedModalOpen}
                    >
                        <IconBtn
                            aria-label="close"
                            data-test-id='btnCloseModal'
                            onClick={this.handleCloseSuccessModal}
                        >
                        <img src={closeIcon} alt="close_icon" />
                        </IconBtn>
                        
                        <DialogContent dividers>
                            <img style={webStyle.tickIcon} src={tickIcon} alt="" />
                            <Typography style={webStyle.feedbackSentTxt} >Feedback Sent!</Typography>
                            <button data-test-id='handleDoneAfterSendFeedback' onClick={this.handleDoneAfterSendFeedback} style={webStyle.feedbackSentDoneBtn}>Done</button>
                        </DialogContent>
                    </BootstrapDialog>

        
                </>
                // Customizable Area End
            );
        }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
      display: "flex",
      fontFamily: "Roboto-Medium",
      flexDirection: "column",
      alignItems: "center",
      background: "#2b3d7d0A",
      marginTop: "1px",
      height: "91.2%",
      backrgroundColor: "#B2F1F5F9",
    },
    firstHedingTexts: { fontSize: "14px", fontWeight: 500, color: "#282829", fontFamily: "Poppins",  },
    secHedingText: { fontSize: "11px", fontWeight: 400, color: "#282829", fontFamily: "Poppins",  },
    firstHedingIcon: { fontSize: "14px", cursor: 'pointer', marginRight: '16px', },
    firstHedingIcons: { fontSize: "14px", marginRight: "6px" },
    hedingContainer: { padding: "1% 2%", 'box-shadow': '0px 1px 3px 0px #00000014',  },
    hedingBlock: { marginLeft: "60px" },
    mainWrapperContainers: { 
        height: "100%", 
        padding: "44px 44px 44px 44px",
        position: "relative" as "relative",
        width: "100%",
        overflow:"auto" ,
    },
    mainWrapperContainersForSubStage: { 
        height: "100%", 
        position: "relative" as "relative",
        width: "34%",
        overflow:"auto" ,
    },
    mainWrapperRightSide: { 
        height: "100%",
        overflow: "auto",
        width: "65%",
        paddingRight: "2%",
        marginRight: '5px',
        fontFamily: "Poppins", 
    },
    mainWrapperLeftSide: { 
        height: "100%",
        borderRadius: "9px",
        padding: "0px 5%",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", 
        backgroundColor: "#fff",
    },
    mainWrapperForWordsCloud: { 
        height: "100%",
        borderRadius: "9px",
        padding: "4% 7% 7% 7%",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        backgroundColor: "#fff",
        boxSizing: 'border-box' as 'border-box',
    },
    mainWrapperChartQuestion: {
        height: "fit-content",
        padding: "4% 7% 7% 7%",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        borderRadius: "9px",
        boxSizing: 'border-box' as 'border-box',
        backgroundColor: "#fff",
    },
    textStyleSec: { fontSize: "21px", fontWeight: 500, color: "#282829", fontFamily: "Poppins",  margin: "20px 0px" },
    buttonBeforeClick: {
        cursor: 'pointer',
      width: "100%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
      , borderRadius: "25px", color: "#fff",
      fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "8px 20px", fontWeight: 700
    },
    submitButtonBeforeClickDrag: {
      width: "30%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
      , opacity: "0.5", borderRadius: "25px", color: "#fff",
      fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
      marginTop: "10px"
    },
    submitButtonActive: {
      width: "45%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
      , borderRadius: "25px", color: "#fff",
      fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
      marginTop: "10px"
    },
    submitButtonActiveDrag: {
      width: "30%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
      , borderRadius: "25px", color: "#fff",
      fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
      marginTop: "10px"
    },
    submitedText: { fontSize: "14px", fontFamily: "Poppins", color: "#3F526D", fontWeight: 700, marginLeft: "10px" },
    optionBeforSelect: {
      width: "92%", border: "1px solid #2B3D7D", borderRadius: "20px",
      margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
      cursor: "pointer",
      fontFamily: "Poppins",
    },
    optionSelect: {
      width: "92%", border: "2px solid #2B3D7D", borderRadius: "20px", position: "relative",
      margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
      cursor: "pointer",
      fontFamily: "Poppins",
    },
    optionAfterSelect: {
      width: "92%", background: "#2B3D7D", borderRadius: "20px", position: "relative",
      margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
      cursor: "pointer", border: "2px solid #2B3D7D",
      fontFamily: "Poppins",
    },
    containerForIcon: { position: "absolute", top: "9px", right: "12px", borderRadius: "50%", padding: "3px" },
    containerSubmitIcon: { backgroundColor: "#2B3D7D", borderRadius: "50%", padding: "3px" },
    icoStyle: { color: "#fff", fontSize: "20px" },
    iconStyle: { color: "#fff", fontSize: "15px" },
    firstTextGrids: { paddingTop: "20px" },
    pginationGrid: {
      position: "fixed", bottom: "20px", left: "50%", width: "120px", padding: "4px",
      boxShadow: "0.5px 1px 1px 1px #646464", borderRadius: "11px",
      background: '#fff'
    },
    styleOfImage: { width: "100%", height: "100%" },
    paginationText: { margin: "0px 8px", fontSize: "14px", fontWeight: 600, color: "#3F526D", fontFamily: "Poppins" },
    optionForInput: {
      width: "100%", border: "1px solid #2B3D7D", borderRadius: "20px",
      margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
      cursor: "pointer",
      fontFamily: "Poppins",
    },
    editBtnContainer: { cursor: "pointer", border: "2px solid #2B3D7D", borderRadius: "25px", width: "100px", padding: "9px 0px" },
    mainContainersWidth: { width: "40%" },
    mainContainerWidthSec: { width: "60%" },
    mainContainerWidthDrag: { width: "40%", backgroundColor: "#fff", padding: "3%", borderRadius: "20px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" },
    chartQuestion: { fontSize: "14px", fontFamily: "Poppins", color: "#282829", fontWeight: 500 },
    chartQuestionTextfield: {
      fontSize: "14px", fontFamily: "Poppins", color: "#282829", fontWeight: 600, width: "100%",
      borderRadius: "4px", border: "1px solid #5C2B3D7D", resize: "none", margin: "2% 0px 5%",
      padding: '5px 10px 5px 10px',
    },
    whChartQuestionAns: {
        overflowY: 'scroll' as 'scroll',
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none' ,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        fontSize: "14px", 
        fontFamily: "Poppins", 
        color: "#282829", 
        fontWeight: 600, 
        width: "100%",
        height: '100px',
        boxSizing: 'border-box',
        borderRadius: "4px", 
        border: "1px solid #5C2B3D7D", 
        resize: "none", 
        margin: "2% 0px 5% 0px",
        padding: '5px 10px 5px 10px',
    },
    openEndedAns: {
        fontSize: "14px", 
        fontFamily: "Poppins", 
        color: "#282829", 
        fontWeight: 600, 
        borderRadius: "4px", 
        border: "1px solid #5C2B3D7D", 
        resize: "none", 
        margin: "2% 0px 5% 0px",
        minHeight: '200px',
        overflowY: 'scroll',
        padding: '5px 5px 5px 5px',
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none' ,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    addTextLayout: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
    },
    textQuesTitle: {
        display: 'block',
        fontSize: '16px',
        fontFamily: 'Poppins',
        lineHeight: '26px',
        fontWeight: 400,
    },
    textQuesDescription: {
        display: 'block',
        fontWeight: 400,
        marginTop: '20px',
        lineHeight: '26px',
        fontFamily: 'Poppins',
        fontSize: '16px',
    },
    rightAnswer: {
        width: "92%", background: "#00A52C", borderRadius: "20px", position: "relative" as "relative",
        margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
        cursor: "pointer", border: "2px solid #00A52C",
        fontFamily: "Poppins", lineHeight: "16px"
      },
      wrongAnswer: {
        width: "92%", border: "1px solid #FF0000", borderRadius: "20px",
        margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
        position: "relative" as "relative",
        background: "#FF0000",
        cursor: "pointer",
        fontFamily: "Poppins", lineHeight: "16px"
      },
      noAnswer: {
        width: "92%", border: "1px solid #2B3D7D", borderRadius: "20px",
        margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
        cursor: "pointer",
        fontFamily: "Poppins", lineHeight: "16px"
      },
      openEndedWrapper: {
        background: "#F1F5F9",
        paddingTop: "30px",
        paddingBottom: "30px",
        backrgroundColor: "#B2F1F5F9",
        minHeight: "92%",
      },
      openEndedBlankWrapper: {
        backgroundColor: "#fff",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        width: '32%',
        height: "100%",
        padding: "24px 24px 24px 24px",
        boxSizing: 'border-box' as 'border-box',
        overflowY: 'scroll' as 'scroll', 
        borderRadius: "9px",
        msOverflowStyle: 'none' as 'none' ,
        scrollbarWidth: 'none' as 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
      },
      openEndedMainBoxSubStage: {
        width: '100%',
      },
      openEndedMainBoxStage: {
        width: '34%',
        height: "100%",
        margin: 'auto',
        marginTop: '30px',
        padding: "24px 24px 24px 24px",
        backgroundColor: "#fff",
        overflowY: 'scroll' as 'scroll',
        boxSizing: 'border-box' as 'border-box',
        borderRadius: "9px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none' ,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
      },
      addMultimediaMcq: {
        width: '29%',
        height: "100%",
        borderRadius: "9px",
        padding: "24px 24px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", 
        backgroundColor: "#fff",
        boxSizing: 'border-box' as 'border-box',
        overflowY: 'scroll' as 'scroll',
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none' ,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    tickIcon: {
        width: '60px',
        height: '60px',
        display: 'block',
        margin: 'auto',
    },
    feedbackSentTxt: {
        margin: '12px 0 16px 0',
        fontFamily: 'Poppins',
        fontSize: '24px',
        textAlign: 'center' as 'center',
        color: '#202020',
    },
    feedbackSentDesc: {
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 400,
        textAlign: 'center' as 'center',
        color: '#202020',
    },
    feedbackSentDoneBtn: {
        display: 'block',
        margin: 'auto',
        border: 'none',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        marginTop: '28px',
        color: '#FFFFFF',
        padding: '14px 42px 14px 42px',
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        borderRadius: '50px',
    },
    infoIcon: {
        display: 'block',
        margin: 'auto',
        marginBottom: '12px',
    },
    doYouWantToSendTxt: {
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center' as 'center',
        color: '#202020',
    },
    alertModalAssignName: {
        width: '412px',
        height: "60px",
        margin: 'auto',
        marginTop: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        color: '#2C2C2E',
        border: '0.5px solid #2B3D7D1F',
        backgroundColor: '#2B3D7D14',
        borderRadius: '6px',
    },
    alertBtnBox: {
        width: '412px',
        margin: "auto",
        marginTop: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sendNowBtn: {
        padding: "16px 24px 16px 24px",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        color: '#3F526D',
        background: 'none',
        border: '1px solid #2B3D7D',
        borderRadius: '50px',
        cursor: 'pointer',
    },
    saveAndContiBtn: {
        padding: "16px 24px 16px 24px",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        color: '#FFFFFF',
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        border: 'none',
        borderRadius: '50px',
        cursor: 'pointer',
    },
    pollingQuestion: {
        width: "92%", 
        border: "1px solid #2B3D7D", 
        borderRadius: "20px",
        margin: "20px 0px", 
        padding: "15px", 
        fontSize: "14px", 
        fontWeight: 500, 
        color: "#282829",
        cursor: "pointer",
        fontFamily: "Poppins", 
        lineHeight: "16px"
    },
    canvasStyleImg: {
        width: '100%',
        marginBottom: '10px',
    },
    videoPreviewTitle: {
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        textAlign: 'center' as 'center',
        lineHeight: '24px',
        marginBottom: '20px',
        color: '#282829',
    },
  };
  
  const PropertyDeveloperCss = styled(Box)({
    '& .question': {
      fontFamily: "Poppins",
      fontSize: "18px",
      color: "#282829",
      fontWeight: 500,
      marginBottom: "20px",
    },
    "& .matchingBoxBCorrect": {
        borderRadius: "10px",
        padding: "16px 12px",
        border: "1px solid rgba(43, 61, 125, 0.24)",
        background: "#00A52C",
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Poppins",
        marginBottom: "10px",
        color: "#282829",
        cursor: "move",
    },
    "& .matchingBoxBWrong": {
        fontFamily: "Poppins",
        borderRadius: "10px",
        border: "1px solid rgba(43, 61, 125, 0.24)",
        color: "#282829",
        padding: "16px 12px",
        fontWeight: 500,
        fontSize: "14px",
        cursor: "move",
        marginBottom: "10px",
        background: "#FF0000",
    },
    "& .instruction": {
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#282829",
      fontWeight: 500,
      marginBottom: "15px",
    },
    "& .matchingBoxA": {
      padding: "16px 12px",
      border: "1px solid rgba(43, 61, 125, 0.24)",
      borderRadius: "10px",
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#282829",
      fontWeight: 500,
      marginBottom: "10px",
    },
    "& .matchingBoxB": {
      padding: "16px 12px",
      border: "1px solid rgba(43, 61, 125, 0.24)",
      borderRadius: "10px",
      backgroundColor: "rgba(43, 61, 125, 0.08)",
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#282829",
      fontWeight: 500,
      marginBottom: "10px",
      cursor: "move",
    },
    "& .matchingBoxBActive": {
      padding: "16px 12px",
      border: "1px solid #2B3D7D",
      borderRadius: "10px",
      backgroundColor: "#2B3D7D",
      fontFamily: "Poppins",
      marginBottom: "10px",
      cursor: "move",
      fontSize: "14px", fontWeight: 500, color: "#fff",
    },
    "& .heading": {
      fontFamily: "Poppins",
      fontSize: "21px",
      color: "#282829",
      fontWeight: 700,
    },
    "& .headingLayout": {
      display: "flex",
      marginBottom: "15px",
      justifyContent: "space-around",
      textAlign: "center",
    },
  
    "& .mainLayout": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      padding: "30px 60px",
    },
    "& .contentBox": {
      width: "380px",
      backgroundColor: "#FFF",
      display: "flex",
      justifyContent: "center",
      boxShadow: "0px 0px 10px 0px #F1F5F9",
      borderRadius: "10px",
      flexDirection: "column",
      padding: "30px 60px",
    },
    "& .submitBtn": {
      width: "107px",
      height: "44px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
      color: "#FFFFFF",
      backgroundColor: "#2B3D7D",
      borderRadius: "50px",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#2B3D7D",
      },
    },
    "& .disabledBtn": {
      width: "107px",
      height: "44px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
      color: "#FFFFFF",
      backgroundColor: "#2B3D7D",
      borderRadius: "50px",
      opacity: "0.5",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#2B3D7D",
      },
    },
    "& .submitBtnLayout": {
      marginTop: "25px",
      cursor: "pointer"
    },
  })

export const BootstrapDialog = styled(Dialog) ({
    "& .MuiDialog-paper": {
        width: '540px',
        borderRadius: "16px",
        boxSizing: 'border-box',
    },
    '& .MuiDialogContent-root': {
        paddingTop: '48px',
        paddingBottom: '56px',
    },
});

const IconBtn = styled(IconButton)({
    position: 'absolute',
    right: 8,
    top: 8,
});
// Customizable Area End