import React, { useEffect, useState } from "react";
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Typography,Box} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { notesIcon,lessonIcon,quizIcon,assignmentIcon,folderIcon } from "../../../assets"
import moment from "moment";
import TeacherCustomContextMenu from "./TeacherCustomContextMenu.web";
import CustomEditModal from "../../../../../../components/src/CustomEditModal.web";

const StyledTableCell = withStyles(() => ({
    head: {
        backgroundColor: "#FDF4DC",
        color: "#202020",
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "16px",
    },
    body: {
        fontSize: "14px",
        color: "#3F526D",
        fontFamily: "Poppins",
        fontWeight: 400,
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    LessonIcon: {
        width: "28px",
        height: "28px",
        marginRight: "30px",
    },
    LessonColumnBox: {
        display: "flex",
        alignItems: "center",
    },
    title:{
      fontSize: "14px",
      color: "#3F526D",
      fontFamily: "Poppins",
      fontWeight: 400,
      cursor:"pointer"
    },
    noteIcon: {
      width: "28px",
      height: "28px",
      marginLeft: "30px",
      cursor:"pointer",
    },
    noRecordsText: {
      fontFamily: "Poppins",
      textAlign: "center",
      paddingTop: "35px",
  },
});

export default function SelectedFolderLessonList(props: any) {
    const {
        lessons,
        moveOutLessonApi,
        deleteLessonFolder,
        handleOpenDeleteModal,
        handleCloseDeleteModal,
        openDeleteConfirmationModal,
        handleShowNotesIcon,
        notesList,
        showMoveOutOption,
        downloadLesson,
        editPractice,
        viewMyLibraryLesson,
        handleOpenEditModal,
        state,
        handleEditClassChange,
        handleEditStartDateChange,
        handleEditDueDateChange,
        handleLessonObjectiveChange,
        handleCloseEditModal,
        editFuncMyLibraryAssignment,
        editFuncMyLibraryQuiz,
        handleEditUploadIcon,
        editFuncMyLibraryLesson,
        showLessons,
        handleLessonNameChange,
        deliverLessonApi,
        selectedLessons,
        handleOpenAddFolderModal,
        showLessonsForSelectedSubFolder,
        moveLessonToFolder,
        handleOpenFolderList,
        folderList,
        openFolderList,
        handleCloseFolderList,
        folderAnchorEl,
        handleSetFolderId,
        handleOpenMoveConfirmationPopUp,
        openMovePopup,
        handleCloseMoveConfirmationPopUp,
        handleFolderNameSearchInput,
        folderSearchInput,
        handleCloseSuccessPopup,
        handleOpenSuccessPopup,
        openMoveSuccessPopup
    }=props;
    const classes = useStyles();
    const [lessonAnchorEl,setLessonAnchorEl] =  useState(null)
    const [openContextMenu,setOpenContextMenu] = useState(false)
    const [contextMenuData, setContextMenuData] = React.useState<any>({});
    const [openAddNotesModal, setOpenAddNotesModal] = React.useState<any>({ show: false, type: "" });
    const [notesData, setNotesData] = React.useState<any>([]);
    const handleOpenContextMenu=(event:any,id:string,name:string,itemType:string,totalStage:number,type:string)=>{
      event.preventDefault();
      setContextMenuData({id,name,itemType,totalStage,type})
      setLessonAnchorEl(event.currentTarget)
       setOpenContextMenu(true)
    }
    const handleCloseContextMenu=(event:any)=>{
      event.preventDefault();
      setLessonAnchorEl(null)
      setOpenContextMenu(false)
    }
    const handleCloseAddNoteModal = () => {
      setOpenAddNotesModal({ show: false, type: "" });
    };
    const handleOpenAddNoteModal = (value: string, id:any, name:string ) => {
      setOpenAddNotesModal({ show: true, type: value,id:id, name:name });
    };
    const showNotesIcon=()=>{
      const list = notesList.map((obj:any)=>obj.lessonId)
      setNotesData(list)
    }
    useEffect(()=>{
      showNotesIcon()
    },[notesList])
    const dateTimeFormat = (date: any) => {
        return date
          ? moment(date).format("DD/MM/YYYY HH:MM A")
          : undefined;
      }
      const renderIcon=(itemType:string)=>{
        if(itemType==="lesson"){
          return lessonIcon
        } else if(itemType==="quiz"){
          return quizIcon
        }else if(itemType==="folder"){
          return folderIcon
        }
         else{
          return assignmentIcon
        }
      }

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Lesson Name</StyledTableCell>
                            <StyledTableCell align="center">Stages</StyledTableCell>
                            <StyledTableCell align="center">Created On</StyledTableCell>
                            <StyledTableCell align="center">Last Updated On</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lessons.map((data: any) => (
                            <StyledTableRow key={data.id}>
                                <StyledTableCell component="th" scope="row">
                              <Box className={classes.LessonColumnBox}>
                                <img
                                  className={classes.LessonIcon}
                                  src={renderIcon(data.itemType)}
                                  data-test-id="open-subFolder"
                                  onClick={() => { data?.itemType === "folder" && showLessonsForSelectedSubFolder(data.name, data.id) }}
                                  style={{ cursor: data.itemType === "folder" ? "pointer":"none"}}
                                />{" "}
                                <Typography 
                                 className={classes.title}
                                 style={{ cursor: data.itemType === "folder" ? "pointer":"none"}}
                                 data-test-id="open-subFolderName"
                                  onClick={() => { data?.itemType === "folder" && showLessonsForSelectedSubFolder(data.name, data.id) }}
                                >{data.name}</Typography>

                                {notesData.find((obj: any) => obj === Number(data.id)) && (
                                  <img
                                    onClick={() => handleOpenAddNoteModal("Edit", data.id, data.name)}
                                    className={classes.noteIcon}
                                    src={notesIcon}
                                  />
                                )}
                              </Box>
                                </StyledTableCell>
                                <StyledTableCell align="center">{data.totalStage}</StyledTableCell>
                                <StyledTableCell align="center">{data.createdAt}</StyledTableCell>
                                <StyledTableCell align="center">{data.updatedAt}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <MoreVertIcon
                                        data-test-id="action-icon"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e)=>handleOpenContextMenu(e,data.id,data.name,data.itemType,data.totalStage,data.type)}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomEditModal open={state?.openEditModal} handleClose={handleCloseEditModal} selectedOption={"Lessons"} classList={state?.classList} editLessonApiCall={editFuncMyLibraryLesson} showLessons={showLessons} handleLessonNameChange={handleLessonNameChange} 
        state={state} handleEditClassChange={handleEditClassChange} handleEditStartDateChange={handleEditStartDateChange} handleEditDueDateChange={handleEditDueDateChange} 
        handleLessonObjectiveChange={handleLessonObjectiveChange}
        editFuncMyLibraryAssignment={editFuncMyLibraryAssignment}
        editFuncMyLibraryQuiz={editFuncMyLibraryQuiz}
        handleEditUploadIcon={handleEditUploadIcon}
      />
            <TeacherCustomContextMenu
            data-test-id="context-menu"
            anchorEl={lessonAnchorEl}
            openContextMenu={openContextMenu}
            handleCloseContextMenu={handleCloseContextMenu}
            moveOutLessonApi={moveOutLessonApi}
            contextMenuData={contextMenuData}
            handleCloseDeleteModal={handleCloseDeleteModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
            openDeleteConfirmationModal={openDeleteConfirmationModal}
            deleteLessonFolder={deleteLessonFolder}
            handleCloseAddNotesModal={handleCloseAddNoteModal}
            handleOpenAddNotesModal={handleOpenAddNoteModal}
            openAddNotesModal={openAddNotesModal}
            handleShowNotesIcon={handleShowNotesIcon}
            noteData={notesData}
            showMoveOutOption={showMoveOutOption}
            downloadLesson={downloadLesson}
            editPractice={editPractice}
            viewMyLibraryLesson={viewMyLibraryLesson}
            handleOpenEditModal={handleOpenEditModal}
            deliverLessonApi={deliverLessonApi}
            selectedLessons={selectedLessons}
            handleOpenAddFolderModal={handleOpenAddFolderModal}
            moveLessonToFolder={moveLessonToFolder}
            handleOpenFolderList={handleOpenFolderList}
            folderList={folderList}
            openFolderList={openFolderList}
            handleCloseFolderList={handleCloseFolderList}
            folderAnchorEl={folderAnchorEl}
            handleSetFolderId={handleSetFolderId}
            handleOpenMoveConfirmationPopUp={handleOpenMoveConfirmationPopUp}
            openMovePopup={openMovePopup}
            handleCloseMoveConfirmationPopUp={handleCloseMoveConfirmationPopUp}
            handleFolderNameSearchInput={handleFolderNameSearchInput}
            folderSearchInput={folderSearchInput}
            handleCloseSuccessPopup={handleCloseSuccessPopup}
            handleOpenSuccessPopup={handleOpenSuccessPopup}
            openMoveSuccessPopup={openMoveSuccessPopup}
            />
        </>
    );
}
