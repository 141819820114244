import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Roles } from "../../../components/src/types";
import { debounce } from "lodash";


export interface UserGroupAttributes {
  teacher_classes_id: number;
  teacher_courses_id: number;
  lessons_id?: number;
  user_group_name?: string;
  group_alias?: string;
  assignment_ids?: number[];
  student_ids?: number[];
  account_id?: number;
  no_of_student?: number;
  date_created?: string;
  last_updated?: string;
}

export interface UserGroup {
  type: string;
  id: string;
  attributes: UserGroupAttributes;
}

export interface ClassAttributes {
  class_name: string;
  class_id: number;
  created_at?: string;
  updated_at?: string;
  attachment?: string;
  full_name?: string;
  teacher_id?: number;
}

export interface Class {
  type: string;
  id: string;
  attributes: ClassAttributes;
}

export interface StudentAttributes {
  email: string;
  full_name: string;
  activated: boolean;
  gender: string;
  date_of_birth: string;
  institution: string;
  summary: string;
  school_admin: number;
  grade_id: string;
  course: string;
  teacher_id: number;
  full_phone_number: string;
  first_name: string;
  last_name: string;
  country_code: string;
  profile_status: string;
  role: string;
  password: string;
  phone_number: string;
  image: string;
  date_created: string;
  student_count: number;
  teacher_count: number;
  updated_at: string;
}

export interface Student {
  type: string;
  id: string;
  attributes: StudentAttributes;
}

export interface StudentListAttributes {
  email: string;
  full_name: string;
  activated: boolean;
  gender: string;
  date_of_birth: string;
  institution: string;
  summary: string;
  school_admin: number;
  grade_id: string;
  course: string;
  teacher_id: number;
  full_phone_number: string;
  first_name: string;
  last_name: string;
  account_type: string;
  country_code: string;
  profile_status: string;
  role: string;
  password: string;
  phone_number: string;
  image: string;
  date_created: string;
  student_count: number;
  teacher_count: number;
  updated_at: string;
}

export interface StudentList {
  type: string;
  id: string;
  attributes: StudentListAttributes;
}

export interface ClassList {
  label: string,
  value: string
}

export interface LessonList {
  label: string,
  value: string
}

export interface QuizList {
  label: string,
  value: string
}

interface AssignmentData {
  id: number;
  teacher_classes_id: number;
  teacher_courses_id: number | null;
  lessons_id: number;
  teacher_id: number;
  assignment_name: string;
  start_date: string;
  end_date: string;
  details: string;
  created_at: string;
  updated_at: string;
  add_to_calendar: boolean;
  start_time: string;
  end_time: string;
  status: string;
}

interface StudentData {
  id: number;
  first_name: string | null;
  last_name: string | null;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  // ... other properties
}

interface TeacherUserGroupAttributes {
  teacher_classes_id: number;
  lessons_id: number;
  quiz_id: number;
  user_group_name: string;
  group_alias: string;
  group_link: string;
  account_id: number;
  teacher_classes_name: string;
  lesson_name: string;
  quiz_name: string;
  no_of_student: number;
  assignment: AssignmentData[];
  students: StudentData[];
  teacher_name: string;
  date_created: string;
  last_updated: string;
}

interface TeacherUserGroup {
  id: string;
  type: string;
  attributes: TeacherUserGroupAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  view: string;
  userGroupsData: UserGroup[];
  pageNo: number;
  pageLimit: number;
  classData: Class | null;
  studentList: Student[];
  userGroupsLoading: boolean;
  studentListLoading: boolean;
  userGroupId: string;
  userGroupName: string;
  className: string;
  role: string | null;
  anchorEl: any;
  activeButton: number | null;
  userGroup: string;
  class: any;
  quiz: string;
  lesson: string;
  userGroupError: string;
  classError: string;
  quizError: string;
  lessonError: string;
  isVisibleStudentModal: boolean;
  studentsList: StudentList[];
  selectedRows: any;
  selectedStudents: StudentList[];
  filterdSelectedStudent:StudentList[]
  selectedStudentvalue:any;
  Classdata:any;

  classList: ClassList[];
  lessonList: LessonList[];
  quizList: QuizList[];
  studentSuccessModal: boolean;
  userGroupSuccessModal: boolean;
  studentIds: string[];
  teacherUserGroups: TeacherUserGroup[];
  userGroupDetails: TeacherUserGroup | null;
  editId: string;
  nonEditable: boolean;
  classvalue:any;
  deleteId: string;
  showDeleteModal: boolean;
  deleteUserGroupName: string;
  searchInput: string;
  studentAnchorEl: any;
  deleteStudentId: string;
  openViewDetailsModal:boolean,
  classcode:string;
  isSmallScreen:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  getUserGroupsApiCallId = "";
  getStudentApiCallId="";
  getClassesListApiCallId = "";
  getStudentListApiCallId = "";
  getStudentsApiCallId = "";
  getClassesApiCallId = "";
  getLessonsApiCallId = "";
  getQuizesApiCallId = "";
  addUserGroupApiCallId = "";
  getTeacherUserGroupsApiCallId = "";
  getUserGroupDetailsApiCallId = "";
  editUserGroupApiCallId = "";
  deleteUserGroupApiCallId = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      view: "usergroupList",
      userGroupsData: [],
      pageNo: 1,
      pageLimit: 1,
      classData: null,
      studentList: [],
      userGroupsLoading: true,
      studentListLoading: true,
      userGroupId: "",
      userGroupName: "",
      className: "",
      role: "",
      anchorEl: null,
      activeButton: null,
      userGroup: "",
      class: null,
      quiz: "",
      lesson: "",
      userGroupError: "",
      classError: "",
      quizError: "",
      lessonError: "",
      isVisibleStudentModal: false,
      studentsList: [],
      selectedRows: [],
      selectedStudents: [],
      Classdata:[],
      selectedStudentvalue: {
        id: "",
        fullName: "",
        email: "",
        password: "",
        classes: [],
        creationDate: "",
        profileStatus: ""
      },
      filterdSelectedStudent:[],
      classList: [],
      lessonList: [],
      quizList: [],
      studentSuccessModal: false,
      userGroupSuccessModal: false,
      classcode:'',
      studentIds: [],
      teacherUserGroups: [],
      userGroupDetails: null,
      editId: "",
      nonEditable: false,
      classvalue:"",
      deleteId: "",
      showDeleteModal: false,
      deleteUserGroupName: "",
      searchInput: "",
      studentAnchorEl: null,
      deleteStudentId: "",
      openViewDetailsModal:false,
      isSmallScreen: window.innerWidth <= 600,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const messageId = getName(MessageEnum.RestAPIResponceMessage);
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (message.id) {
      case messageId:
        this.handleRestAPIResponse(message, dataMessage);
        break;

      case getName(MessageEnum.NavigationPayLoadMessage):
        this.renderNavigation(message);
        break;

      case getName(MessageEnum.CountryCodeMessage):
        this.renderCountryCode(message);
        break;

      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    
    const userRole = window.localStorage.getItem("role")
    this.setState({
      role: userRole
    })
    if (userRole === Roles.TEACHER) {
      this.getTeacherUserGroups()
      this.getClasses()
      this.getLessons()
      this.getQuizes()
    } else {
      this.getUserGroups();
    }
  }

  private handleRestAPIResponse(message: Message, dataMessage: string | null) {
    const apiCallIdMappings: Record<string, () => void> = {
      [this.getUserGroupsApiCallId]: () => this.renderGetUserGroups(message),
      [this.getClassesListApiCallId]: () => this.renderGetClassesList(message),
      [this.getStudentListApiCallId]: () => this.renderGetStudentList(message),
      [this.getStudentsApiCallId]: () => this.renderGetStudentsList(message),
      [this.getClassesApiCallId]: () => this.renderGetClasses(message),
      [this.getLessonsApiCallId]: () => this.renderGetLessons(message),
      [this.getQuizesApiCallId]: () => this.renderGetQuizes(message),
      [this.addUserGroupApiCallId]: () => this.renderAddUserGroup(message),
      [this.getTeacherUserGroupsApiCallId]: () => this.renderTeacherUserGroups(message),
      [this.getUserGroupDetailsApiCallId]: () => this.renderUserGroupDetails(message),
      [this.editUserGroupApiCallId]: () => this.renderEditUserGroup(message),
      [this.deleteUserGroupApiCallId]: () => this.renderDeleteUserGroup(message),
      [this.getStudentApiCallId]:()=>this.rendershowstudentDetail(message),
    };

    if (dataMessage && apiCallIdMappings[dataMessage]) {
      apiCallIdMappings[dataMessage]();
    }
  }

  renderGetValidations = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.validationApiCallId) {
        this.handleValidationResponse(responseJson.data);
      } else if (apiRequestCallId === this.createAccountApiCallId) {
        if (!responseJson.errors) {
          this.handleAccountRegistrationSuccess();
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  };

  handleValidationResponse = (data: any) => {
    this.arrayholder = data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  };

  handleAccountRegistrationSuccess = () => {
    const msg: Message = new Message(
      getName(MessageEnum.AccoutResgistrationSuccess)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  };

  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  renderNavigation = (message: Message) => {
    const otpAuthTkn = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  };

  renderCountryCode = (message: Message) => {
    var selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );

    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
  };

  renderGetUserGroups = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        userGroupsData: responseJson.data,
        pageLimit: responseJson.meta.total_page
      });
    } else {
      this.setState({ userGroupsData: [] });
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
    this.setState({
      userGroupsLoading: false
    });
  };

  renderGetClassesList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ classData: responseJson.data });
    } else {
      this.setState({ classData: null });
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  };

  renderGetStudentList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        studentList: responseJson.data,
        pageLimit: responseJson.meta.total_page
      });
    } else {
      this.setState({ studentList: [] });
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
    this.setState({
      studentListLoading: false
    });
  };

  renderGetStudentsList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        studentsList: responseJson.data,
      });
    } else {
      this.setState({ studentsList: [] });
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
    this.setState({ searchInput: "" })
  };

  renderGetClasses = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      const classData = responseJson.data.map((item: any) => ({
        label: item.attributes.class_name,
        value: item.id,
      }));
      this.setState({
        classList: classData
      });
    } else {
      this.setState({ classList: [] });
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  };

  renderGetLessons = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      const lessonData = responseJson.data.map((item: any) => ({
        label: item.attributes.lesson_name,
        value: item.id,
      }));
      this.setState({
        lessonList: lessonData
      });
    } else {
      this.setState({ lessonList: [] });
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  };

  renderGetQuizes = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      const quizData = responseJson.data.map((item: any) => ({
        label: item.attributes.quiz_name,
        value: item.id,
      }));
      this.setState({
        quizList: quizData
      });
    } else {
      this.setState({ quizList: [] });
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  };

  renderAddUserGroup = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {

      this.setState({ userGroupSuccessModal: true,classcode:responseJson.data.attributes.group_alias })
  
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  };

  renderTeacherUserGroups = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        teacherUserGroups: responseJson.data,
        pageLimit: responseJson.meta.total_page
      });
    } else {
      this.setState({ teacherUserGroups: [] });
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
    this.setState({
      userGroupsLoading: false,
      searchInput: ""
    });
  };

  renderUserGroupDetails = async(message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      const userGroup = responseJson.data
      const studentIds = userGroup.attributes.students.map((item: any) => item.id);
 const teacher_classes_name=userGroup.attributes.teacher_classes_name;
 const classValue = this.state.classList.find(
  (item:any) => item.label === teacher_classes_name
);


      this.setState({
        classvalue:classValue?.value,
        userGroupDetails: userGroup,
        userGroup:userGroup.attributes.user_group_name,
        class:classValue?.value,
        
        quiz: userGroup.attributes.quiz_name,
        lesson: userGroup.attributes.lesson_name,
        selectedStudents: userGroup.attributes.students,
        studentIds: studentIds
      })
    } else {
      this.setState({ userGroupDetails: null });
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  };

  renderEditUserGroup = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ view: "usergroupList" }, () => this.getTeacherUserGroups())
      this.setState({ editId: "", deleteId: "" ,className:'',classvalue:''})
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  };
  rendershowstudentDetail=(message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.data) {
      const { data } = responseJson
      this.setState({
        Classdata:data.attributes.classes
      })
      
      const student = {
        id: data.id,
        fullName: data.attributes.full_name,
        email: data.attributes.email,
        password: data.attributes.password,
        classes: data.attributes.password,
        creationDate: data.attributes.date_created,
        profileStatus: data.attributes.profile_status,
        phone: data.attributes.full_phone_number,
        gender: data.attributes.gender,
        birthDate: data.attributes.date_of_birth
      }
      this.setState({
      
        
            selectedStudentvalue: student,
          })
      
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }
  renderDeleteUserGroup = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.message) {
      this.getTeacherUserGroups()
      this.hideDeleteModal()
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
    this.setState({ editId: "", deleteId: "" })
  };

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getUserGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserGroupsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserGroupsApiEndPoint}?page=${this.state.pageNo
      }&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClassesListData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClassesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClassesListApiEndPoint}?id=${this.state.userGroupId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStudentListData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStudentListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentListApiEndPoint}?id=${this.state.userGroupId
      }&page=${this.state.pageNo}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStudentsList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStudentsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentsApiEndPoint}?full_name=${this.state.searchInput}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClasses = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClassesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClassesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getLessons = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLessonsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLessonsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getQuizes = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuizesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuizesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTeacherUserGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeacherUserGroupsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeacherUserGroupsApiEndPoint}?page=${this.state.pageNo
      }&per_page=10&search=${this.state.searchInput}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserGroupDetails = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserGroupDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeacherUserGroupsApiEndPoint}/${this.state.editId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editUserGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const httpBody = {
      user_group_name: this.state.userGroup,
      teacher_classes_id: this.state.class,
      quiz_id: this.state.userGroupDetails?.attributes.quiz_id,
      lessons_id: this.state.userGroupDetails?.attributes.lessons_id,
      student_ids: this.state.studentIds
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editUserGroupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editUserGroupApiEndPoint}/${this.state.editId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserGroupApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteUserGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteUserGroupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteUserGroupApiEndPoint}?id=${this.state.deleteId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteUserGroupApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  handleBreadCrumb = (view: string) => {
    this.setState({ view: view }, () => {
      if (this.state.view === "usergroupList") {
        this.getUserGroups();
      }
    });
  };

  getClassesList = (event: any, row: UserGroup) => {
    this.setState({ view: "classList" });
    this.setState({ userGroupId: row.id }, () => this.getClassesListData());
    this.setState({ userGroupName: row.attributes.user_group_name! });
  };

  getStudentList = (event: any, data: any) => {
    this.setState({ view: "studentList" });
    this.setState({ className: data.attributes.class_name });
    this.setState({ pageNo: 1 }, () => this.getStudentListData());
  };
  handleCopyLink = () => {
    let copyText: any = document.getElementById("linkInput");
    copyText.select();
    navigator.clipboard.writeText(copyText.value);
  };
  handlePageChange(event: any, page: number) {
    this.setState(
      {
        pageNo: page
      },
      () => {
        if (this.state.role === "SchoolAdmin") {
          this.state.view === "usergroupList"
            ? this.getUserGroups()
            : this.getStudentListData();
        } else {
          this.getTeacherUserGroups();
        }
      }
    );
  }

  handleClick = (event: any, row: any) => {
    this.setState({ anchorEl: event.target, editId: row.id, deleteId: row.id, deleteUserGroupName: row.attributes.user_group_name });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleButtonHover = (buttonId: number | null) => {
    this.setState({ activeButton: buttonId });
  }

  openUserGroupForm = () => {
    this.setState({
      view: "userGroupForm",
      userGroup: "",
      class: "",
      quiz: "",
      lesson: "",
      selectedStudents: [],
      studentIds: []
    })

  }

  handleDeleteUserGroups = () => {
    this.setState({ showDeleteModal: true, editId: "" })
    this.setState({ anchorEl: null })
  }

  hideDeleteModal = () => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal, deleteId: "" });
  };
  handelOpenView=()=>{
    const updatedStudents = this.state.selectedStudents.filter(student => student.id === this.state.deleteStudentId);

    this.CloseStudentPopover()
    
    this.viewStudent(updatedStudents[0].id)
    this.setState({openViewDetailsModal:true})
  }
  viewStudent(id: string): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewStudent}?account_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handelCloseView=()=>{
    
    this.setState({openViewDetailsModal:false})
  }

  validateForm = () => {
    let isValid = true;

    if (this.isStringNullOrBlank(this.state.userGroup)) {
      this.setState({ userGroupError: "User Group name is required!" })
      isValid = false;
    } 
    else{
      this.setState({ userGroupError: "" })
    
    }

    if (this.isStringNullOrBlank(this.state.class)) {
      this.setState({ classError: "Class is required!" })
      isValid = false;
    } else {
      this.setState({ classError: "" })
    }
    return isValid
  }

  handleFormSubmit = (event: any) => {
    event.preventDefault();
    if (this.state.nonEditable) {
      this.setState({ nonEditable: false })
      return false
    }
    const isValid = this.validateForm()
    if (isValid) {
      this.state.editId ? this.editUserGroup() : this.createUserGroup()
    }
  }

  handleChange = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name as string]: value,
    }));
  };

  handleCancel = () => {
    this.setState({ view: "usergroupList", editId: "",classvalue:"",className:"" })

  }

  openAddStudentModal = () => {
    this.setState({ isVisibleStudentModal: true })
    this.getStudentsList()
    
  }

  hideStudentModal = () => {
    this.setState({ isVisibleStudentModal: !this.state.isVisibleStudentModal });
  };

  handleViewDetails = () => {
    this.setState({
      view: "userGroupForm",
      nonEditable: true,
      anchorEl: null
    })
    this.getUserGroupDetails()
    this.getTeacherUserGroups()
  }

  debouncedSearchUserGroups = debounce(() => {
    this.getTeacherUserGroups()
  }, 1000);

  handleSearchUserGroups(event: any) {
    this.setState(
      {
        searchInput: event.target.value,
      },
      () => {
        this.debouncedSearchUserGroups()
      }
    );
  }

  debouncedSearchStudents = debounce(() => {
    this.getStudentsList()
  }, 1000);

  handleSearchStudents(event: any) {
    this.setState(
      {
        searchInput: event.target.value,
      },
      () => {
        this.debouncedSearchStudents()
      }
    );
  }

  filterStudents = (input: any) => {
    if(input)
      {
    const filteredData=this.state.selectedStudents
    const filteredStudents = filteredData.filter((student) =>
      student.attributes.full_name.toLowerCase().includes(input.toLowerCase())
    );
    this.setState({selectedStudents:filteredStudents})
  }
  else{
    this.getUserGroupDetails()
  }
  };

  handleSearchSelectedStudents(event: any) {
    const input = event.target.value
    this.filterStudents(input)
  }

  handleCancelfunction = () => {
    this.setState({ view: "usergroupList", editId: "",classvalue:"",className:"" })

  }


  OpenStudentPopover = (event: any, row: any) => {
    this.setState({ studentAnchorEl: event.target, deleteStudentId: row.id});
  };

  CloseStudentPopover = () => {
    this.setState({ studentAnchorEl: null });
  };

  handleDeleteStudent = () => {
    const updatedStudents = this.state.selectedStudents.filter(student => student.id !== this.state.deleteStudentId);
    const studentIds = updatedStudents.map(student => student.id);    
    this.setState({selectedStudents: updatedStudents, studentAnchorEl: null, studentIds});
  };

  createUserGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const httpBody = {
      user_group_name: this.state.userGroup,
      teacher_classes_id: this.state.class,
      quiz_id: this.state.quiz,
      lessons_id: this.state.lesson,
      student_ids: this.state.studentIds
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserGroupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addUserGroupApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserGroupApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSelectedStudents = (rows: any) => {
    this.setState({ selectedRows: rows })
    const ids = rows.map((item: any) => item.id);
    this.setState({ studentIds: ids })
  };

  handleSubmitStudents = () => {
    this.setState((prevState) => {
      const existingStudentIds = new Set(prevState.selectedStudents.map(student => student.id));
      const newUniqueSelectedRows = prevState.selectedRows.filter((row: Student) => !existingStudentIds.has(row.id));
      const updatedSelectedStudents = [...prevState.selectedStudents, ...newUniqueSelectedRows];
      const ids=updatedSelectedStudents.map(student=>student.id)
      return {
        studentIds:ids,
        selectedStudents: updatedSelectedStudents,
        filterdSelectedStudent: updatedSelectedStudents,
        isVisibleStudentModal: false,
        studentSuccessModal: true
      };
    });
  };
  
  
  hideStudentSuccessModal = () => {
    this.setState({ studentSuccessModal: !this.state.studentSuccessModal });
  };

  hideUserGroupSuccessModal = () => {
    this.setState({ 
      userGroupSuccessModal: !this.state.userGroupSuccessModal,
      view: ""
    }, () => this.getTeacherUserGroups());
  };

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
