import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { defaultImage, noClassImg } from "./assets";
import { debounce } from "lodash";
import { COURSES_PAGES, Roles, TEACHER_DASHBOARD_VIEWS, TeacherState} from "../../../components/src/types"
import StorageProvider from "../../../framework/src/StorageProvider";
import moment from "moment";
import { setStorageData } from "../../../framework/src/Utilities";

export interface OptionData {
  optionName: string;
  class: string;
  startDate: string; 
  endDate: string;
  objective: string;
  attachment: string; 
}

export interface QuizData {
  message: string;
  data: {
      id: string;
      type: string;
      attributes: {
          teacher_classes_id: number;
          quiz_name: string;
          start_date: string | null;
          end_date: string | null;
          start_time: string | null;
          end_time: string | null;
          details: string | null;
          quiz_status: string;
          status: string;
          add_to_calendar: boolean;
          created_at: string;
          updated_at: string;
          due_date: number;
          quiz_attachment: string; 
          class_name: string;
      };
  };
  error: string
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  name?: string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  pageLimit: number;
  pageNo: number;
  searchInputData: string;
  dashboardLoading: boolean;
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  activeTab: string;
  studentCount: number;
  teacherCount: number;
  teachersList: any[];
  searchQuery: string;
  view : string;
  classListData: any;
  classTitle: string;
  selectedTeacher: any;
  role:string|null;
  teacherState:TeacherState;
  selectedOption:string;
  createdLesson:any;
  studentClassListData: any;
  studentClassList: any[];
  formClassList:any[]
  pageType: string;
  openAssignModal: boolean,
  value: number,
  quizName: string ,
  page: number,
  name: string,
  activeState: boolean,
  quizList: any,
  classesData: any,
  openClassListModal: boolean,
  classActiveTab: boolean,
  foldersList: any,
  selectedClass: string,
  selectedAssignmentList: any,
  assignNewTasksModal: boolean;
  selectedDueDate:string;
  selectedStartDate:string;
  isValidateEndDate: boolean;
  isValidateStartDate: boolean;
  isValidateStartTime: boolean;
  isSameStartDueTime: boolean;
  isValidateEndTime: boolean;
  isStartDueDateEvent: boolean;
  isValidateCreateEvent: boolean;
  emailActiveTab: string
  currentLocation: string
  render: string,
  selectedTeacherId: number
  selectedClassId: string,
  assignentId: string,
  successModal: boolean,
  selectedAssignment: string,
  selectedDueTime: string;
  selectedStartTime: string;
  teacherClassId: string,
  actionName: string,
  assignClassId: string,
  studentActionItems: {},
  studentMails: any,
  filteredUserGroupData: any[],
  folderId: string,
  selected: any,
  selectedStudents: any,
  selectedClasses:any,
  selectedChipsValue: any
  searchnputValue: string
  userGroupData: any
  isSmallScreen:boolean;
  selectedClassName: string,
  selectedClassData: any
  selectedFoldersData: any,
  inputValue: string
  tabValue: number;
  editTaskId: string;
  editLessonId: string;
  openEditModal: boolean;
  openDeleteConfirmationModal: boolean;
  isValidateEditLibrary: boolean;
  editAssignmentName: string;
  editAssignmentClassName: string;
  editQuizName: string;
  editQuizClassName: string;
  editStartDate: string;
  editDueDate: string;
  editLessonName: string;
  editClassName: string;
  editLessonObjective: string;
  editClassValidate: boolean;
  editLessonNameValidate: boolean;
  editValidStartDateEvent: boolean;
  editValidDueDateEvent: boolean;
  assignNewSelectedClassId: number;
  assignNewselectedTeacherId: number;
  assignNewSelectedClassName: string;
  assignNewSelectedFolderName: string;
  assignNewSelectedUserEmailClassId: string;
  assignNewSelectedUserEmailClassName: string;
  assignNewSelectedUserEmailStudentEmails: any[];
  assignNewSelectedUserGroupClassId: string;
  assignNewSelectedUserGroupClassName: string;
  taskListPageNo: number;
  isLimitModalOpen: boolean;
  taskListPageLimit: number;
  selectedSubFolderData:any;
  selectedSubFolderList:any;
  folderData:any[];
  quizzesData:any[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  classListApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  locations: any[]
  apiGetTeacherDashboardCallId: string = "";
  createLessonApiCallId:string= "";
  studentClassListApiCallId: string = "";
  apiGetClassListCallId: string = "";
  createAssignmentApiCallId:string = "";
  createQuizApiCallId:string = "";
  getQuizDataApiCallId: string = "" ;
  getClassesDataApiCallId: string = "" ;
  getSharedLibraryApiCallId: string = "";
  getFoldersListApiCallId: string = "";
  assignTaskApiCallId: string = "";
  searchUserGroupApiCallId: string = "";
  searchStudentsMailAPiCallId: string = "";
  userGroupEmailsAPICallId: string = "";
  apiShowAssignmentCallId:string="";
  apiUpdateAssignmentCallId:string="";
  apiDeleteAssignmentCallId:string="";
  getFoldersQuizListCallId:string="";
  getFoldersAssignmentListApiCallId:string="";
  getSubFoldersAssignmentListApiCallId:string="";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      pageLimit: 1,
      pageNo: 1,
      searchInputData: "",
      dashboardLoading: true,
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      activeTab: "Dashboard",
      studentCount: 0,
      teacherCount: 0,
      teachersList: [],
      searchQuery: "",
      view: "teacherList",
      classListData: [],
      classTitle: "",
      selectedTeacher: {},
      role:"",
      selectedOption:"",
      createdLesson:"",
      teacherState:{
        classList: [],
        totalStudentCount: 0,
        selectedView:TEACHER_DASHBOARD_VIEWS.DASHBOARD,
        searchInputClassName:"",
      },
      studentClassList: [],
      studentClassListData: [],
      formClassList:[],
      pageType: "",
      openAssignModal: false,
      value: 0,
      quizName: "" ,
      page: 1,
      name: "",
      activeState: false,
      quizList: [],
      classesData: [],
      openClassListModal: false,
      classActiveTab:false,
      foldersList:[],
      selectedClass: "CLASS",
      selectedAssignmentList: [],
      assignNewTasksModal: false,
      selectedDueDate:"",
      selectedStartDate: '',
      selectedStartTime: '',
      emailActiveTab:"CLASS",
      currentLocation: "Select Class",
      render: "Select Class",
      selectedTeacherId: 0,
      selectedClassId:"",
      assignentId:"",
      successModal: false,
      selectedAssignment:"",
      selectedDueTime: "",
      teacherClassId: "",
      actionName: "",
      assignClassId: "",
      studentActionItems: {},
      studentMails: [],
      filteredUserGroupData: [],
      folderId: "",
      selected:[],
      selectedStudents:[],
      selectedClasses: [],
      selectedChipsValue: [],
      searchnputValue: "",
      userGroupData: [],
      isSmallScreen: window.innerWidth <= 600,
      selectedClassName:"",
      selectedClassData: [],
      selectedFoldersData: {},
      tabValue:0,
      inputValue:"",
      editTaskId: '',
      editLessonId: '',
      openEditModal: false,
      openDeleteConfirmationModal: false,
      isValidateEditLibrary: false,
      editAssignmentName: '',
      editAssignmentClassName: '',
      editQuizName: "",
      editQuizClassName: "",
      editStartDate: "",
      editDueDate: "",
      editLessonName: "",
      editClassName: "",
      editLessonObjective: "",
      editClassValidate: false,
      editLessonNameValidate: false,
      editValidStartDateEvent: false,
      editValidDueDateEvent: false,
      assignNewSelectedClassId: 0,
      assignNewselectedTeacherId: 0,
      assignNewSelectedClassName: "",
      assignNewSelectedFolderName: "",
      assignNewSelectedUserEmailClassId: "",
      assignNewSelectedUserEmailClassName: "",
      assignNewSelectedUserEmailStudentEmails: [],
      assignNewSelectedUserGroupClassId: "",
      assignNewSelectedUserGroupClassName: "",
      isValidateEndDate: false,
      isSameStartDueTime: false,
      isValidateStartDate: false,
      isValidateEndTime: false,
      isStartDueDateEvent: false,
      isValidateCreateEvent: false,
      isValidateStartTime: false,
      taskListPageNo: 1,
      taskListPageLimit: 1,
      isLimitModalOpen: false,
      selectedSubFolderData:{},
      selectedSubFolderList:[],
      folderData:[],
      quizzesData:[]
    };
    this.locations = [];
    

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    let pageType = await StorageProvider.get("pageTypeData")
    this.setState({ pageType: pageType }, () => {
    })

    if(window.localStorage.getItem("role")===Roles.SUB_ADMIN){
      this.getDashboardData();
    }
    if(window.localStorage.getItem("role")===Roles.TEACHER){
      this.getTeacherDashboardData();
    }
    if (window.localStorage.getItem("role") === Roles.STUDENT) {
      this.getStudentClasslistData();
    }
    this.setState({
      role:window.localStorage.getItem("role")
    })

    window.addEventListener('resize', this.handleResize);
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.dashboardGetUrl}?per_page=9&full_name=${this.state.searchQuery}&page=${this.state.pageNo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const callId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    {(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiDashboardItemCallId != null &&
      this.apiDashboardItemCallId === callId) &&
      this.renderTeachersData(message) }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.classListApiCallId != null &&
    this.classListApiCallId === callId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson?.errors && responseJson?.data) {
          this.setState({classListData: responseJson?.data})
        }else {
          const errorResponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorResponse);
        }

      } 
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.studentClassListApiCallId != null &&
      this.studentClassListApiCallId === callId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson?.data) {
        this.setState({
          studentClassListData: responseJson?.data?.attributes?.classes,
          studentClassList: responseJson?.data,
          studentActionItems: responseJson?.meta,
        }
        )
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } 

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let error= message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );  
      this.handleGetTeacherDashboardDataResponse(callId,response,error)
      this.handleCreateLessonResponse(callId,response,error)
      this.handleGetClassListResponse(callId,response,error)
      this.handleCreateAssignmentResponse(callId,response,error)
      this.handleCreateQuizResponse(callId,response,error);
      this.handleEditMyLibraryAssignment(callId,response,error);
      this.handleDeleteAssignment(callId,response,error);
      this.handleGetFoldersQuizListApiData(callId,message);
      this.handleGetFoldersAssignmentListApiData(callId,message);
      this.handleGetSubFoldersAssignmentListApiData(callId,message);
      this.setState({
        dashboardLoading: false
      });

      this.callQuizApi(from, message)
      this.assignTaskApi(from, message)
      this.callGroupApi(from, message)
      this.otherApiResponse(message)
    // Customizable Area End
  }

  // Customizable Area Start
  callQuizApi = (from: string, message: Message) => {
    const callId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    // Teacher Asigned Task List
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getQuizDataApiCallId != null &&
    this.getQuizDataApiCallId === callId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson?.data) {
        let filteredData = [];
        if (!this.state.activeState) {
          filteredData = responseJson?.data.filter((item: any) => item?.attributes?.assignment?.data !== null);
        } else {
          filteredData = responseJson?.data.filter((item: any) => item?.attributes?.quiz?.data !== null);
        }
        this.setState({loading: false, quizList: filteredData, pageLimit: responseJson.meta && responseJson.meta.total_pages});
      }
      else{
        this.setState({loading: false, quizList: []});
      }
    }
    
    // My Library - Class List
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getClassesDataApiCallId != null &&
    this.getClassesDataApiCallId === callId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false});
      if (!responseJson?.errors && responseJson?.data) {
        this.setState({classesData: responseJson?.data})
      }
    }
  }

  otherApiResponse = (message:Message) => {
    const callId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    // Shared Library - Assignemtn & Quiz List
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getSharedLibraryApiCallId != null &&
    this.getSharedLibraryApiCallId === callId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let classesData = [];
      if (this.state.actionName === "Assignment") {
        classesData = responseJson?.assignments?.data;
      } else if (this.state.actionName === "Quiz") {
        classesData = responseJson?.quizzes?.data;
      }
      this.setState({ loading: false});
      if (!responseJson?.errors && classesData) {
        this.setState({classesData: classesData})
      }
    }

    // Folder List
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getFoldersListApiCallId != null &&
    this.getFoldersListApiCallId === callId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false});
      if (!responseJson?.errors && responseJson) {
        this.setState({foldersList: responseJson, selectedClassData:{},selectedFoldersData: {}})
      }
    }
        
  }

  callGroupApi = (from: string, message: Message) => {
    const callId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userGroupEmailsAPICallId != null &&
      this.userGroupEmailsAPICallId === callId) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (!responseJson?.errors && responseJson?.data) {
            
            this.setState({userGroupData: responseJson?.data})
          }
        }
  }

  assignTaskApi = (from: string, message: Message) => {
    const callId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.assignTaskApiCallId != null &&
    this.assignTaskApiCallId === callId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson?.errors && responseJson?.data) {
          this.locations= []
          this.setState({successModal: true, openClassListModal: false,
            assignNewTasksModal: false, selectedDueDate:"",selectedStartDate:'',selectedDueTime:"",selectedStartTime:"",
           teacherClassId:"", assignentId:"", selectedClassName:"", selectedAssignment:"",
           selected:[],selectedClasses:[],folderId:"",selectedChipsValue:[],filteredUserGroupData:[],assignNewSelectedUserEmailStudentEmails:[], quizName: ""
          });
          this.getQuizData(!this.state.activeState ? 'assignment' : 'quiz');
        }
      }
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.searchUserGroupApiCallId != null &&
      this.searchUserGroupApiCallId === callId) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (!responseJson?.errors && responseJson?.data) {
            this.setState({filteredUserGroupData: responseJson?.data})
          }
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.searchStudentsMailAPiCallId != null &&
      this.searchStudentsMailAPiCallId === callId) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (!responseJson?.errors && responseJson?.students) {
            this.setState({studentMails: responseJson?.students})
          }
        }
        
  }
  getStudentClasslistData(): boolean {
    let id = window.localStorage.getItem("id")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.studentClassListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentClasslistData}?student_id=${id}&search=${this.state.teacherState.searchInputClassName}` 
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getTeacherDashboardData(): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetTeacherDashboardCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeacherDashboardData}?per_page=9&class_name=${this.state.teacherState.searchInputClassName}&page=${this.state.pageNo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  renderDashboardData=(message: Message)=>{
    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token: token, loading: true }, () => {
      this.getDashboardData();
    });
  }

  renderTeachersData=(message: Message)=>{
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      if (responseJson.data.length === 0) {
        this.setState({
          errorMsg: "Data Not Found",
          loading: false
        });
      } else {
        this.setState({
          dashboardData: responseJson.data,
          errorMsg: "",
          loading: false,
          studentCount: responseJson.data[0].attributes.student_count,
          teacherCount: responseJson.data[0].attributes.teacher_count,
          teachersList: responseJson.data.map((teacher:any)=>{
            return {
              id:teacher.id,
              attributes:{
                full_name:teacher.attributes.full_name,
                image:teacher.attributes.image?teacher.attributes.image:defaultImage
              }
            }
          }),
          pageLimit: responseJson.meta.total_page
        });
      }
    } else {
      this.setState({teachersList: []})
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorReponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false
        });
      } else {
        this.setState({
          errorMsg: errorReponse,
          loading: false
        });
      }
    }
    this.setState({
      dashboardLoading: false
    });
  }

  handlePageChange(event: any, page: number) {
    this.setState({
      pageNo: page,
      page: page,
    },
    () => { 
      this.getDashboardData(); 
      this.getQuizData(!this.state.activeState ? 'assignment' : 'quiz', page);
     }); 
  }

  handleTabClick = (tabName: string) => {
    this.setState({
      activeTab: tabName
    });
    {
      tabName === "Accounts" && this.props.navigation.navigate("AccountGroups");
    }
  };
  debouncedSearch = debounce(() => {
    this.getDashboardData();
  }, 1000); 

  handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({ searchQuery: event.target.value },
       () => { 
         this.debouncedSearch(); 
        }); 
      };
      
  handleClasses = () => {
    this.setState({assignNewTasksModal:true, emailActiveTab:"CLASS", selectedClass:"CLASS", quizName: ""})
    // this.getClassesData()
  }

  getClassesList=(event: any,teacher: any)=>{
    this.setState({view: 'classList'})
    this.getClassListData(teacher.id)
    this.setState({selectedTeacher: {
     id:teacher.id,
     attributes:{
      full_name:teacher.name,
      image:teacher.image
     } 
    }})
    this.setState({
      dashboardLoading: true
    });
  }

  handleBreadCrumb=(view: string)=>{
    this.setState({view: view})
    if(view === "teacherList"){
      this.setState({classListData: [], searchQuery: ""},()=>this.getDashboardData())
      }  
  }

  getClassListData(id: string): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.classListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.classListGetUrl}?teacher_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  createLesson(data:any): boolean {
    const header = {
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData= new FormData()
    formData.append("lesson_name", data.optionName); 
    formData.append("lesson_objective", data.objective); 
    formData.append("start_date", data.startDate); 
    formData.append("end_date", data.endDate); 
    formData.append("teacher_classes_id", data.class); 
    if(data.attachment){
      formData.append("lesson_attachment", data.attachment); 
    }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.createLessonApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createLesson}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Post
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCreateLessonResponse(  apiRequestCallId: string,
    responseJson: any,
    errorReponse: string){
      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.createLessonApiCallId
      ){
        if(responseJson.message){
          return
        }
        if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
          this.enableLimitSnackbar();
        }
        if(responseJson){
          StorageProvider.set("teacherId",responseJson.teacher_classes_id)
          StorageProvider.set("oldLessonId",responseJson.id)
          this.setState({
            createdLesson:responseJson
          })
          this.props.navigation.navigate("CfCourseCreation23", {
            type:COURSES_PAGES.LESSON,
            id:responseJson.id,
            name:encodeURIComponent(responseJson.lesson_name)
          });
        }
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
       }
    }

  getClassDetails=(className: string, classId: string | number, data?: any)=>{
    this.setState({view: "classDetails"})
    this.setState({classTitle: className})
    setStorageData("classIdForPeopleManagement", classId)
    setStorageData("cameFromDashboard", JSON.stringify(data))
  }

  getTeacherCalendar(id: string) {
    const currentLocation = window.location.toString(); 
    const newLocation = `${currentLocation.replace(/\/dashboard$/, '/teachers')}/${id}`; 
    window.location.href = newLocation;
  }

  navigateToSection(path: string) {
    const currentLocation = window.location.toString();
    const newLocation = `${currentLocation.replace(/\/dashboard$/, `/${path}`)}`; 
    window.location.href = newLocation;
  }

  handleGetTeacherDashboardDataResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string){
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiGetTeacherDashboardCallId
    ){
      if(responseJson.message){
        this.setState((prevState)=>({
          teacherState:{
            ...prevState.teacherState,
            classList:[],
          },
          pageLimit: 0
        }));
        return
      }
      if(responseJson.data[0]){
            const classList=responseJson.data.map((data: any)=>{
              return{
                id:data.id,
                name:data.attributes.class_name,
                studentCount:data.attributes.student_count,
                image:data.attributes.attachment?data.attributes.attachment:noClassImg,
                classCode: data.attributes.class_code
              }
            })
              this.setState((prevState)=>({
                teacherState:{
                  ...prevState.teacherState,
                  classList:classList,
                  totalStudentCount:responseJson.meta.total_student_count
                },
                pageLimit: responseJson.meta.total_page
            }));
      }
      else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
     }
  }
  handlePageChangeClassList(event: any, page: number){
    this.setState({
      pageNo: page
    },
    () => { 
      this.getTeacherDashboardData(); 
     }); 
  }

  debouncedSearchClassName = debounce(() => {
      this.getTeacherDashboardData();
  }, 1000); 

  debouncedSearchClassNameForStudents = debounce(() => {
      this.getStudentClasslistData();
  }, 1000); 

  handleSearchClassName = (value:any,data:any) => {
      this.setState(
        (prevState) => ({
          teacherState: {
            ...prevState.teacherState,
            searchInputClassName: value,
          },
          pageNo:1,
        }),
        () => {
          this.debouncedSearchClassName();
        }
      );
  };

  handleSearchClassNameForStudents = (value: string) => {
    this.setState({
      teacherState: {
        ...this.state.teacherState,
        searchInputClassName: value,
      },
      pageNo: 1,
    },
      () => {
        this.debouncedSearchClassNameForStudents();
      }
    );
  };

  setSelectedOption(selectedOption:string){
    this.setState({
      selectedOption:selectedOption
    })
  }
  redirectToClassDetailView(e: any, data: any){
    this.props.navigation.navigate("ContentManagement", {
      data:JSON.stringify(data)
    });
  }
  getFormClassList(): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetClassListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeacherDashboardData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  handleGetClassListResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string){
      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.apiGetClassListCallId
      ){
        if(responseJson.message){
          this.setState({
            formClassList:[],
          })
          return
        }
        if(responseJson.data[0]){
         const classList=responseJson.data.map((data: any)=>{
           return{
             id:data.id,
             name:data.attributes.class_name,
             studentCount:data.attributes.student_count,
             image:data.attributes.attachment?data.attributes.attachment:defaultImage,
           }
         })
         this.setState({
           formClassList:classList,
         })
        }
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
       }
  }
  createAssignment(data:any): boolean {
    const header = {
        token: window.localStorage.getItem("authToken")
    };
     
    const formData= new FormData()
    formData.append("assignment_name", data.optionName); 
    formData.append("details", data.objective); 
    formData.append("start_date", data.startDate); 
    formData.append("end_date", data.endDate); 
    formData.append("teacher_classes_id", data.class);
    if(data.attachment){
      formData.append("assignment_attachment", data.attachment); 
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
     );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.createAssignmentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createAssignment}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Post
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCreateAssignmentResponse(  apiRequestCallId: string,
    responseJson: any,
    errorReponse: string){
      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.createAssignmentApiCallId
      ){
        if(responseJson.message){
          return
        }
        if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
          this.enableLimitSnackbar();
        }
        if(responseJson){
          StorageProvider.set("assignmentteacherId",responseJson.data.attributes.teacher_classes_id)
          StorageProvider.set("assignmentoldLessonId",responseJson.data.id)
          this.setState({
            createdLesson:responseJson
          })
          this.props.navigation.navigate("CfCourseCreation23", {
            type:COURSES_PAGES.ASSIGNMENT,
            id:responseJson.data.id,
            name:encodeURIComponent(responseJson.data.attributes.assignment_name)
          });
        }
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
       }
    }

    createQuiz(data: OptionData): boolean {

      const header = {
          token: window.localStorage.getItem("authToken")
      };
       
      const formInfo = new FormData()
      formInfo.append("start_date", data.startDate); 
      formInfo.append("end_date", data.endDate); 
      formInfo.append("details", data.objective); 
      formInfo.append("teacher_classes_id", data.class);
      formInfo.append("quiz_name", data.optionName); 
      if(data.attachment){
        formInfo.append("assignment_attachment", data.attachment); 
      }
      const requestQuizMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
       );
      requestQuizMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formInfo
      );
      this.createQuizApiCallId = requestQuizMessage.messageId;
      requestQuizMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createQuizEndPoint}`
      );
      requestQuizMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestQuizMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.Post
      );
      runEngine.sendMessage(requestQuizMessage.id, requestQuizMessage);
      return true;
    };

    handleCreateQuizResponse(  apiRequestCallId: string,responseJson: QuizData,errorReponse: string){
        if (
          apiRequestCallId != null &&
          apiRequestCallId === this.createQuizApiCallId
        ){
          if(responseJson.message){
            return
          }
          if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
            this.enableLimitSnackbar();
          }
          if(responseJson){
            this.setState({
              createdLesson:responseJson
            })
            this.props.navigation.navigate("CfCourseCreation23", {
              type:COURSES_PAGES.QUIZ,
             id:responseJson.data.id,
            name:encodeURIComponent(responseJson.data.attributes.quiz_name)
          });
        }
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
       }
      }
    setQuizeName =(value:any)=>{
      this.setState(
        {
          quizName : value ,
          page: 1
        },
        () => {
          setTimeout(() => {
            this.getQuizData(!this.state.activeState ? 'assignment' : 'quiz');
          }, 1000);
        }
      );  
    }

    setName =(value:any)=>{
      this.setState(
        {
          name: value ,
          page: 1
        },
      );  
    }


  getTaskLists = () =>{
    return [];
  }
  
  getQuizData= (value: string, currentPage: number = 1) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuizDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/teacher_assign_task_list?type=${value}&search=${this.state.quizName}&per_page=10&page=${currentPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCloseModal = () => {
    this.locations = []
    this.setState({openAssignModal: false, actionName: "", classActiveTab: false, successModal:false, assignNewTasksModal: false, selectedStartDate:"",selectedStartTime:"", selectedDueDate:"",selectedDueTime:"",currentLocation:"Select Class",
    teacherClassId:"", assignentId:"",selectedClassId:"",selectedClassName:"", selectedAssignment:"",selectedChipsValue:[],assignNewSelectedUserEmailStudentEmails: [],openClassListModal: false,selectedClass: "CLASS", selectedClassData:"",selectedFoldersData: {},inputValue:"", quizName: "", isValidateStartDate: false, isValidateEndDate: false, isValidateStartTime: false, isSameStartDueTime: false, isValidateEndTime: false, isStartDueDateEvent: false, isValidateCreateEvent: false})
  }
  handleClassListModal = () => {
    this.locations = []
    this.setState({openClassListModal: false, actionName: "", selectedClass: "CLASS", currentLocation:"Select Class",selectedClassData:"",selectedFoldersData: {}, classActiveTab: false, classesData: [], inputValue: "", quizName: "", assignNewSelectedClassName: "", assignNewSelectedFolderName: ""})
  }
  handleClassTabs = (status: boolean) => {
    this.setState({classActiveTab: status, inputValue: "", classesData: [], selectedClassData: ""});
    if (status) {
      // API call for Shared Library
      this.getSharedLibraryData("");
    } else {
      // API call for My Library
      this.getClassesData("");
    }
  }
  handleOpenClassModal = (actionName: string) => {
    this.getClassesData("")
    this.setState({openClassListModal: true, openAssignModal: false, actionName: actionName})
  }
  handleCloseDeleteConfirmationModal = () => {
    this.setState({
      openDeleteConfirmationModal: false
    });
  };
  handleOpenDeleteConfirmationModal = () => {
    this.setState({
      openDeleteConfirmationModal: true
    });
  };
  handleOpenEditModal = ()=>{
    this.setState({openEditModal:true});
  }
  handleCloseEditModal = () =>{
    this.setState({
      openEditModal:false,
      editValidDueDateEvent:false,
      editLessonNameValidate: false,
      editClassValidate: false,
      editValidStartDateEvent: false
    });
  }
  handleLessonNameChange = (event:React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({editLessonName: event.target.value})
  }
  handleEditClassChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({editClassName: event.target.value})
  }
  handleLessonObjectiveChange = (event:React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({editLessonObjective: event.target.value})
  }
  handleEditDueDateChange = (date:any) =>{
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    this.setState({editDueDate: formattedDate})
  }
  handleEditStartDateChange = (date:any) =>{
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    this.setState({editStartDate: formattedDate})
  }
  handleEditMyLibraryAssignment = (apiRequestCallId: string, responseJson: any, errorReponse: string) => {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiUpdateAssignmentCallId
    ) {
      if (responseJson) {
        this.setState({openEditModal: false,},()=>{
          this.setState({ loading: true, quizList: [] });
          this.getQuizData(!this.state.activeState ? "assignment" : "quiz");
        })
      }
    }
  }
  handleDeleteAssignment = (apiRequestCallId: string, responseJson: any, errorReponse: string) => {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiDeleteAssignmentCallId
    ) {
      if (responseJson) {
        this.setState({openEditModal: false,},()=>{
          this.setState({ loading: true, quizList: [], page:1 });
          this.getQuizData(!this.state.activeState ? "assignment" : "quiz");
        })
      }
    }
  }
  viewMyLibraryAssignment = (data: any) => {
    this.setState({
      editTaskId: data?.id,
      editAssignmentName: data?.attributes?.assignment?.data?.attributes?.assignment_name,
      editAssignmentClassName: data?.attributes?.assignment?.data?.attributes?.class_name,
      editLessonId: data?.attributes?.assignment?.data?.id,
      editLessonName: data?.attributes?.assignment?.data?.attributes?.assignment_name, 
      editLessonObjective: data?.attributes?.assignment?.data?.attributes?.details,
      editClassName: data?.attributes?.assignment?.data?.attributes?.teacher_classes_id,
      editStartDate: moment(data.attributes?.start_date).format('DD/MM/YYYY'),
      editDueDate: moment(data.attributes?.due_date).format('DD/MM/YYYY'),
    })
  }
  viewMyLibraryQuiz = (data: any) => {
    this.setState({
      editTaskId: data?.id,
      editQuizName: data?.attributes?.quiz?.data?.attributes?.quiz_name,
      editQuizClassName: data?.attributes?.quiz?.data?.attributes?.class_name,
      editLessonId: data?.attributes?.quiz?.data?.id,
      editLessonName: data?.attributes?.quiz?.data?.attributes?.quiz_name, 
      editLessonObjective: data?.attributes?.quiz?.data?.attributes?.details,
      editClassName: data?.attributes?.quiz?.data?.attributes?.teacher_classes_id,
      editStartDate: moment(data.attributes?.start_date).format('DD/MM/YYYY'),
      editDueDate: moment(data.attributes?.due_date).format('DD/MM/YYYY'),
    })
  }
  validateEditFuncLibrary = () => {
    let flag = false;
    if(!this.state.editLessonName.length){
      this.setState({editLessonNameValidate: true})  
      flag = true
    } else if (this.state.editClassName === 'Select Class') {
      this.setState({ editClassValidate: true });
      flag = true;
    } else if (!this.state.editStartDate.length) {
      this.setState({ editValidStartDateEvent: true });
      flag = true;
    } else if(!this.state.editDueDate.length){
      this.setState({editValidDueDateEvent: true})  
      flag = true
    }
    return flag;
  }
  editFuncAssignedTask = (id: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    if(this.validateEditFuncLibrary()){
      this.setState({isValidateEditLibrary: true})
      return;
    }

    const httpBody = {
      "due_date": this.state.editDueDate,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // const URL = !this.state.activeState ? configJSON.createAssignment : configJSON.updateQuizEndPoint;
    const URL = configJSON.editTaskApi;
    this.apiUpdateAssignmentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${URL}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  deleteFuncTask = (id: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const URL = configJSON.deleteTaskApi;
    this.apiDeleteAssignmentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${URL}?id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCancel = (data:string) => {
    if(data == "CLASS")
    {
      this.locations= [];
      this.setState({openClassListModal: false,inputValue:"",selectedClass: "CLASS",foldersList:[], selectedClassId:"0",selectedClassName:"",selectedAssignment:"",currentLocation:"Select Class",openAssignModal: false, assignNewTasksModal: false, teacherClassId:"", assignentId:"",selected:[],selectedClasses:[],selectedFoldersData:{},selectedClassData:"", assignNewSelectedClassId:0, assignNewselectedTeacherId:0, assignNewSelectedClassName: "", assignNewSelectedFolderName: "", assignNewSelectedUserEmailClassId: "", assignNewSelectedUserEmailClassName: "", assignNewSelectedUserGroupClassId: "", assignNewSelectedUserGroupClassName: "", selectedChipsValue: [], assignNewSelectedUserEmailStudentEmails: [], classActiveTab:false, classesData:[], quizName: "", selectedStartDate:"", selectedStartTime:"", selectedDueDate:"", selectedDueTime:"", isValidateStartDate: false, isValidateEndDate: false, isValidateStartTime: false, isSameStartDueTime: false, isValidateEndTime: false, isStartDueDateEvent: false, isValidateCreateEvent: false })
    } else if(data == "FOLDER")
      {
        this.popLocation();
        this.setState({inputValue:"", selectedClass: "CLASS", foldersList:[], selectedClassId:"0",selectedClassName:"",selectedAssignment:"",currentLocation:"Select Class",openAssignModal: false, assignNewTasksModal: false, teacherClassId:"", assignentId:"",selected:[],selectedClasses:[],selectedFoldersData: {},selectedClassData:"", assignNewSelectedClassId:0, assignNewselectedTeacherId:0, assignNewSelectedClassName: "", assignNewSelectedFolderName: "", assignNewSelectedUserEmailClassId: "", assignNewSelectedUserEmailClassName: "", assignNewSelectedUserGroupClassId: "", assignNewSelectedUserGroupClassName: "", selectedChipsValue: [], assignNewSelectedUserEmailStudentEmails: [], selectedStartDate:"", selectedStartTime:"", selectedDueDate:"", selectedDueTime:"", isValidateStartDate: false, isValidateEndDate: false, isValidateStartTime: false, isSameStartDueTime: false, isValidateEndTime: false, isStartDueDateEvent: false, isValidateCreateEvent: false})
    }else if(data == "ASSIGNMENTS")
      {
        this.popLocation();
        this.setState({inputValue:"", selectedClass: "FOLDER", selectedClassId:"0", selectedClassName:"", selectedAssignment:"",openAssignModal: false, assignNewTasksModal: false, teacherClassId:"", assignentId:"",selected:[],selectedClasses:[],selectedFoldersData: {},selectedClassData:"", selectedStartDate:"", selectedStartTime:"", selectedDueDate:"", selectedDueTime:"", isValidateStartDate: false, isValidateEndDate: false, isValidateStartTime: false, isSameStartDueTime: false, isValidateEndTime: false, isStartDueDateEvent: false, isValidateCreateEvent: false, selectedChipsValue: [], assignNewSelectedUserEmailStudentEmails: []})
    }else if(data == "SUBFOLDER")
      {
        this.popLocation();
        this.setState({inputValue:"", selectedClass: "ASSIGNMENTS", selectedClassId:"0", selectedClassName:"", selectedAssignment:"",openAssignModal: false, assignNewTasksModal: false, teacherClassId:"", assignentId:"",selected:[],selectedClasses:[],selectedFoldersData: {},selectedClassData:"", selectedStartDate:"", selectedStartTime:"", selectedDueDate:"", selectedDueTime:"", isValidateStartDate: false, isValidateEndDate: false, isValidateStartTime: false, isSameStartDueTime: false, isValidateEndTime: false, isStartDueDateEvent: false, isValidateCreateEvent: false, selectedChipsValue: [], assignNewSelectedUserEmailStudentEmails: []})
    }
    else{
      this.locations= []
      this.setState({inputValue:"", selectedClass: "CLASS", foldersList:[], selectedClassId:"0",selectedClassName:"",selectedAssignment:"",currentLocation:"Select Class",
       openAssignModal: false, assignNewTasksModal: false, teacherClassId:"", assignentId:"",selected:[],selectedClasses:[],selectedFoldersData: {},selectedClassData:"", assignNewSelectedClassId:0, assignNewselectedTeacherId:0, assignNewSelectedClassName: "", assignNewSelectedFolderName: "", assignNewSelectedUserEmailClassId: "", assignNewSelectedUserEmailClassName: "", assignNewSelectedUserGroupClassId: "", assignNewSelectedUserGroupClassName: "", selectedChipsValue: [], assignNewSelectedUserEmailStudentEmails: [], classActiveTab:false, classesData:[], quizName: "", selectedStartDate:"", selectedStartTime:"", selectedDueDate:"", selectedDueTime:"", isValidateStartDate: false, isValidateEndDate: false, isValidateStartTime: false, isSameStartDueTime: false, isValidateEndTime: false, isStartDueDateEvent: false, isValidateCreateEvent: false})
    }
  }

  getClassesData = (textData: string = "") => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClassesDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClassesDataApi}?class_name=${textData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    this.setState({ loading: true, classesData: [] });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getSharedLibraryData= (value: string) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSharedLibraryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/shared_libraries/index_shared_resources?search=${value}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
  
    this.setState({ loading: true, classesData: [] });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleAssignNewTaskModal = () => {
    this.locations = [];
    this.setState({assignNewTasksModal: false, openClassListModal: false, currentLocation:"Select Class", actionName: "", selectedClass:"CLASS", selectedClassName:"", selectedAssignment:"", selectedChipsValue: [], selectedClassId:"0", selectedClassData:"", selectedFoldersData:{}, classesData: [], classActiveTab: false, selectedStartDate:"", selectedStartTime:"", selectedDueDate:"", selectedDueTime:"", quizName: "", assignNewSelectedClassName: "", assignNewSelectedFolderName: "", assignNewSelectedUserEmailStudentEmails: [], inputValue: ""});
  }


  handleEmailTabs = (activeTab: string) => {
    this.setState({
      emailActiveTab:activeTab,
      selectedAssignment: "",
      selectedClassName: "",
      assignNewSelectedUserEmailStudentEmails: [],
      selectedChipsValue: [],
    })
    if(activeTab == "USEREMAIL"){
      this.searchStudentsMailAPi("");
    } else if(activeTab == "USERGROUP"){
      this.searchUserGroupApi("");
    }
  }
  getClasses(classId: any) {
    const loc: any = {
      renderView: "CLASS",
      breadcrumbDisplayName: "",
    };
    this.pushLocation(loc);
    this.setState({
      currentLocation: classId?.attributes?.class_name,
      selectedClass: "FOLDER",
      selectedTeacherId: classId.id//"teacher.id"
    });
  }
  getFolderRoute = ( data: any) => {
    const loc: any = {
      renderView: "FOLDER",
      breadcrumbDisplayName: this.state.currentLocation,
    };
    this.pushLocation(loc);
    this.setState({
      currentLocation: data.attributes.folder_name,
      selectedClass: "ASSIGNMENTS",
      selectedTeacherId: data.id,
      folderId:"",
      // selectedAssignmentList: [],
      teacherClassId: "",
      assignentId:"",
      selectedClassId:"",
    });
  }
  getSubFolderRoute = ( data: any) => {
    const loc: any = {
      renderView: "ASSIGNMENTS",
      breadcrumbDisplayName: this.state.currentLocation,
    };
    this.pushLocation(loc);
    this.setState({
      currentLocation: data.attributes.folder_name,
      selectedClass: "SUBFOLDER",
      selectedTeacherId: data.id,
      folderId:"",
      // selectedAssignmentList: [],
      teacherClassId: "",
      assignentId:"",
      selectedClassId:"",
    });
  }
  redirect(renderView: string) {
   const loc = this.peekLocation()

   while (renderView != this.peekLocation().renderView) {
     this.popLocation()
   }
   this.popLocation()
   this.setState({
     currentLocation: loc.breadcrumbDisplayName,
     selectedClass: renderView
   });


  }
  pushLocation(location: any) {
    this.locations.push(location);
  }
  popLocation() {
    if (this.locations.length == 0) return "Underflow";
    return this.locations.pop();
  }
  peekLocation() {
    return this.locations[this.locations.length - 1];
  }

  handleFolders = (item2: any, isInFolder = false) => {
    if (item2.type == "assignment_folder") {
      this.getFolderRoute(item2)
      this.setState({
        selectedFoldersData: {},
        assignNewSelectedFolderName: item2?.attributes?.assignments?.data[0]?.attributes?.assignment_name,
        // selectedClassId: item2?.attributes?.assignments?.data[0]?.id,
        selectedClass: "ASSIGNMENTS",
        folderId: item2.id,
        // selectedAssignmentList: item2.attributes.assignments?.data,
        teacherClassId: item2.attributes.teacher_class_id,
        // assignentId: item2.attributes.assignments?.data[0]?.id
      })
    } else if(item2.type ==	"folder"){
      this.getFolderRoute(item2)
      this.setState({
        selectedFoldersData: {},
        assignNewSelectedFolderName: item2?.attributes?.quizzes?.data[0]?.attributes?.quiz_name,
        // selectedClassId: item2?.attributes?.quizzes?.data[0]?.id,
        selectedClass: "ASSIGNMENTS",
        folderId: item2.id,
        // selectedAssignmentList: item2.attributes.quizzes?.data,
        teacherClassId: item2.attributes.teacher_class_id,
        // assignentId: item2.attributes.quizzes?.data[0]?.id
      })
    }else if(item2.type ==	"quizzes"){
      this.setState({
        selectedFoldersData: {},
        assignNewSelectedFolderName: item2?.attributes?.quiz_name,
        selectedClassId: item2?.id,
        assignentId: item2?.id,
        teacherClassId: item2?.attributes?.teacher_classes_id,
        folderId: isInFolder ? this.state.folderId : "",
      })
    } else {
      this.setState({
        selectedFoldersData: {},
        assignNewSelectedFolderName: item2?.attributes?.assignment_name,
        selectedClassId: item2?.id,
        assignentId: item2?.id,
        teacherClassId: item2?.attributes?.teacher_classes_id,
        folderId: isInFolder ? this.state.folderId : "",
      })
    }
  }
  handleSubFolders = (item2: any) => {
    if(item2.type==="folder" && this.state.actionName==="Quiz"){
      this.getSubFolderRoute(item2)
      this.setState({
        selectedFoldersData: {},
        assignNewSelectedFolderName: item2?.attributes?.quizzes?.data[0]?.attributes?.quiz_name,
        selectedClass: "SUBFOLDER",
        folderId: item2.id,
        selectedAssignmentList: item2.attributes.quizzes?.data,
        teacherClassId: item2.attributes.teacher_class_id,
        assignentId: item2.attributes.quizzes?.data[0]?.id
      })
    }else if(item2.type==="assignment_folder" && this.state.actionName==="Assignment"){
      this.getSubFolderRoute(item2)
      this.setState({
        selectedFoldersData: {},
        // assignNewSelectedFolderName: item2?.attributes?.quizzes?.data[0]?.attributes?.quiz_name,
        selectedClass: "SUBFOLDER",
        folderId: item2.id,
        // selectedAssignmentList: item2.attributes.quizzes?.data,
        teacherClassId: item2.attributes.teacher_class_id,
        assignentId: item2.attributes.quizzes?.data[0]?.id
      })
    }else if(item2.type ==	"quizzes"){
      this.setState({
        selectedFoldersData: {},
        assignNewSelectedFolderName: item2?.attributes?.quiz_name,
        selectedClassId: item2?.id,
        assignentId: item2?.id,
        teacherClassId: item2?.attributes?.teacher_classes_id,
        folderId:  this.state.folderId 
      })
    }else {
      this.setState({
        selectedFoldersData: {},
        assignNewSelectedFolderName: item2?.attributes?.assignment_name,
        selectedClassId: item2?.id,
        assignentId: item2?.id,
        teacherClassId: item2?.attributes?.teacher_classes_id,
        folderId:  this.state.folderId 
      })
    }
     
  }
  getFoldersData = (textData: string = "") => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    let apiEndPoint = "";
    if (this.state.actionName === "Assignment") {
      apiEndPoint = configJSON.getFoldersListApi;
    } else if (this.state.actionName === "Quiz") {
      apiEndPoint = configJSON.getQuizFoldersListApi;
    }
    this.getFoldersListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${apiEndPoint}?class_id=${this.state.assignNewSelectedClassId}&teacher_id=${this.state.assignNewselectedTeacherId}&search=${textData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
  
    this.setState({ loading: true, quizName: '', foldersList: [] });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleIsSameTimeValidate = () => {
    if (this.state.selectedStartDate === this.state.selectedDueDate && this.state.selectedStartTime === this.state.selectedDueTime) {
      return true;
    }
  }
  isAssignNowDisabled = () => {
    const {
      selectedAssignment,
      assignNewSelectedUserEmailStudentEmails,
      selectedChipsValue,
      selectedStartDate,
      selectedDueDate,
      selectedStartTime,
      selectedDueTime,
      isValidateCreateEvent,
      isStartDueDateEvent,
    } = this.state;

    const hasAssignmentOrUsers = selectedAssignment || assignNewSelectedUserEmailStudentEmails.length || selectedChipsValue.length;
    const hasValidDates = selectedStartDate && selectedDueDate;
    const hasValidTimes = selectedStartTime && selectedDueTime;
  
    return !(hasAssignmentOrUsers && hasValidDates && hasValidTimes && isValidateCreateEvent) || isStartDueDateEvent;
  };
  isValidateAssignTask = () => {
    let flag = false;
    let startDateTime = moment(this.state.selectedStartDate, "DD/MM/YYYY");
    let endDateTime = moment(this.state.selectedDueDate, "DD/MM/YYYY");
    if (!this.state.selectedStartDate.length) {
      this.setState({ isValidateStartDate: true })
      flag = true;
    } else if (!this.state.selectedDueDate.length) {
      this.setState({ isValidateEndDate: true })
      flag = true;
    } else if (!this.state.selectedStartTime.length) {
      this.setState({ isValidateStartTime: true })
      flag = true;
    } else if (!this.state.selectedDueTime.length) {
      this.setState({ isValidateEndTime: true })
      flag = true;
    } else if (startDateTime.isAfter(endDateTime)) {
      this.setState({ isStartDueDateEvent: true })
      flag = true;
    } else if (this.handleIsSameTimeValidate()) {
      this.setState({ isSameStartDueTime: true })
      flag = true
    }
    return flag;
  }
  assingTaskApi = () => {
    if (this.isValidateAssignTask()) {
      this.setState({ isValidateCreateEvent: true })
      return;
    }

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };

    let body: any = {
      "teacher_class_id": this.state.teacherClassId,
      "due_date": this.state.selectedDueDate,
      "due_time": this.state.selectedDueTime,
      "start_date": this.state.selectedStartDate,
      "start_time": this.state.selectedStartTime,
      "assign_class_id": this.state.selectedAssignment,
      "student_ids": this.state.assignNewSelectedUserEmailStudentEmails.map((item: any) => item.id),
      "user_group_ids": this.state.selectedChipsValue.map((item: any) => item.id)
    };
    if (this.state.actionName === "Assignment") {
      body = {
        ...body,
        "assignment_id": this.state.assignentId,
        "assignment_folder_id": this.state.folderId,
      }
    } else if (this.state.actionName === "Quiz") {
      body = {
        ...body,
        "assignment_id": this.state.assignentId,
        "quiz_id": this.state.assignentId,
        "quiz_folder_id": this.state.folderId,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.assignTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.assignTaskApi}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Post
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  searchUserGroupApi = (search: string = "") => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchUserGroupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchUserGroupApi}?class_id=${this.state.assignNewSelectedUserGroupClassId}&search=${search}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  searchStudentsMailAPi = (searchValue: string = "") => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchStudentsMailAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchStudentsMailAPi}?class_id=${this.state.assignNewSelectedUserEmailClassId}&search=${searchValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleUserEmailClassNameChange = (event: any, newValue: any) => {
    this.setState({
      assignNewSelectedUserEmailClassId: newValue?.id,
      assignNewSelectedUserEmailClassName: newValue?.attributes?.class_name,
      studentMails: [],
      assignNewSelectedUserEmailStudentEmails: [],
    }, () => {
      this.searchStudentsMailAPi(newValue?.attributes?.class_name);
    });
  }
  handleUserGroupClassNameChange = (event: any, newValue: any) => {
    this.setState({
      assignNewSelectedUserGroupClassId: newValue?.id,
      assignNewSelectedUserGroupClassName: newValue?.attributes?.class_name,
      selectedChipsValue: [],
      filteredUserGroupData: [],
    }, () => {
      this.searchUserGroupApi();
    });
  }
  handleUserEmailStudentEmailChange = (event: any, newValue: any) => {
    this.setState({ assignNewSelectedUserEmailStudentEmails: newValue });
  }
  handleStudentChange = (event: any) => {
    let { value, name } = event.target
    if (name === "class") {
      this.setState({ teacherClassId: value[value.length - 1], selectedClasses: value })
    } else {
      this.setState({ selected: value })
    }
  }

  handleChange = (event: any, newValue: any) => {
    this.setState({ selectedChipsValue: newValue });
  };

  handleInputChange = (event: any, newInputValue: string) => {
    this.setState({ searchnputValue: newInputValue });
    this.userGroupEmailsAPI(newInputValue)
  };

  deleteChips = (index: number) => {
    this.setState((prevState) => {
      const newChips = [...prevState.selectedChipsValue];
      newChips.splice(index, 1);
      return { selectedChipsValue: newChips };
    });

  }

  userGroupEmailsAPI = (searchData: string) => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userGroupEmailsAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userGroupApi}?class_id=${this.state.teacherClassId}&search=${searchData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getOption = (option: any) => {
    const label = option?.attributes?.user_group_name;
    return typeof label === 'string' ? label : '';
  }

  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };


  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleFoldersData = (id: string, selectedClassName: string) => {
    this.setState({selectedAssignment: id, selectedClassName: selectedClassName })
  }
  handleCondition = () => {
    if(this.state.assignentId){
      this.handleClasses()
    } else if(this.state.selectedClass==="FOLDER"){
      this.handleFolders(this.state.selectedFoldersData);
    }else{
      this.handleSubFolders(this.state.selectedSubFolderData)
    }
  }
  renderStyle = () => {
    if(this.state.emailActiveTab == "USEREMAIL"){ 
      return  "20px"
   }
    else if(this.state.emailActiveTab == "CLASS"){
      return "65px"
    }else{
      return "250px"
    }
  }
  handleSearch = (value:any) => {
    this.setState({ inputValue: value, quizName: value }, () => {
      if (this.state.selectedClass === "CLASS") {
        if (this.state.classActiveTab && !this.state.assignNewTasksModal) {
          // Shared-Library Screen - Assignment & Quiz Data
          this.getSharedLibraryData(value);
        } else {
          // My-Library Screen OR Assign-New-Task Screen - Classes Data
          this.getClassesData(value);
        }
      } else if (this.state.selectedClass === "FOLDER") {
        // Folder Screen - Assignment/Quiz Folder List Data
        this.getFoldersData(value);
      }
    });
  }

  redirectToLibrary = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TeacherLibrary");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  redirectToLibraryassignment = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AssingnNewTask");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  setTaskPageNo = (pageNo: number) => {
    this.setState({
      taskListPageNo: pageNo
    })
  }

  setTaskPageLimit = (pageLimit: number) => {
    this.setState({
      taskListPageLimit: pageLimit
    })
  }

  enableLimitSnackbar = () => {
    this.setState({
      isLimitModalOpen: true,
    })
  }

  handleCloseLimitSnackbar = () => {
    this.setState({
      isLimitModalOpen: false,
    })
  }
  componentDidUpdate = (prevProps: Props, prevState: S) => {
    if (this.state.tabValue !== prevState.tabValue||this.state.activeState!==prevState.activeState) {
      this.setState({ 
        quizName:""
      });
    }
  };
  getQuizFolderData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
                        
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getFoldersQuizListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showQuizFolder}?quiz_folder_id=${this.state.selectedFoldersData.id}&search=${""}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetFoldersQuizListApiData = (apiRequestCallId: any, message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFoldersQuizListCallId !== null &&
      this.getFoldersQuizListCallId === apiRequestCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson) {
        const quizzes = responseJson.items
          .filter((item: any) => item.data.type === 'quizzes')
          .map((quiz: any) => ({
            data: quiz.data,
          }));

        const folders = responseJson.items
          .filter((item: any) => item.data.type === 'folder')
          .map((folder: any) => ({
            data: folder.data,
          }));
          
        this.setState({
            selectedSubFolderList:[{folders:folders,quizzes:quizzes}],
            folderData:folders,
            quizzesData:quizzes
        });
      }
    }
  };
  getAssignmentFolderData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
                        
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getFoldersAssignmentListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showAssignmentFolder}?assignment_folder_id=${this.state.selectedFoldersData.id}&search=${""}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetFoldersAssignmentListApiData = (apiRequestCallId: any, message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFoldersAssignmentListApiCallId !== null &&
      this.getFoldersAssignmentListApiCallId === apiRequestCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson) {
        const assignment = responseJson.items
          .filter((item: any) => item.data.type === 'assignments')
          .map((assignment: any) => ({
            data: assignment.data,
          }));

        const folders = responseJson.items
          .filter((item: any) => item.data.type === 'assignment_folder')
          .map((folder: any) => ({
            data: folder.data,
          }));
        this.setState({
            selectedSubFolderList:[{folders:folders,quizzes:assignment}],
            folderData:folders,
            quizzesData:assignment
        });
      }
    }
  };
  getAssignmentSubFolderData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
                        
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getSubFoldersAssignmentListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showAssignmentFolder}?assignment_folder_id=${this.state.selectedSubFolderData.id}&search=${""}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetSubFoldersAssignmentListApiData = (apiRequestCallId: any, message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSubFoldersAssignmentListApiCallId !== null &&
      this.getSubFoldersAssignmentListApiCallId === apiRequestCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson) {
        const assignment = responseJson.items
          .map((item:any) => item.data);
        this.setState({
            selectedAssignmentList:assignment,
        });
      }
    }
  };
  // Customizable Area End

}
