import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  ListItemText,
  Modal,
  Tab,
  Breadcrumbs,
  Link,
  TextField,
  Select,
  MenuItem,
  Theme
} from "@material-ui/core";
import TeacherListController, { Props } from "./TeacherCalendarController.web";
import { DeleteInactive, EditInactive, EditActive, StudentIcon, SuccessIcon, calendarDefaultIcon2, calendarIcon, calendarPickerIcon, folderDefaultIcon, groupAssignmentIcon, groupLessonIcon, groupQuizIcon, DeleteActive } from "../../assets";
import { Calendar, momentLocalizer, } from "react-big-calendar";
import moment from "moment";
import CustomDatePicker from "../../SchoolAdmin/TeacherCalendar/CustomDatePicker.web";
import Loader from "../../../../../components/src/Loader.web";
import AppHeaderWeb from "../../../../../components/src/AppHeader.web";
import Sidebar from "../../../../../components/src/Sidebar.web";
import "../../SchoolAdmin/TeacherCalendar/react-big-calendar.css";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { StyledMenu, StyledMenuItem } from "../../../../../components/src/StyledMenu.web";
import ClearIcon from "@material-ui/icons/Clear";
import SearchInput from "../../../../../components/src/SearchInput.web";
import { styled } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import "./style/React-big-calendar.css"

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DeleteEvent from "../../SchoolAdmin/TeacherCalendar/DeleteEvent.web";


const Card = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  width: "360px",
  height: "135px",
  borderRadius: "8px",
  justifyContent: "center",
  gap: "20px",
  alignItems: "center",
  position: "relative",
  border: "1px solid rgba(149, 157, 165, 0.2)",
  "&:hover": {
    background: "#ebf8fe",
    borderColor: "#3093DDFF",
    color: "#121212",
  },
  [theme.breakpoints.down("xs")]: {
    gap: "7px",
    height: "180px",
    width:"100%",
    flexDirection:"column"
  },
}));

const AssignmentInput = styled(Box)(({ theme }) => ({
  display:'flex',
  alignItems: 'baseline',
  marginLeft: '2rem',
  justifyContent:'space-between',
  width:'fit-content',
  [theme.breakpoints.down("xs")]: {
    margin: 0,
    flexDirection:'column',
    width: "100%"
  },
}));

const InputBoxs = styled(Box)(({ theme }) => ({
  marginLeft:'2rem',
  [theme.breakpoints.down("xs")]: {
    margin: 0,
    width:'100%'
  },
}));

const TextFields = styled(TextField)(({ theme }) => ({
 ...webStyle.inputDateStyle,
 width:'400px',
  [theme.breakpoints.down('xs')]: {
    width:"100%"
  },
}));


const ObjTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    ...webStyle.inputDateStyle,
    width: "830px",
    height: "120px",
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down('xs')]: {
      width:"100% "
    },
  },
}));
 


const SelectField = styled(Select)(({ theme }) => ({
  ...webStyle.inputDateStyle,
  width:'400px',
  marginTop:"16px",
   [theme.breakpoints.down('xs')]: {
     width:"100% !important"
   },
 }));
 


const TextFieldContainer = styled(Box)(({ theme }) => ({
  marginBottom:"1rem",
   [theme.breakpoints.down('xs')]: {
     width:"100%"
   },
 }));

const CalendarBtnLayout = styled(Box)(({ theme }) => ({
  display: "flex",
  width: '100%',
  justifyContent: "space-between",
  alignItems: 'baseline',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column', 
  },
}));

const EditPopup = styled(Box)(({ theme }: { theme: Theme }) => ({
  ...webStyle.paperOption,
  width: "1000px",
  [theme.breakpoints.down('xs')]: {
    width: "90%",
    overflow:"auto",
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',  
    'scrollbar-width': 'none', 
  },
}));

const OptionIconBtn = styled(Button)(({ theme }) => ({
  minWidth: 0,
    transform: 'rotate(90deg)',
    padding: '4px',
    float:'right' as 'right',
    top:'4px',
    right:'22px',
  [theme.breakpoints.down('xs')]: {
    right: '2px !important',
  },
}));

const InnerEditAssign = styled(Box)(({ theme }) => ({
  height: "580px",
  width: "900px",
  backgroundColor: "#FFFFFF",
  [theme.breakpoints.down("xs")]: {
    width:"90%"
  },
}));

const DateMainContainer = styled(Box)(({ theme }) => ({
 display:"flex",
 alignItems:"baseline",
  [theme.breakpoints.down("xs")]: {
    alignItems:'initial',
    flexDirection:'column'
  },
}));

const EditText = styled(Typography)(({ theme }) => ({
  fontFamily: "poppins",
  color: "#2C2C2E",
  fontSize: 28,
  marginBottom: "15px",
  marginLeft: "30px",
  fontWeight: 600,
  [theme.breakpoints.down("xs")]: {
    marginLeft:0
  },
}));

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    ...webStyle.inputDateStyle,

    [theme.breakpoints.down('xs')]: {
      width:'100% !important'
    },
  },
}));

const StyledKeyboardTimePicker = styled(KeyboardTimePicker)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    ...webStyle.inputDateStyle,
    width:"200px",
    [theme.breakpoints.down('xs')]: {
      width:'100% !important'
    },
  },
}));

const CreateEventTimeBtn = styled(Box)(({ theme }) => ({
  ...webStyle.buttonContainer, 
  top: "25rem",
  [theme.breakpoints.down('xs')]: {
    top: "12rem",
    padding: 0,
    display:"flex",
    justifyContent:"space-around"
  },

  '@media (min-height: 900px) and (max-height: 950px)': {
    
  },


  '@media (min-height: 850px) and (max-height: 900px)': {
     top: "15rem",
  },

  '@media (min-height: 750px) and (max-height: 800px)': {
    top: "10rem",
  },  
}));
const SuccessBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "460px",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "35px 0",
  '@media (min-width: 300px) and (max-width: 550px)': {
    width: '361px',
    height: '317px'
  },
  [theme.breakpoints.down('xs')]: {
    // Add specific styles for extra small screens if needed
  }
}));

const SaveAssignBtn = styled(Box)(({ theme }) => ({
  ...webStyle.buttonContainer, 
  [theme.breakpoints.down('xs')]: {
    padding:"8px 0 21px 0 !important"
  },
}));

const FolderList = styled(Box)(({ theme }) => ({
  overflow: "auto",
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
  paddingLeft: "35px",
  height: "465px",
  [theme.breakpoints.down("xs")]: {
    paddingLeft: 0,
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',  
    'scrollbar-width': 'none', 
  },

  '@media (min-height: 900px) and (max-height: 950px)': {
    height: "60%",
  },

  '@media (min-height: 800px) and (max-height: 850px)': {
    height: "57% ",
  },

  '@media (min-height: 850px) and (max-height: 900px)': {
    height: "58%",
  },

  '@media (min-height: 750px) and (max-height: 800px)': {
    height: "53%",
  },  
}));

const CurrentDateBox = styled(Box)(({ theme }) => ({
  padding: "0 35px",
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
  [theme.breakpoints.down('xs')]: {
    padding: "0",
    width: "100%",
    justifyContent: "center"
  },
}));

const QuizFolderUi = styled(Box)(({ theme }) => ({
  overflow: "auto",
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
  paddingLeft: "35px",
  height: "465px",
  [theme.breakpoints.down("xs")]: {
    padding: "0",
    width: "100%",
    justifyContent: "center",
  },
}));

const DueDateContainer = styled(Box)(({ theme }) => ({
  display:"flex",
  alignItems:"baseline",
  marginLeft:"2rem",
  [theme.breakpoints.down("xs")]: {
    marginLeft:0,
    marginTop:"10px",
    flexDirection:"column"
  },
}));


const CreateEventTime = styled(Box)(({ theme }: { theme: Theme }) => ({
  ...webStyle.paperOption,
  width: "1208px",
  [theme.breakpoints.down('xs')]: {
    width: "90%",
    height: "90%",
  },
}));

const InnerBoxTime = styled(Box)(({ theme }) => ({
  height: "800px",
  width: "1208px", 
  backgroundColor: "#FFFFFF",
  [theme.breakpoints.down('xs')]: {
   height:"90%",
   width:"90%"
  },
}));

const StartEndTime = styled(Box)(({ theme }) => ({
  display:'flex',
  alignItems:'baseline',
  marginLeft: "2rem",
  [theme.breakpoints.down('xs')]: {
   marginLeft: 0,
  flexDirection: "column"
  },
}));

const CreateSetTime = styled(Typography)(({ theme }) => ({
  fontFamily: "poppins",
  color: "#2C2C2E",
  fontSize: 28,
  marginBottom: "15px",
  marginLeft: "30px",
  fontWeight: 600,
  [theme.breakpoints.down("xs")]: {
    marginLeft:0
  },
})); 


const CurrentDate =  styled(Typography)(({ theme }) => ({
  color: "rgb(43, 61, 125)",
  paddingRight: "15px",
  fontWeight: 600 as 600,
  fontSize: "22px",
  fontFamily: "Poppins",
  [theme.breakpoints.down('xs')]: {
    fontSize: "17px"
  },
}));

const CreateEventButton = styled(Button)(({ theme }) => ({
  backgroundImage: 'linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)',
  color: "white",
  border: "1px solid #979797",
  borderRadius: "10px" as "10px",
  height: "60px",
  marginRight: "32.5px",
  width: "10rem",
  fontSize: '15px',
  textTransform: 'capitalize' as 'capitalize',

  [theme.breakpoints.down('xs')]: {
    width: "90%",
    marginRight: 0,
  },
}));



const ObjectiveContainer = styled(Box)(({ theme }) => ({
  marginLeft: "2rem",
  marginBottom: "2rem",
  [theme.breakpoints.down('xs')]: {
    margin: 0,
    paddingTop:"25px",
    paddingBottom: "25px"
  },
}));

const StartDateContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems:"baseline",
  marginLeft:"2rem",
  [theme.breakpoints.down('xs')]: {
    margin: 0,
    flexDirection:'column'
  },
}));


const CreateEventBtnLayout = styled(Button)(({ theme }) => ({

  [theme.breakpoints.down('xs')]: {
    width: "100%",
    justifyContent: "center",
  },
}));

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  borderRadius: "10px",
  padding: "15px 30px",
  minHeight: "204px",
  cursor: "pointer",
  maxWidth: "180px",
  flexDirection: "column" as "column",
});


const StyledWeekDate = styled(Box)(({ theme }) => ({

  "&.selected": {
    display: "flex",
    marginTop: "16px",
    marginBottom: "8px",
    paddingTop: "20px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    flexDirection: "column",
    width: "93px",
    border: "1px solid #979797",
    borderRadius: "10px",
    height: "111px",
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      [theme.breakpoints.down('xs')]: {
        width: "80px",
        height:"80px",
        justifyContent: "center",
        paddingTop: "6px"
      },
  },

  "&.unSelected": {
    paddingTop: "20px",
    flexDirection: "column",
    display: "flex",
    marginBottom: "8px",
    marginTop: "16px",
    width: "93px",
    height: "111px",
    [theme.breakpoints.down('xs')]: {
      width: "80px",
      height:"80px",
      justifyContent: "center",
      paddingTop:"6px"
    },
  },
}));

const StyledWeekText = styled("span")(({ theme }) => ({

  "&.highlightedWeekHeaderDayLabel": {
    fontFamily: "Poppins",
    color: "#FFF",
    fontWeight: 500 as 500,
    textAlign: "center" as "center",
    marginBottom: "10px",
    lineHeight: "27px",
    fontSize: "24px",
      [theme.breakpoints.down('xs')]: {
        fontSize:"20px",
        lineHeight:"10px"
      },
  },

  "&.weekHeaderDayLabel": {
    lineHeight: "27px",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 500 as 500,
    color: "#858585",
    marginBottom: "10px",
    textAlign: "center" as "center",
    [theme.breakpoints.down('xs')]: {
      fontSize:"20px",
      lineHeight:"10px"
    },
  },
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 800 as 800,
  fontFamily: "Poppins",
  fontSize: "14px",
  overflow: "hidden" as "hidden",
  marginBottom: "4px",
  color: "#2B3D7D",
  textOverflow: "ellipsis" as "ellipsis",
  whiteSpace: "nowrap" as "nowrap",
  height: "2em",
  paddingTop: '5px',
  [theme.breakpoints.down('xs')]: {
    width: "100%",
    textAlign:"center"
  },
}));

const OptionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "28px",
  color: "#2B3D7D",
  fontWeight: 600,
  marginBottom: "40px",
  paddingTop: '5px',
  [theme.breakpoints.down('xs')]: {
    fontSize: "1.1rem",
    marginTop: "20px"
  },
}));

const ClassName = styled(Typography)(({ theme }) => ({
  fontFamily: "poppins",
  lineHeight: "32px",
  fontSize: "20px",
  letterSpacing: "0.2px",
  marginBottom: "10px",
  [theme.breakpoints.down("xs")]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const EventMainCard = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  paddingLeft: "10px",
  paddingRight:"10px",
  paddingTop: '2px',
  position: "relative",
  [theme.breakpoints.down('xs')]: {
  },
}));

const CreateEventSearch = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    width:"100%"
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex", 
  position: "relative" as "relative",
  top: '2rem',
  justifyContent: "flex-end" as "flex-end",
  gap: "10px",
  alignItems: "center",
  padding: "30px 52px 0px 52px",
  [theme.breakpoints.down('xs')]: {
    padding: 0,
    top: "1rem",
    gap: "23px",
    justifyContent:"space-around"
  },
}));

const TabContainer = styled(Box)(({ theme }) => ({
  
  borderRadius: "4px",
  marginLeft: "35px",
  width: '36.1%',
  [theme.breakpoints.down('xs')]: {
    width:"100%",
    marginLeft:0
  },
}));

const CreateEventText = styled(Typography)(({ theme }) => ({
  fontFamily: "poppins",
  color: "#2B3D7D",
  fontSize: 24,
  marginBottom: "10px",
  marginLeft: "30px",
  fontWeight: 600,
  [theme.breakpoints.down("xs")]: {
    marginLeft: 0,
  },
}));

const TabAfter = styled(Tab)(({ theme }) => ({
  fontWeight: 600,
  color: "#FFFFFF",
  textTransform: "capitalize" as "capitalize",
  backgroundColor: "#2B3D7D",
  fontSize: "20px",
  borderRadius: "4px",
  width: "218px",
  opacity: 1,
  [theme.breakpoints.down("xs")]: {
    minWidth: "100%",
  },
}));


const TabBefore = styled(Tab)(({ theme }) => ({
  color: "#2B3D7D",
  fontWeight: 500,
  fontSize: "20px",
  width: "218px",
  textTransform: "capitalize" as "capitalize",
  borderRadius: "4px",
  border: "1px solid #2B3D7D",
  [theme.breakpoints.down("xs")]: {
    minWidth: "100%",
  },
}));



const ItemBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  border: "1px solid rgba(149, 157, 165, 0.2)",
  borderRadius: "10px",
  padding: "15px 30px",
  minHeight: "204px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  cursor: "pointer",
  maxWidth: "180px",
  flexDirection: "column" as "column",
  "&:hover": {
    background: "#ebf8fe",
    borderColor: "#3093DDFF",
    color: "#121212"
  },
  [theme.breakpoints.down('xs')]: {
    minHeight: "160px",

  },
}));

const EventCard = styled(Box)(({ theme }) => ({
  display:"flex",
  justifyContent:"space-between",
  [theme.breakpoints.down('xs')]: {
    alignItems:"center",
    gap:"10px",
    flexDirection: "column"
  },
}));


const SearchBox = styled(Box)(({ theme }) => ({
  width: "456px",
  [theme.breakpoints.down('xs')]: {
    width: "100%"
  },
}));

const ClassList = styled(Box)(({ theme }) => ({
  height: "465px",
  overflow: "auto",
  display: "flex",
  flexWrap: "wrap" as "wrap",
  gap: "20px",
  paddingLeft: "35px",
  
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    paddingLeft: "0px",
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',  
    'scrollbar-width': 'none', 
  },

  '@media (min-height: 900px) and (max-height: 950px)': {
    height: "60%",
  },

  '@media (min-height: 800px) and (max-height: 850px)': {
    height: "57% ",
  },

  '@media (min-height: 850px) and (max-height: 900px)': {
    height: "58%",
  },

  '@media (min-height: 750px) and (max-height: 800px)': {
    height: "53%",
  },  
}));


const MainModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
    width: "740px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "35px 0",
  [theme.breakpoints.down('xs')]: {
    width: "90%",
    padding: "26px 0",
  },
}));

const ClassDetails = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
}));


const OptionsLayout = styled(Box)(({ theme }) => ({
  display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginBottom: "40px",
  [theme.breakpoints.down('xs')]: {
    flexDirection:"column",
    alignItems: "center",
    gap: "20px",
    padding: "0px 10px",
    textAlign: "center"
  },
}));

const CreateEventModal = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
    width: "1208px",
    backgroundColor: "white",
    borderRadius: "10px",
    display: "flex",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: "35px 0",
    justifyContent: "center",
    alignItems: "center",
  [theme.breakpoints.down('xs')]: {
    width:"90%",
    height:"90%"

  },
}));

const InnerEventModal = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF", 
  width: "1208px", 
  height: "800px",
  [theme.breakpoints.down('xs')]: {
    width:"90%",

    '@media (min-height: 750px) and (max-height: 800px)': {
      height: "100%",
    },

    
  },
}));

const CalendarBox = styled(Box)(({ theme }) => ({
  padding: "50px 35px",
  height: '70%',
  [theme.breakpoints.down('xs')]: {
    padding: "19px 0px"
  },
}));



class Teachers extends TeacherListController {
  constructor(props: Props) {
    super(props);
  }
  private localizer = momentLocalizer(moment);

  renderPropsEvent = (props: any) => (
    <this.handleMyEvents {...props} handleDeleteEvent={this.handleDeleteEvent} />
  )

  handleRenderWeekHeader = (props: any) => (
    <this.MyWeekHeader selectedDate={this.state.selectedDate} {...props} />
  )

  handleRenderCalendar = () => ({
    event: this.renderPropsEvent,
    week: {
      header: this.handleRenderWeekHeader,
    },
  })


  MyWeekHeader = (props: any) => {
    const { label, selectedDate } = props;
    const [date, day] = label.split(" ");
    const weekDate = date.toString();
    const selectedWeekDate = selectedDate
      .getDate()
      .toString()
      .padStart(2, "0");

     


    return (
      <StyledWeekDate
        className={weekDate === selectedWeekDate ? 'selected' : 'unSelected'}
        data-testid="weekHeaderClick"
        onClick={this.handleDateChange.bind(this, props?.date)}
      >
        <StyledWeekText
          className={weekDate === selectedWeekDate ? 'highlightedWeekHeaderDayLabel' : 'weekHeaderDayLabel'}
        >
          {day}
        </StyledWeekText>
        <span
          style={
            weekDate === selectedWeekDate
              ? webStyle.highlightedWeekHeaderDateLabel
              : webStyle.weekHeaderDateLabel
          }
        >
          {date}
        </span>
      </StyledWeekDate>
    );
  };

  handleMyContextMenu = (props: any) => {
    const {
      anchorEl,
      openContextMenu,
      handleCloseContextMenu,
      handleOpenDeleteEvent,
      dataTestID,
    } = props;
    return (
      <StyledMenu
        data-testid={dataTestID}
        anchorEl={anchorEl}
        open={openContextMenu}
        onClose={handleCloseContextMenu}
        anchorCenter={true}
      >
        <StyledMenuItem
          data-testid="editBtn"
          onMouseEnter={(e) => {
            if (this.state.imageRefEditEvents.current) {
              this.state.imageRefEditEvents.current.src = EditActive
            }
          }
          }
          onMouseLeave={(e) => {
            if (this.state.imageRefEditEvents.current) {
              (this.state.imageRefEditEvents.current.src = EditInactive)
            }
          }
          }
          onClick={this.handleOpenEditMenu}
        >
          <img
            src={EditInactive}
            ref={this.state.imageRefEditEvents}
            style={{ marginRight: "10px" }}
          />
          <ListItemText
            primary={`Edit ${this.handleEditEventTypeCheck()}`}
            color="#48484A"
          />
        </StyledMenuItem>
        <StyledMenuItem
          data-testid="deleteBtn"
          onClick={handleOpenDeleteEvent}
          onMouseEnter={(e) => {
            if (this.state.imageRefDeleteEvents.current) {
              (this.state.imageRefDeleteEvents.current.src = DeleteActive)
            }
          }
          }
          onMouseLeave={(e) => {
            if (this.state.imageRefDeleteEvents.current) {
              (this.state.imageRefDeleteEvents.current.src = DeleteInactive)
            }
          }
          }
        >
          <img ref={this.state.imageRefDeleteEvents} src={DeleteInactive} style={{ marginRight: "10px" }} />
          <ListItemText primary="Delete" color="#48484A" />
        </StyledMenuItem>
      </StyledMenu>
    );
  };

  handleColorEvents = (event: any) => {
    if (event?.type === 'lesson') {
      return "#007AFF"
    } else if (event?.type === 'quiz') {
      return "#39C6B5"
    }else {
      return "#FF9500"
    }
  }

  handleBrowserCheck = () => {
    if (navigator.userAgent.includes('Chrome') || navigator.userAgent.includes('Edge') || navigator.userAgent.includes('Safari')) {
      return true
    } else {
      return false
    }
  }

  handleMyEvents = (eventProps: any) => {
    const { handleDeleteEvent } = eventProps;
    const truncatedTitle = eventProps.title;  

    return (
      <>
        <Box
          style={webStyle.eventBox}
          data-testid="open-customized-menu-box"
          className="unique-calendar"
        >
          <Box style={{
            backgroundColor: this.handleColorEvents(eventProps?.event),
            height: '100%',
            width: '1.2%',
          }} />
          <EventMainCard>
            {!eventProps?.event?.overlap && <Box style={webStyle.eventTitleBox}>
              <EventTitle>
                {truncatedTitle}
              </EventTitle>
              <Box style={{
                width: this.handleBrowserCheck() ? '-webkit-fill-available' : "100%",
                zIndex: 99999,
                top: '0px',
                right:"0px",
                position: 'absolute',
                height: this.handleBrowserCheck() ? '-webkit-fill-available' : "100%",
              }} onClick={this.handleOpenContextMenuFromIcon.bind(this, eventProps)}>
                <OptionIconBtn
                  aria-haspopup="true"
                  data-testid="open-customized-menu-btn"
                  style={webStyle.optionIconBtn}
                >
                  <MoreVertIcon style={webStyle.optionIcon} />
                </OptionIconBtn>
              </Box>
            </Box>}
            <EventCard>
              <Box>
                <Typography style={webStyle.eventDueDate}>
                  Start Date & Time
                </Typography>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={calendarIcon} style={{ height: "17px", width: "17px", marginRight: '2px', marginLeft: '-2px' }} />
                  <Typography style={{ color: 'black', display: 'flex', fontSize: '14px' }}>{moment(eventProps?.event?.startDate).format("DD/MM/YYYY")} {eventProps?.event?.startTime}</Typography>
                </Box>
              </Box>

              <Box>
                <Typography style={webStyle.eventDueDate}>
                  Due Date & Time
                </Typography>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={calendarIcon} style={{ height: "17px", width: "17px", marginRight: '2px', marginLeft: '-2px' }} />
                  <Typography style={{ color: 'black', display: 'flex', fontSize: '14px' }}>{moment(eventProps?.event?.endDate).format("DD/MM/YYYY")} {eventProps?.event?.endTime}</Typography>
                </Box>
              </Box>

              <this.handleMyContextMenu
                dataTestID="customized-menu"
                anchorEl={this.state.anchorElFromIcon}
                openContextMenu={this.state.openContextMenuFromIcon}
                handleCloseContextMenu={this.handleCloseContextMenuFromIcon}
                handleOpenDeleteEvent={this.handleOpenDeleteEvent}
                handleCloseDeleteEvent={this.handleCloseDeleteEvent}
                handleDeleteEvent={handleDeleteEvent}
                eventProps={eventProps}
              />
            </EventCard>
          </EventMainCard>
        </Box>
        <Modal
          data-testid="delete-modal"
          open={this.state.openDeleteEvent}
          onClose={this.handleCloseDeleteEvent}
        >
          <DeleteEvent
            handleDeleteEvent={
              handleDeleteEvent.bind(this,
                this.state.eventPropsObj?.event.id,
                this.state.eventPropsObj?.event.type,)}
            handleCancelDeleteEvent={this.handleCloseDeleteEvent}
            title={this.state.eventPropsObj?.title}
          />
        </Modal>
      </>
    );
  };

  handleClassPageBtn = (top?: any) => {
    return <ButtonContainer sx={{...top}}>
      <Button style={{
        width: "150", height: "52", border: "0.5px solid #3F526D", borderRadius: "8px", color: "#3F526D",
        fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textAlign: "center",
        textTransform: "capitalize"
      }}
        data-testid="handleTeacherPreviousModal"
        onClick={this.handleTeacherPreviousModal.bind(this)}
      >Previous</Button>
      <Button
        style={{
          width: "150", height: "52", borderRadius: "8px", textAlign: "center", color: "white",
          fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textTransform: "capitalize",
          backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
          cursor: this.state.isNextBtnDisabled ? 'not-allowed' : "pointer"
        }}
        data-testid="handleNextPageFolder"
        disabled={this.state.isNextBtnDisabled}
        onClick={this.handleNextPageFolder.bind(this,)}
      >
        Next
      </Button>
    </ButtonContainer>
  }

  handleClassUi = () => {
    if (this.state.selectedTeacherClass === "CLASS") {
      if (this.state.teacherClassesData?.length > 0) {
        return <Box>
          <ClassList>
            {
              this.state.teacherClassesData?.map((item2: any) =>
                <Card
                  data-testid="getFoldersDataId"
                  onClick={this.getTeacherClasses.bind(this, "", item2)}
                  style={this.state.selectedTeacherId === item2?.id ? webStyle.selectedCard : {}}
                >
                  <img src={item2.attributes.attachment === null ? calendarDefaultIcon2 : item2.attributes.attachment} style={{ height: "99px", width: "110px" }} />
                  <ClassDetails>
                    <ClassName>{item2.attributes.class_name}</ClassName>
                    <Box style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                      <img src={StudentIcon} style={webStyle.heightWidth14px} />
                      <Typography style={webStyle.countTypo}>{`Student Count - ${item2.attributes.student_count}`}</Typography>
                    </Box>
                  </ClassDetails>
                </Card>
              )
            }
          </ClassList>
          {this.handleClassPageBtn()}
        </Box>
      } else {
        return <Box>
          {this.state.loading && <span style={{ width: "100%", display: "flex", justifyContent: "center", height: '100%', alignItems: "center" }}><Loader loading={this.state.loading} /></span>}
          <Box>
            <Typography style={{ ...webStyle.noRecords }}>No records found</Typography>
          </Box>
          {this.state.isClassNotFound && this.handleClassPageBtn({ top: '27rem' })}
        </Box>
      }
    }
  }

  handleFolderPageBtn = (top?: any) => {
    return <>
      <ButtonContainer sx={{...top}}>
        <Button style={{
          width: "150", height: "52", border: "0.5px solid #3F526D", borderRadius: "8px", color: "#3F526D",
          fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textAlign: "center",
          textTransform: "capitalize"
        }}
          data-testid="handleClassPreviousModal"
          onClick={this.handleClassPreviousModal.bind(this)}
        >Previous</Button>
        <Button
          style={{
            width: "150", height: "52", borderRadius: "8px", textAlign: "center", color: "white",
            fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textTransform: "capitalize",
            backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
            cursor: this.state.isNextLessonDisabled ? 'not-allowed' : "pointer"
          }}
          data-testid="handleNextSubLessonPage"
          disabled={this.state.isNextLessonDisabled}
          onClick={this.handleNextSubLessonPage.bind(this,)}
        >
          Next
        </Button>
      </ButtonContainer>
    </>
  }

  handleSubFolderPageBtn = (top?: any) => {
    return <>
      <ButtonContainer sx={{...top}}>
        <Button style={{
          width: "150", height: "52", border: "0.5px solid #3F526D", borderRadius: "8px", color: "#3F526D",
          fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textAlign: "center",
          textTransform: "capitalize"
        }}
          data-testid="handleSubFolderPreviousModal"
          onClick={this.handleSubFolderPreviousModal.bind(this)}
        >Previous</Button>
        <Button
          style={{
            width: "150", height: "52", borderRadius: "8px", 
            textAlign: "center", color: "white",
            fontFamily: "poppins",letterSpacing: "0.11px", 
            lineHeight: "16px", fontSize: "18px", 
             textTransform: "capitalize",
            backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
            cursor: this.state.isNextLessonDisabled ? 'not-allowed' : "pointer"
          }}
          data-testid="handleNextSubFolderLessonPage"
          disabled={this.state.isNextLessonDisabled}
          onClick={this.handleNextSubLessonPage.bind(this,)}
        >
          Next
        </Button>
      </ButtonContainer>
    </>
  }

  handleSelectLesson = (item2: any) => {
    return this.state.teacherLessonId === item2?.id ? webStyle.selectedCard : {}
  }

  handleAttachmentLesson = (item2: any) => {
    return item2?.attributes?.lesson_attachment === null ? groupLessonIcon : item2?.attributes?.lesson_attachment
  }

  handleAttachmentQuiz = (item2: any) => {
    return item2?.attributes?.quiz_attachment === null ? groupQuizIcon : item2?.attributes?.quiz_attachment
  }

  handleSelectLessonFolder = (item2: any) => {
    return this.state.teacherFolderId === item2?.id ? webStyle.selectedCard : {}
  }

  handleSelectQuizLessonFolder = (item2: any) => {
    return this.state.teacherQuizFolderId === item2?.id ? webStyle.selectedCard : {}
  }

  handleSelectQuiz = (item2: any) => {
    return this.state.teacherQuizId === item2?.id ? webStyle.selectedCard : {}
  }

  handleFolderUi = () => {
    if (this.state.selectedTeacherClass === "FOLDER" && this.state.selectedEventType === 'lesson') {
      if (this.state.foldersList?.lesson_folders?.data?.length > 0 || this.state.foldersList?.lessons?.data?.length > 0) {
        return <Box>
          <FolderList>
            {
              this.state.foldersList?.lesson_folders?.data?.map((item2: any) =>
                <Card
                  data-testid="lessonsFolderID"
                  className="lessons-card"
                  onClick={this.handleFolders.bind(this, item2)}
                  style={this.handleSelectLessonFolder(item2)}
                >
                  <img src={folderDefaultIcon} style={{ height: "99px", width: "110px" }} />
                  <Box>
                    <Typography style={webStyle.titleTypography}>{item2.attributes.folder_name}</Typography>
                    <Box style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                      <img src={StudentIcon} style={webStyle.heightWidth14px} />
                      <Typography style={webStyle.countTypo}>{`Total Lessons - ${item2?.attributes?.lessons?.data?.length}`}</Typography>
                    </Box>
                  </Box>
                </Card>
              )
            }

            {
              this.state.foldersList?.lessons?.data?.map((item2: any) =>
                <Card
                  data-testid="lessonsID"
                  className="lessons-card"
                  onClick={this.handleLessonFolders.bind(this, item2)}
                  style={this.handleSelectLesson(item2)}
                >
                  <img src={this.handleAttachmentLesson(item2)} style={{ height: "99px", width: "110px" }} />
                  <Box>
                    <Typography style={webStyle.titleTypography}>{item2.attributes.lesson_name}</Typography>
                  </Box>
                </Card>
              )
            }
          </FolderList>
          {this.handleFolderPageBtn()}
        </Box>
      } else {
        return <Box>
          <Box>
            <Typography style={{ ...webStyle.noRecords }}>No records found</Typography>
          </Box>
          {this.handleFolderPageBtn({ top: '28rem' })}
        </Box>
      }
    }
  }

  handleQuizFolderUi = () => {
    if (this.state.selectedTeacherClass === "FOLDER" && this.state.selectedEventType === 'quiz') {
      if (this.state.quizFoldersList?.quiz_folders?.data?.length > 0 || this.state.quizFoldersList?.quizzes?.data?.length > 0) {
        return <Box>
          <QuizFolderUi>
            {
              this.state.quizFoldersList?.quiz_folders?.data?.map((item2: any) =>
                <Card
                  data-testid="quizFolderID"
                  className="lessons-card"
                  onClick={this.handleQuizFolders.bind(this, item2)}
                  style={this.handleSelectQuizLessonFolder(item2)}
                >
                  <img src={folderDefaultIcon} style={{ height: "99px", width: "110px" }} />
                  <Box>
                    <Typography style={webStyle.titleTypography}>{item2.attributes.folder_name}</Typography>
                    <Box style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                      <img src={StudentIcon} style={webStyle.heightWidth14px} />
                      <Typography style={webStyle.countTypo}>{`Total Quiz - ${item2?.attributes?.quizzes?.data?.length}`}</Typography>
                    </Box>
                  </Box>
                </Card>
              )
            }

            {
              this.state.quizFoldersList?.quizzes?.data?.map((item2: any) =>
                <Card
                  data-testid="lessonsID"
                  className="lessons-card"
                  onClick={this.handleQuizLessonFolders.bind(this, item2)}
                  style={this.handleSelectQuiz(item2)}
                >
                  <img src={this.handleAttachmentQuiz(item2)} style={{ height: "99px", width: "110px" }} />
                  <Box>
                    <Typography style={webStyle.titleTypography}>{item2.attributes.quiz_name}</Typography>
                  </Box>
                </Card>
              )
            }
          </QuizFolderUi>
          {this.handleFolderPageBtn()}
        </Box>
      } else {
        return <Box>
          <Box>
            <Typography style={{ ...webStyle.noRecords }}>No records found</Typography>
          </Box>
          {this.handleFolderPageBtn({ top: '28rem' })}
        </Box>
      }
    }
  }

  handleAssignmentPageBtn = (top?: any) => {
    return <Box style={{  ...webStyle.buttonContainer, ...top }}>
      <Button style={{
        width: "150", height: "52", border: "0.5px solid #3F526D", borderRadius: "8px", color: "#3F526D",
        fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textAlign: "center",
        textTransform: "capitalize"
      }}
        data-testid="handleFolderPreviousModal"
        onClick={this.handleFolderPreviousModal.bind(this)}
      >Previous</Button>
      <Button
        style={{
          width: "150", height: "52", borderRadius: "8px", textAlign: "center", color: "white",
          fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textTransform: "capitalize",
          backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
          cursor: this.state.isNextBtnAssigmentDisabled ? 'not-allowed' : "pointer"
        }}
        data-testid="handleNextSaveLesson"
        disabled={this.state.isNextBtnAssigmentDisabled}
        onClick={this.handleNextSaveLesson.bind(this,)}
      >
        Next
      </Button>
    </Box>
  }
  handleAssignmentUi = () => {
    if (this.state.selectedTeacherClass === "ASSIGNMENTS" && this.state.selectedEventType === 'lesson') {
      if (this.state.showLessonFolder.folders.length > 0 || this.state.showLessonFolder?.lessons?.length > 0) {
        return <Box>
          <Box style={webStyle.listingClass}>
            {
              this.state.showLessonFolder?.folders?.map((item2: any) =>
                <Card
                  data-testid="quizFolderID"
                  className="lessons-card"
                  onClick={this.handleSubFolders.bind(this, item2.data)}
                  style={this.handleSelectLessonFolder(item2.data)}
                >
                  <img src={folderDefaultIcon} style={{ height: "99px", width: "110px" }} />
                  <Box>
                    <Typography style={webStyle.titleTypography}>{item2.data.attributes.folder_name}</Typography>
                    <Box style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                      <img src={StudentIcon} style={webStyle.heightWidth14px} />
                      <Typography style={webStyle.countTypo}>{`Total Lessons - ${item2?.data.attributes?.lessons?.data?.length}`}</Typography>
                    </Box>
                  </Box>
                </Card>
              )
            }
            {
              this.state.showLessonFolder?.lessons.map((item2: any, index: number) =>
                <Card
                  data-testid="folderID"
                  className="assignment-card"
                  onClick={this.handleAssignment.bind(this, item2.data)}
                  style={this.state.teacherLessonFolderId === item2?.data.id ? webStyle.selectedCard : {}}
                >
                  <img src={groupAssignmentIcon} style={{ height: "99px", width: "110px" }} />
                  <Box>
                    <Box display={"flex"} alignItems="center" style={{ gap: "10px", }}>
                      <img style={{
                        width: "14px",
                        height: "14px",
                      }} src={StudentIcon} />
                      <Typography style={webStyle.countTypo}>{`Lesson ${index + 1}`}</Typography>
                    </Box>
                    <Box>
                      <Typography style={{ ...webStyle.titleTypography, width: '200px' }}>{item2.data.attributes.lesson_name}</Typography>
                    </Box>
                  </Box>
                </Card>
              )
            }
          </Box>
          {this.handleSubFolderPageBtn()}
        </Box>
      } else {
        return <Box >
          <Box>
            <Typography style={{ ...webStyle.noRecords }}>No records found</Typography>
          </Box>
          {this.handleSubFolderPageBtn({ top: "28rem" })}
        </Box>
      }
    }
  }
  handleSubFolderAssignmentUi = () => {
    if (this.state.selectedTeacherClass === "SUBFOLDER" && this.state.selectedEventType === 'lesson') {
      if (this.state.selectedTeacherAssignmentList?.length > 0) {
        return <Box>
          <Box style={webStyle.listingClass}>
            {
              this.state.selectedTeacherAssignmentList.map((item2: any, index: number) =>
                <Card
                  data-testid="folderID"
                  className="assignment-card"
                  onClick={this.handleAssignment.bind(this, item2)}
                  style={this.state.teacherLessonFolderId === item2?.id ? webStyle.selectedCard : {}}
                >
                  <img src={groupAssignmentIcon} style={{ height: "99px", width: "110px" }} />
                  <Box>
                    <Box display={"flex"} alignItems="center" style={{ gap: "10px", }}>
                      <img style={{
                        width: "14px",
                        height: "14px",
                      }} src={StudentIcon} />
                      <Typography style={webStyle.countTypo}>{`Lesson ${index + 1}`}</Typography>
                    </Box>
                    <Box>
                      <Typography style={{ ...webStyle.titleTypography, width: '200px' }}>{item2.attributes.lesson_name}</Typography>
                    </Box>
                  </Box>
                </Card>
              )
            }
          </Box>
          {this.handleAssignmentPageBtn()}
        </Box>
      } else {
        return <Box >
          <Box>
            <Typography style={{ ...webStyle.noRecords }}>No records found</Typography>
          </Box>
          {this.handleAssignmentPageBtn({ top: "28rem" })}
        </Box>
      }
    }
  }
  handleSubFolderQuizUi = () => {
    if (this.state.selectedTeacherClass === "SUBFOLDER" && this.state.selectedEventType === 'quiz') {
     if (this.state.selectedQuizAssignmentList?.length > 0) {
        return <Box>
          <Box style={webStyle.listingClass}>
            {
              this.state.selectedQuizAssignmentList.map((item2: any, index: number) =>
                <Card
                  data-testid="quizFolderID"
                  className="assignment-card"
                  onClick={this.handleQuizAssignment.bind(this, item2)}
                  style={this.state.quizFolderDirectId === item2?.id ? webStyle.selectedCard : {}}
                >
                  <img src={groupAssignmentIcon} style={{ height: "99px", width: "110px" }} />
                  <Box>
                    <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <img src={StudentIcon} style={webStyle.heightWidth14px} />
                      <Typography style={webStyle.countTypo}>{`Quiz ${index + 1}`}</Typography>
                    </Box>
                    <Box>
                      <Typography style={webStyle.titleTypography}>{item2.attributes.quiz_name}</Typography>
                    </Box>
                  </Box>
                </Card>
              )
            }
          </Box>
          {this.handleAssignmentPageBtn()}
        </Box>
      } else {
        return <Box >
          <Box>
            <Typography style={{ ...webStyle.noRecords }}>No records found</Typography>
          </Box>
          {this.handleAssignmentPageBtn({ top: "28rem" })}
        </Box>
      }
    }
  }
  handleQuizAssignmentUi = () => {
    if (this.state.selectedTeacherClass === "ASSIGNMENTS" && this.state.selectedEventType === 'quiz') {
      if (this.state.showQuizFolder.folders.length > 0 || this.state.showQuizFolder.quizzes.length > 0) {
        return <Box>
          <Box style={webStyle.listingClass}>
            {
              this.state.showQuizFolder?.folders?.map((item2: any) =>
                <Card
                  data-testid="quizesFolderID"
                  className="lessons-card"
                  onClick={this.handleQuizSubFolders.bind(this, item2.data)}
                  style={this.handleSelectQuizLessonFolder(item2.data)}
                >
                  <img src={folderDefaultIcon} style={{ height: "99px", width: "110px" }} />
                  <Box>
                    <Typography style={webStyle.titleTypography}>{item2.data.attributes.folder_name}</Typography>
                    <Box style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                      <img src={StudentIcon} style={webStyle.heightWidth14px} />
                      <Typography style={webStyle.countTypo}>{`Total Quiz - ${item2?.data.attributes?.quizzes?.data?.length}`}</Typography>
                    </Box>
                  </Box>
                </Card>
              )
            }
            {
              this.state.showQuizFolder?.quizzes.map((item2: any, index: number) =>
                <Card
                  data-testid="folderID"
                  className="assignment-card"
                  onClick={this.handleQuizAssignment.bind(this, item2.data)}
                  style={this.state.quizFolderDirectId === item2?.data.id ? webStyle.selectedCard : {}}
                >
                  <img src={groupAssignmentIcon} style={{ height: "99px", width: "110px" }} />
                  <Box>
                    <Box display={"flex"} alignItems="center" style={{ gap: "10px", }}>
                      <img style={{
                        width: "14px",
                        height: "14px",
                      }} src={StudentIcon} />
                      <Typography style={webStyle.countTypo}>{`Quiz ${index + 1}`}</Typography>
                    </Box>
                    <Box>
                      <Typography style={{ ...webStyle.titleTypography, width: '200px' }}>{item2.data.attributes.quiz_name}</Typography>
                    </Box>
                  </Box>
                </Card>
              )
            }
          </Box>
          {this.handleSubFolderPageBtn()}
        </Box>
      } 
       else {
        return <Box >
          <Box>
            <Typography style={{ ...webStyle.noRecords }}>No records found</Typography>
          </Box>
          {this.handleSubFolderPageBtn({ top: "28rem" })}
        </Box>
      }
    }
  }

  handleBreadCrumbUi = () => {
    return <>
      <Breadcrumbs style={{ marginBottom: ".8rem", fontWeight: "bold", fontFamily: "poppins" }} separator={<NavigateBeforeIcon fontSize="small" />} aria-label="breadcrumb">
        <></>
        <div style={webStyle.btnLinkBreadCrumb} data-testid="teacherLocation" onClick={this.handleLinkTeacherClick.bind(this, "Select Class")}>
          {this.state.currentTeacherLocation}
        </div>
        { this.state.isOpenFolder === true  &&
          <div style={webStyle.btnLinkBreadCrumb} data-testid="lessonFolderName" onClick={this.handleLinkTeacherClick.bind(this, "My Folder")}>{this.state.lessonFolderName}</div>
        }
        {this.state.isSubFolder === true  &&
          <div style={webStyle.btnLinkBreadCrumb} data-testid="lessonSubFolderName" onClick={this.handleLinkTeacherClick.bind(this, "My SubFolder")}>{this.state.lessonSubFolderName}</div>
        }
      </Breadcrumbs>
    </>
  }

  handleValidationMessage = (val: string, isValidCheck: boolean, message: string) => {
    if (val === '' && isValidCheck) {
      return message;
    } else {
      return ""
    }
  }

  handleStartDueDateValidationMessage = (val: boolean, isValidCheck: boolean, message: string) => {
    if (val && isValidCheck) {
      return message;
    } else {
      return ""
    }
  }

  handleStartDueTimeValidationMessage = (val: boolean, isValidCheck: boolean, message: string) => {
    if (val && isValidCheck) {
      return message;
    } else {
      return ""
    }
  }

  handleEditStartDueDateValidationMessage = (val: boolean, isValidCheck: boolean, message: string) => {
    if (val && isValidCheck) {
      return message;
    } else {
      return ""
    }
  }

  handleStartDueTimeSameValidationMessage = (isValidCheck: boolean, message: string) => {
    if (isValidCheck) {
      return message;
    } else {
      return ""
    }
  }

  renderTeacherLessonModal() {
    return (
      <Modal style={{ display: "flex", justifyContent: "center", alignItems: "center" }} data-testid='handleTeacherLessonModal' open={this.state.openTeacherLessonModal} onClose={this.handleTeacherLessonModal}>
              <CreateEventModal>
                <InnerEventModal>
                  <ClearIcon
                    onClick={this.handleTeacherLessonModal}
                    style={webStyle.crossIconOption}
                  />
                  <CreateEventText>Create Event</CreateEventText>
                  <TabContainer>
                    {
                      !this.state.teacherClassActiveTab ?
                        <>
                          <TabAfter label="My Library" />
                          <TabBefore label="Shared Library" data-testid="quizId2" onClick={() => this.handleTeacherClassTabs(true)} /> </> :
                        <>
                          <TabBefore label="My Library" data-testid="LibraryId" onClick={() => this.handleTeacherClassTabs(false)} />
                          <TabAfter label="Shared Library" />
                        </>
                    }
                  </TabContainer>
                  <Box style={webStyle.addButtonLayout} sx={{ px: this.state.isSmallScreen ? "" : "35px" }}>
                    <CreateEventSearch>
                      {this.state.openTeacherBreadCrumb === 'Select Class' ? <Typography style={{ fontFamily: "poppins", fontSize: 18, marginBottom: ".8rem", fontWeight: "bold" }}>Select Class</Typography> :
                        this.handleBreadCrumbUi()
                      }
                      <SearchBox>
                        <SearchInput
                          placeholder={"Search by name"}
                          data-testid="setTeacherQuizeName"
                          handleSearchInput={this.setTeacherQuizeName}
                          searchInput={this.renderSearchInputType()}
                        />
                      </SearchBox>
                    </CreateEventSearch>
                  </Box>
                  {this.handleClassUi()}

                  {this.handleFolderUi()}

                  {this.handleQuizFolderUi()}

                  {this.handleAssignmentUi()}

                  {this.handleQuizAssignmentUi()}

                  {this.handleSubFolderAssignmentUi()}

                  {this.handleSubFolderQuizUi()}
                </InnerEventModal>
              </CreateEventModal>
            </Modal>
    );
  }

  renderGridItem() {
    if (this.state.isSmallScreen) {
      return null;
    }
  
    return (
      <Grid xs={2} item>
        <Sidebar />
      </Grid>
    );
  }

  render() {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    };

    const timeOptions = [];
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0); // Set to midnight
    for (let i = 0; i < 24 * 4; i++) { // 24 hours * 4 intervals/hour
      const time = new Date(startTime.getTime() + i * 15 * 60 * 1000); // Add 15 minutes
      timeOptions.push(time);
    }

    return (
      <>
        <Box>
          <Grid style={{ height: "100%", overflowX: "hidden", }} container >
            {this.renderGridItem()}
            <Grid {...(!this.state.isSmallScreen && { xs: 10 })} item style={{ overflowY: "scroll", height: "100%", }}>
              <Grid md={12} item>
                <AppHeaderWeb title={"My Calendar"} />
              </Grid>
              {this.state.loading ? (<Loader loading={this.state.loading} />) : (
                <Grid md={12} item >
                  <CalendarBtnLayout>
                    <CurrentDateBox>
                      <CurrentDate>
                        {new Date(this.state.selectedDate).toLocaleDateString("en-US", options)}
                      </CurrentDate>
                      <Button data-testid="handleOpen" onClick={this.handleOpen}><img src={calendarPickerIcon} /></Button>
                    </CurrentDateBox>
                    <CreateEventBtnLayout>
                      <CreateEventButton data-testid="handleOpenCreateEventModal" onClick={this.handleOpenCreateEventModal}>Create Event</CreateEventButton>
                    </CreateEventBtnLayout>
                  </CalendarBtnLayout>
                  <CustomDatePicker
                    open={this.state.setOpen}
                    selectedDate={this.state.selectedDate}
                    data-testid="custom-date-picker"
                    handleClose={this.handleClose}
                    handleDateChange={this.handleDateChange}
                  />
                  <CalendarBox>
                    <Calendar
                      defaultView="week"
                      date={this.state.selectedDate}
                      events={this.state.calendarData.events}
                      localizer={this.localizer}
                      data-testid="calendar"
                      components={this.handleRenderCalendar.call(this)}
                      toolbar={false}
                      views={["week"]}
                      step={30}
                    />
                  </CalendarBox>

                </Grid>
              )}
            </Grid>

            <Modal open={this.state.openCreateEventModal} data-testid="handleCloseEventModal" onClose={this.handleCloseEventModal} style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
              <MainModalContainer>
                <Box style={webStyle.container}>
                  <ClearIcon
                    onClick={this.handleCloseEventModal}
                    style={webStyle.crossIconOption}
                  />
                  <Box style={webStyle.popupContentOption}>
                    <OptionTitle>
                      Select an option to Create Event
                    </OptionTitle>
                    <OptionsLayout>
                      {this.state.eventOptions.map((option: any) => (
                        <Box key={option.name}>
                          <ItemBox
                            data-testid="event-option"
                            onClick={this.handleSelectEventOptions.bind(this, option)}
                          >
                            <img src={option.image} style={webStyle.imageOption} />
                            <Typography style={webStyle.optionTitle}>
                              {option.name}
                            </Typography>
                          </ItemBox>
                        </Box>
                      ))}
                    </OptionsLayout>
                  </Box>
                </Box>
              </MainModalContainer>
            </Modal>


            {this.renderTeacherLessonModal()}

            <Modal style={{ display: "flex", justifyContent: "center", alignItems: "center" }} data-testid="handleSaveCloseEventModal" open={this.state.openSaveEventModal} onClose={this.handleSaveCloseEventModal}>
              <CreateEventTime>
                <InnerBoxTime>
                  <ClearIcon
                    onClick={this.handleSaveCloseEventModal}
                    style={webStyle.crossIconOption}
                  />
                  <CreateSetTime>Create Event</CreateSetTime>
                  <StartEndTime>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Box mb={this.state.isSmallScreen ? "":"1rem"}>
                        <Typography style={webStyle.saveTimeTypo}>Start Date & Time</Typography>
                        <StyledKeyboardDatePicker
                          id="startDate"
                          name="startDate"
                          variant="dialog"
                          data-testid={"startDate"}
                          minDate={(new Date())}
                          format="dd/MM/yyyy"
                          inputVariant="outlined"
                          onChange={this.handleSaveStartDateChange}
                          style={{ ...webStyle.picker, ...webStyle.borderStartTimeRadius }}
                          value={this.initStartDateVal()}
                          labelFunc={() =>
                            this.state.selectedStartDateEvent || "Select Date"
                          }
                          className="start-time"
                          InputProps={{
                            style: webStyle.inputDateStyle,
                          }}
                        />
                        <Typography style={webStyle.errorTypo}>{this.handleValidationMessage(this.state.selectedStartDateEvent, this.state.isValidateCreateEvent, "Select Start Date")}</Typography>
                        <Typography style={{ ...webStyle.errorTypo, width: '18rem' }}>{this.handleStartDueDateValidationMessage(this.state.isStartDueDateEvent, this.state.isValidateCreateEvent, "Start date should not be greater than due date")}</Typography>
                        <Typography style={{ ...webStyle.errorTypo, width: '18rem' }}>{this.handleStartDueDateValidationMessage(this.state.isStartDateClass, this.state.isValidateCreateEvent, "Class is not started")}</Typography>
                      </Box>
                      <Box>
                        <KeyboardTimePicker
                          margin="normal"
                          InputProps={{
                            style:this.state.isSmallScreen ? webStyle.inputDateMobileStyle : webStyle.inputDateStyle,
                          }}
                          data-testid="startTime"
                          id="startTime"
                          inputVariant="outlined"
                          value={this.initStartTimeVal()}
                          onChange={this.handleStartTimeChange}
                          labelFunc={() =>
                            this.state.selectedStartTimeEvent || 'Select Time'
                          }
                          minutesStep={5}
                          mask="__:__ _M"
                          ampm
                          views={['hours', 'minutes']}
                          inputProps={{ step: 900 }}
                          className="due-time"
                          keyboardIcon={<AccessTimeIcon />}
                          style={{ ...webStyle.picker, ...webStyle.borderDueTimeRadius }}

                          {...timeOptions}
                        />
                        <Typography style={webStyle.errorTypo}>{this.handleValidationMessage(this.state.selectedStartTimeEvent, this.state.isValidateCreateEvent, "Select Start Time")}</Typography>
                        <Typography style={webStyle.errorTypo}>{this.handleStartDueTimeSameValidationMessage(this.state.isSameStartDueTime, "Start time and due time should not be the same.")}</Typography>
                      </Box>
                    </MuiPickersUtilsProvider>
                  </StartEndTime>
                  <StartEndTime>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Box>
                        <Typography style={webStyle.saveTimeTypo}>Due Date & Time</Typography>
                        <StyledKeyboardDatePicker
                          id="dueDate"
                          name="startDate"
                          variant="dialog"
                          data-testid={"dueDate"}
                          inputVariant="outlined"
                          onChange={this.handleSaveDueDateChange}
                          inputProps={{ style: { fontFamily: 'Poppins', } }}
                          value={this.initDueDateVal()}
                          labelFunc={() =>
                            this.state.selectedDueDateEvent || "Select Date"
                          }
                          minDate={(new Date())}
                          format="dd/MM/yyyy"
                          className="start-time"
                          style={{ ...webStyle.picker, ...webStyle.borderStartTimeRadius }}
                          InputProps={{
                            style: webStyle.inputDateStyle,
                          }}
                        />
                        <Typography style={webStyle.errorTypo}>{this.handleValidationMessage(this.state.selectedDueDateEvent, this.state.isValidateCreateEvent, "Select Due Date")}</Typography>
                        <Typography style={{ ...webStyle.errorTypo, width: '18rem' }}>{this.handleStartDueDateValidationMessage(this.state.isDueDateClass, this.state.isValidateCreateEvent, "Class is ended")}</Typography>
                      </Box>
                      <Box>
                        <KeyboardTimePicker
                          margin="normal"
                          InputProps={{
                            style:this.state.isSmallScreen ? webStyle.inputDateMobileStyle : webStyle.inputDateStyle }}
                          id="dueTime"
                          data-testid="dueTime"
                          inputVariant="outlined"
                          value={this.initDueTimeVal()}
                          onChange={this.handleDueTimeChange}
                          labelFunc={() =>
                            this.state.selectedDueEventTime || 'Select Time'
                          }
                          minutesStep={5}
                          ampm
                          views={['hours', 'minutes']}
                          inputProps={{ step: 900 }}
                          keyboardIcon={<AccessTimeIcon />}
                          className="due-time"
                          style={{ ...webStyle.picker, ...webStyle.borderDueTimeRadius }}
                          mask="__:__ _M"
                          {...timeOptions}
                        />
                        
                        <Typography style={webStyle.errorTypo}>{this.handleValidationMessage(this.state.selectedDueEventTime, this.state.isValidateCreateEvent, "Select Due Time")}</Typography>
                        <Typography style={webStyle.errorTypo}>{this.handleStartDueTimeValidationMessage(this.state.isStartDueTimeEvent, this.state.isValidateCreateEvent, "Due time should greater than start time")}</Typography>
                        <Typography style={webStyle.errorTypo}>{this.handleStartDueTimeSameValidationMessage(this.state.isSameStartDueTime, "Start time and due time should not be the same.")}</Typography>
                      </Box>
                    </MuiPickersUtilsProvider>
                  </StartEndTime>
                  <Box>
                          
                  </Box>

                  <CreateEventTimeBtn>
                    <Button style={{
                      width: "150", height: "52", border: "0.5px solid #3F526D", borderRadius: "8px", color: "#3F526D",
                      fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textAlign: "center",
                      textTransform: "capitalize"
                    }}
                      data-testid="handleSaveCancelEventModal"
                      onClick={this.handleSaveCloseEventModal.bind(this)}
                    >Cancel</Button>
                    <Button
                      style={{
                        width: "150", height: "52", borderRadius: "8px", textAlign: "center", color: "white",
                        fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textTransform: "capitalize",
                        backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
                      }}
                      data-testid="createTeacherCalendarEvents"
                      onClick={this.createTeacherCalendarEvents.bind(this,)}
                    >
                      Create Events
                    </Button>
                  </CreateEventTimeBtn>
                </InnerBoxTime>
              </CreateEventTime>
            </Modal>

            <Modal style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              open={this.state.successEventModal} data-testid="handleCloseSuccessModal" onClose={this.handleCloseSuccessModal}>
              <SuccessBox >
                <Box style={webStyle.container}>
                  <ClearIcon
                    onClick={this.handleCloseSuccessModal}
                    style={webStyle.crossIconOption}
                  />
                  <Box style={webStyle.popupContentOption}>
                    <Box style={webStyle.optionsLayout}>
                      <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <StyledBox
                          data-testId="openModalId234"
                        >
                          <img src={SuccessIcon} style={webStyle.imageOption} />
                        </StyledBox>
                        <Typography style={{ ...webStyle.titleOption, marginTop: "20px", marginBottom: "0px", fontSize: "25px" }}>
                          {this.state.successMessageEvent}
                        </Typography>
                        <Typography style={{ fontFamily: "poppins", lineHeight: "28px", fontSize: "18" }}>Event has been created successfully!</Typography>
                      </Box>

                    </Box>
                  </Box>
                </Box>
              </SuccessBox>
            </Modal>

            <Modal style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              data-testid="handleSaveCloseEditEventModal" open={this.state.isOpenEditMenu} onClose={this.handleSaveCloseEditEventModal}>
              <EditPopup>
                <InnerEditAssign>
                  <ClearIcon
                    onClick={this.handleSaveCloseEditEventModal}
                    style={webStyle.crossIconOption}
                  />
                  <EditText>Edit {this.handleEditEventTypeCheck()}</EditText>
                  <AssignmentInput>
                    <TextFieldContainer>
                      <Typography style={webStyle.saveTimeTypo}>{this.handleEditEventTypeCheck()} Name</Typography>
                      <TextFields
                        data-testid="handleEditTitleChange"
                        onChange={this.handleEditTitleChange}
                        style={{ ...webStyle.picker, ...webStyle.borderStartTimeRadius, }}
                        variant="outlined"
                        value={this.state.editEventTitle}
                      />
                      <Typography style={webStyle.errorTypo}>{this.handleValidationMessage(this.state.editEventTitle, this.state.isValidateEditEvent,`Select ${this.handleEditEventTypeCheck()} Name`)}</Typography>
                    </TextFieldContainer>
                    <InputBoxs>
                      <Typography style={webStyle.saveTimeTypo}>Class</Typography>
                      <SelectField
                        data-testid="classSelect"
                        id="class"
                        variant="outlined"
                        label="Select Class"
                        value={this.state.editEventClass}
                        onChange={this.handleEditClassChange}
                        inputProps={{ style: webStyle.inputDateStyle, }}
                        MenuProps={{ PaperProps: { style: {} }, PopoverClasses:{paper:'selectPopOver'}, style: { height: '280px',top:'180px' },  }}
                      >
                        <MenuItem value="Select Class" disabled>Select Class</MenuItem>
                        {this.state.teacherClassesData?.map((classes: any, index: any) => {
                          return <MenuItem key={classes?.id} value={classes?.id}>{classes?.attributes?.class_name}</MenuItem>
                        })}
                      </SelectField>
                      <Typography style={webStyle.errorTypo}>{this.handleValidationMessage(this.state.editEventClass, this.state.isValidateEditEvent, "Select Class")}</Typography>
                    </InputBoxs>
                  </AssignmentInput>
                  <ObjectiveContainer>
                    <Typography style={webStyle.saveTimeTypo}>Objective</Typography>
                    <ObjTextField
                      style={{ ...webStyle.picker, ...webStyle.borderStartTimeRadius }}
                      variant="outlined"
                      data-testid="handleEditEventObjective"
                      value={this.state.editEventObjective}
                      onChange={this.handleEditEventObjective}
                      placeholder="Enter the Objective"
                    
                    />
                  </ObjectiveContainer>
                  <DateMainContainer>
                    <StartDateContainer>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Box>
                          <Typography style={webStyle.saveTimeTypo}>Start Date & Time</Typography>
                          <StyledKeyboardDatePicker
                            name="startDate"
                            variant="dialog"
                            data-testid={"editStartDate"}
                            inputVariant="outlined"
                            onChange={this.handleEditStartDateChange}
                            inputProps={{ style: { fontFamily: 'Poppins', } }}
                            value={this.editStartDateVal()}
                            labelFunc={() =>
                              this.state.editStartDateEvent || "Select Date"
                            }
                            format="dd/MM/yyyy"
                            className="edit-date-time"
                            style={{ ...webStyle.picker, ...webStyle.borderStartTimeRadius }}
                            InputProps={{
                              style: { ...webStyle.inputDateStyle, width: '200px', },
                            }}
                            /> 
                          <Typography style={webStyle.errorTypo}>{this.handleValidationMessage(this.state.editStartDateEvent, this.state.isValidateEditEvent, "Select Start Date")}</Typography>
                        <Typography style={{ ...webStyle.errorTypo, width:'12rem' }}>{this.handleEditStartDueDateValidationMessage(this.state.isEditStartDueDateEvent, this.state.isValidateEditEvent, "Start date should not be greater than due date")}</Typography>

                        </Box>
                        <Box>
                          <StyledKeyboardTimePicker
                            margin="normal"
                            InputProps={{
                              style: { ...webStyle.inputDateStyle, width: '200px' },
                            }}
                            id="dueDateTime"
                            inputVariant="outlined"
                            data-testid="editStartTime"
                            value={this.editStartTimeVal()}
                            onChange={this.handleEditStartTimeChange}
                            labelFunc={() =>
                              this.state.editStartTimeEvent || 'Select Time'
                            }
                            minutesStep={5}
                            ampm
                            views={['hours', 'minutes']}
                            inputProps={{ step: 900 }}
                            keyboardIcon={<AccessTimeIcon />}
                            className="edit-date-time"
                            style={{ ...webStyle.picker, ...webStyle.borderDueTimeRadius }}
                            mask="__:__ _M"
                            {...timeOptions}
                          />
                          <Typography style={webStyle.errorTypo}>{this.handleValidationMessage(this.state.editStartTimeEvent, this.state.isValidateEditEvent, "Select Start Time")}</Typography>
                          <Typography style={webStyle.errorTypo}>{this.handleStartDueTimeSameValidationMessage(this.state.isEditSameStartDueTime, "Start time and due time should not be the same.")}</Typography>
                        </Box>
                      </MuiPickersUtilsProvider>
                    </StartDateContainer>
                    <DueDateContainer>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Box>
                          <Typography style={webStyle.saveTimeTypo}>Due Date & Time</Typography>
                          <StyledKeyboardDatePicker
                            name="startDate"
                            variant="dialog"
                            data-testid={"editDueDate"}
                            inputVariant="outlined"
                            onChange={this.handleEditDueDateChange}
                            inputProps={{ style: { fontFamily: 'Poppins', } }}
                            value={this.editDueDateVal()}
                            labelFunc={() =>
                              this.state.editDueDateEvent || "Select Date"
                            }
                            format="dd/MM/yyyy"
                            className="edit-date-time"
                            style={{ ...webStyle.picker, ...webStyle.borderStartTimeRadius }}
                            InputProps={{
                              style: { ...webStyle.inputDateStyle, width: '200px' },
                            }}
                          />
                          <Typography style={webStyle.errorTypo}>{this.handleValidationMessage(this.state.editDueDateEvent, this.state.isValidateEditEvent, "Select Due Date")}</Typography>
                        </Box>
                        <Box>
                          <StyledKeyboardTimePicker
                            {...timeOptions}
                            margin="normal"
                            InputProps={{
                              style: { ...webStyle.inputDateStyle, width: '200px' },
                            }}
                            id="editDueTime"
                            data-testid="editDueTime"
                            inputVariant="outlined"
                            value={this.editDueTimeVal()}
                            onChange={this.handleEditDueTimeChange}
                            labelFunc={() =>
                              this.state.editDueEventTime || 'Select Time'
                            }
                            ampm
                            mask="__:__ _M"
                            inputProps={{ step: 900 }}
                            className="edit-date-time"
                            keyboardIcon={<AccessTimeIcon />}
                            style={{ ...webStyle.borderDueTimeRadius, ...webStyle.picker, }}
                            minutesStep={5}
                            views={['hours', 'minutes']}
                          />
                          <Typography style={webStyle.errorTypo}>{this.handleValidationMessage(this.state.editDueEventTime, this.state.isValidateEditEvent, "Select Due Time")}</Typography>
                          <Typography style={webStyle.errorTypo}>{this.handleStartDueTimeValidationMessage(this.state.isEditStartDueTimeEvent, this.state.isValidateEditEvent, "Due time should greater than start time")}</Typography>
                          <Typography style={webStyle.errorTypo}>{this.handleStartDueTimeSameValidationMessage(this.state.isEditSameStartDueTime, "Start time and due time should not be the same.")}</Typography>
                        </Box>
                      </MuiPickersUtilsProvider>
                    </DueDateContainer>
                  </DateMainContainer>
                  <SaveAssignBtn style={{ ...webStyle.buttonContainer, paddingRight:'35px', top:'0rem'}}>
                    <Button style={{
                      width: "150", height: "52", border: "0.5px solid #3F526D", borderRadius: "8px", color: "#3F526D",
                      fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textAlign: "center",
                      textTransform: "capitalize"
                    }}
                      data-testid="handleSaveEditCancelEventModal"
                      onClick={this.handleSaveCloseEditEventModal.bind(this)}
                    >Cancel</Button>
                    <Button
                      style={{
                        width: "150", height: "52", borderRadius: "8px", textAlign: "center", color: "white",
                        fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textTransform: "capitalize",
                        backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
                      }}
                      data-testid="editTeacherCalendarEvents"
                      onClick={this.editTeacherCalendarEvents.bind(this,)}
                    >
                      {this.state.editEventLoader?<Loader loading={this.state.editEventLoader} color="#2327a6" top="15%" left="40%"/>:`Save ${this.handleEditEventTypeCheck()}`}
                    </Button>
                  </SaveAssignBtn>
                </InnerEditAssign>
              </EditPopup>
            </Modal>

            <Modal style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              open={this.state.successEditEventModal} data-testid="handleEditCloseSuccessModal" onClose={this.handleEditCloseSuccessModal}>
              <Box style={{ ...webStyle.paperOption, width: "460px", height: "343px" }}>
                <Box style={webStyle.container}>
                  <ClearIcon
                    onClick={this.handleEditCloseSuccessModal}
                    style={webStyle.crossIconOption}
                  />
                  <Box style={webStyle.popupContentOption}>
                    <Box style={{...webStyle.optionsLayout, marginBottom:'35px'}}>
                      <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <StyledBox
                          data-testId="openModalId234"
                        >
                          <img src={SuccessIcon} style={webStyle.imageOption} />
                        </StyledBox>
                        <Typography style={{ fontFamily: "poppins", lineHeight: "28px", fontSize: "25px", width:"300px", color:'#2B3D7D', textAlign:'center', fontWeight:"bold" }}>{this.handleEditEventTypeCheck()} has been saved successfully!</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Modal>
            
          </Grid>
        </Box>
      </>
    );
  }
}

const webStyle = {
  createEventBtn: {
    backgroundImage: 'linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)',
    color: "white",
    border: "1px solid #979797",
    borderRadius: "10px" as "10px",
    height: "60px",
    marginRight: "32.5px",
    width: "10rem",
    fontSize: '15px',
    textTransform: 'capitalize' as 'capitalize'
  },
  errorTypo: {
    color: "red", paddingTop: "4px", fontSize: "14px", fontFamily: 'Poppins'
  },
  foldersStyle: {
    cursor: "pointer", position: "relative" as "relative", right: "-123px", left: "300px", top: "-45px"
  },
  heightWidth14px: {
    height: "14px", width: "14px"
  },
  countTypo: {
    color: "#2B3D7D", fontSize: "14px", fontFamily: "poppins"
  },
  inputDateStyle: {
    border: "none", fontFamily: 'Poppins', width: '300px',
  },
  inputDateMobileStyle: {
    border: "none", fontFamily: 'Poppins', width: '100%',
  },
  titleTypography: {
    fontFamily: "poppins", lineHeight: "32px", fontSize: "20px", letterSpacing: "0.2px", marginBottom: "10px"
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center" as "center",
    paddingTop: "35px",
  },
  listingClass: { height: "465px", overflow: "auto", display: "flex", flexWrap: "wrap" as "wrap", gap: "20px", paddingLeft: "35px", },
  borderDueTimeRadius: {
    borderBottomLeftRadius: "0px !important", borderTopLeftRadius: '0px !important', marginTop: '23px', fontFamily: 'Poppins'
  },
  borderStartTimeRadius: {
    borderBottomRightRadius: "0px !important", borderTopRightRadius: '0px !important', marginTop: '16px', fontFamily: 'Poppins'
  },
  buttonContainer: {
    display: "flex", position: "relative" as "relative", top: '2rem', justifyContent: "flex-end" as "flex-end", gap: "10px", alignItems: "center", padding: "30px 52px 0px 52px",
  },
  btnLinkBreadCrumb: {
    cursor: "pointer", textDecoration: "none",
    color:'rgba(0, 0, 0, 0.87)', fontWeight:"bold" as 'bold',
  },
  saveTimeTypo: {
    color: "#2C2C2E",
    fontFamily: 'Poppins'
  },
  title: {
    color: "#757575",
    fontWeight: 500 as 500,
    fontSize: "24px" as "24px",
    fontFamily: "Poppins",
  },
  teacherName: {
    color: "#000000",
    fontWeight: 500 as 500,
    fontFamily: "Poppins",
    fontSize: "20px",
  },
  navigationIcon: {
    marginTop: "20px",
  },
  breadcrumbBox: {
    marginTop: "20px",
    padding: "0px 35px",
  },
  currentDateBox: {
    padding: "0 35px",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  addButtonLayout: {
    display: "flex",
    // width: "100%",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
  currenDate: {
    color: "rgb(43, 61, 125)",
    paddingRight: "15px",
    fontWeight: 600 as 600,
    fontSize: "22px",
    fontFamily: "Poppins",
  },
  calendarView: {
    rowGap: "10rem",
    display: "grid",
  },
  weekHeader: {
    paddingTop: "20px",
    flexDirection: "column" as "column",
    display: "flex",
    marginBottom: "8px",
    marginTop: "16px",
    width: "93px",
    height: "111px",
  },
  weekHeaderDayLabel: {
    lineHeight: "27px",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 500 as 500,
    color: "#858585",
    marginBottom: "10px",
    textAlign: "center" as "center",
  },
  weekHeaderDateLabel: {
    color: "#000000",
    lineHeight: "36px",
    fontSize: "24px",
    fontWeight: 400 as 400,
    fontFamily: "Poppins",
    textAlign: "center" as "center",
  },
  tabBefore:
  {
    color: "#2B3D7D",
    fontWeight: 500,
    fontSize: "20px",
    width: "218px",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "4px",
    border: "1px solid #2B3D7D",
  },
  tabAfter: {
    fontWeight: 600,
    color: "#FFFFFF",
    textTransform: "capitalize" as "capitalize",
    backgroundColor: "#2B3D7D",
    fontSize: "20px",
    borderRadius: "4px",
    width: "218px",
    opacity: 1
  },
  calendarBox: {
    padding: "50px 35px",
    height: '70%'
  },
  eventBox: {
    whiteSpace: "nowrap" as "nowrap",
    border: "1px solid rgba(57, 198, 181, 0.26)",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    textOverflow: "ellipsis" as "ellipsis",
    overflow: "hidden" as "hidden",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    backgroundColor: "#FFFFFF",
    display: "flex",
  },
  eventTitle: {
    fontWeight: 800 as 800,
    fontFamily: "Poppins",
    fontSize: "14px",
    overflow: "hidden" as "hidden",
    marginBottom: "14px",
    color: "#2B3D7D",
    textOverflow: "ellipsis" as "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
    height: "2em",
    paddingTop: '5px',
  },

  deleteEventTitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#000000",
    fontWeight: 700,
    marginBottom: "25px"
  },
  eventDueDate: {
    width: "150px",
    fontWeight: 500 as 500,
    color: "#757575",
    overflow: "hidden" as "hidden",
    whiteSpace: "normal" as "normal",
    fontSize: "13px",
    fontFamily: "Poppins",
    wordBreak: "break-word" as "break-word",
  },
  contextMenuBox: {
    display: "flex",
  },
  optionIcon: {
    color: "#757575",
  },
  optionIconBtn: {
    minWidth: 0,
    transform: 'rotate(90deg)',
    padding: '4px',
    float:'right' as 'right',
    top:'4px',
    right:'22px',
  },
  highlightSelectedDate: {
    display: "flex" as "flex",
    marginTop: "16px",
    marginBottom: "8px",
    paddingTop: "20px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    flexDirection: "column" as "column",
    width: "93px",
    border: "1px solid #979797",
    borderRadius: "10px" as "10px",
    height: "111px",
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
  },
  highlightedWeekHeaderDayLabel: {
    fontFamily: "Poppins",
    color: "#FFF",
    fontWeight: 500 as 500,
    textAlign: "center" as "center",
    marginBottom: "10px",
    lineHeight: "27px",
    fontSize: "24px",
  },
  highlightedWeekHeaderDateLabel: {
    color: "#FFF",
    lineHeight: "36px",
    fontWeight: 400,
    textAlign: "center" as "center",
    fontSize: "24px",
    fontFamily: "Poppins",
  },
  link: {
    textDecoration: "none",
  },
  eventTitleBox: {
    justifyContent: "space-between",
    display: "flex",
  },
  goBackButton: {
    justifyContent: "end",
    display: "flex",
    marginRight: "35px",
    marginBottom: "50px",
  },


  listItemBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    padding: "15px",
    minHeight: "275px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    maxWidth: "180px",
    marginRight: "24px",
    flexDirection: "column",
  },
  actionName: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginTop: "12px",
  },
  actionDescription: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#2B3D7D",
    fontWeight: 400,
    textAlign: "center",
  },
  listItemImage: {
    width: "120px",
    height: "120px",
  },
  countBox: {
    display: "flex",
    justifyContent: "start",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    minHeight: "121px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    maxWidth: "210px",
    flexDirection: "column",
    position: "absolute",
    bottom: 0,
    width: "100%",
    background: "#FFF3EB",
  },
  displayCount: {
    fontFamily: "Poppins",
    fontSize: "40px",
    color: "#2B3D7D",
    fontWeight: 600,
  },
  countHeading: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600,
  },
  imageOption: {
    marginBottom: "10px",
  },
  popupContentOption: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
  },
  container: {
    width: "100%",
  },
  itemBox: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    padding: "15px 30px",
    minHeight: "204px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    maxWidth: "180px",
    flexDirection: "column" as "column",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212"
    }
  },
  paper: {
    position: "absolute" as 'absolute',
    width: '600px',
    backgroundColor: 'white',
    borderRadius: "10px" as "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: '2px 4px 3px',
  },
  popupContent: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center"
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#000000",
    fontWeight: 700 as 700,
    marginBottom: "15px"
  },
  confirmation: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#000000",
    marginBottom: "5px"
  },
  btnLayout: {
    display: "flex",
    justifyContent: "space-around"
  },
  cancelBtn: {
    border: "1px solid #FE575A",
    color: "#3F526D",
    borderRadius: "10px" as '10px',
    width: "270px",
    padding: "12px",
    textTransform: "none" as 'none',
    fontFamily: "Poppins",
    fontSize: "18px"
  },
  deleteBtn: {
    backgroundColor: "#FE575A",
    color: "#FFF",
    borderRadius: "10px" as '10px',
    width: "270px",
    padding: "12px",
    textTransform: "none" as 'none',
    fontFamily: "Poppins",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#FE575A",
      color: "#FFF"
    }
  },
  titleOption: {
    fontFamily: "Poppins",
    fontSize: "28px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginBottom: "40px",
  },
  optionTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
  },
  optionsLayout: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginBottom: "40px",
  },
  paperOption: {
    position: "absolute" as "absolute",
    width: "740px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "35px 0",
    '@media (min-width: 300px) and (max-height: 550px)': {
      top: "17rem",
    },
  },
  paperOption1: {
    position: "absolute" as "absolute",
    width: "460px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "35px 0",
    '@media (min-width: 300px) and (max-height: 550px)': {
      width:'361px',
      height:'317px'  
    },

  },
  selectedCard: {
    background: "#ebf8fe",
    borderColor: "#3093DDFF",
    color: "#121212",
  },

  crossIconOption: {
    position: "absolute" as "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  picker: {
    fontFamily: 'Poppins',
    position: "relative" as "relative",
    "& .MuiInputAdornment-root": {
      marginRight: 0,
    },
    "& .MuiIconButton-root": {
      position: "absolute",
      left: 0,
      padding: "10px",
    },
    "& .MuiInputBase-input": {
      paddingLeft: "40px", // Adjust padding to make space for the icon
    },
  },
}

export default Teachers