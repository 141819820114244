import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import { runEngine } from "../../../../framework/src/RunEngine";

interface AnswerApiResponse {
    student_name?: string;
    teacher_correct_answer?: string | null;
    question?: string | null;
    student_answer?: string | number | string[];
    options?: string | null;
    student_image?: string | null;
    answers?: {
        student_image: string | null;
        student_name: string;
        student_answer: string | number | string[] | undefined;
        teacher_correct_answer: string | null;
        answer?: string[]
    }[];
}

interface QuizMarkupTool {
    type: string;
    id: string;
    attributes: Attributes;
}

interface MatchingPair {
    created_at: string;
    pair_question: string;
    pair_answer: string;
    pair_correct_answer: string;
    order_no: number;
    updated_at: string;
    lesson_markup_tools_id: number | null;
    quiz_markup_tools_id: number;
    id: number;
    assignment_markup_tools_id: number | null;
}

interface Attributes {
    quiz_stage_id: number;
    quiz_id: number;
    point: boolean;
    canvas_data: "{canvas: string, canvasSize: string}";
    double_point: boolean;
    title: string | null;
    description: string | null;
    questions: QuestionItem[];
    countdown_timer: string;
    stage_number: number;
    quiz_stage_name: string;
    matching_pairs: MatchingPair[];
    background_image: null;
    images: null;
    videos: null;
    music: null;
}

interface QuestionItem {
    question: Question;
    options: Option[];
    question_background_image: string | null;
}

interface Question {
    created_at: string;
    lesson_markup_tools_id: number;
    question: string;
    updated_at: string;
    id: number;
}

interface Option {
    marked_as_right_answer: boolean | null;
    lesson_mcq_questions_id: number;
    answer: string;
    created_at: string;
    order_no: number | null;
    id: number;
    updated_at: string;
}

// Customizable Area End
export const configJSON = require(".././config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentStep: number;
    className: string;
    allData: QuizMarkupTool[];
    totalStep: null | number,
    authToken: string;
    quizName: string;
    dueDate: number;
    quizStatus: string;
    quizId: string | number;
    isQuizLoading: boolean;
    studentId: number | string;
    answerData: any[],
    isModalOpen: boolean;
    current_markup_tool_id: number | string;
    first_markup_tools_id: number | string;
    openModel: boolean;
    currentQuestionId: string | number;
    quiz_markuptool_id: string | number;
    prevPagePath: string;
    feedbackText: string;
    prevPageId: string;
    isAlertModalOpen: boolean;
    feedbackPlaceHolder: string;
    isLimitModalOpen: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AssessmentQuizTeacherController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getQuizDataApiCallId: string = "";
    getQuizAnswersDataApiCallId: string = "";
    getQuestionFeedbackApiCallId: string = "";
    sendQuestionFeedbackApiCallId: string = "";
    sendOverallFeedbackApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            totalStep: 1,
            currentStep: 0,
            allData: [],
            authToken: '',
            dueDate: 0,
            quizName: '',
            className: '',
            quizId: '',
            quizStatus: '',
            isQuizLoading: false,
            studentId: 0,
            answerData: [],
            current_markup_tool_id: 0,
            first_markup_tools_id: 0,
            isModalOpen: false,
            openModel: false,
            feedbackText: '',
            currentQuestionId: 0,
            quiz_markuptool_id: 0,
            prevPagePath: 'AssingnNewTask',
            prevPageId: '',
            isAlertModalOpen: false,
            feedbackPlaceHolder: '',
            isLimitModalOpen: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)

        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)

        );
        if (this.getQuizDataApiCallId === apiRequestCallId) {

            if (responseJson?.data) {
                const sortData = responseJson.data.attributes.quiz_markup_tools.data.sort((toolA: QuizMarkupTool, toolB: QuizMarkupTool) => {
                    return toolA.attributes.stage_number - toolB.attributes.stage_number;
                });

                this.setState({
                    className: responseJson.data.attributes.class_name,
                    allData: sortData,
                    quizName: responseJson.data.attributes.quiz_name,
                    dueDate: responseJson.data.attributes.due_date,
                    quizStatus: this.checkStatus(responseJson.data.attributes.status),
                    isQuizLoading: false,
                    current_markup_tool_id: responseJson.data.attributes.quiz_markup_tools.data[this.state.currentStep].id,
                    first_markup_tools_id: responseJson.data.attributes.quiz_markup_tools.data[0].id,
                }, () => {
                    this.handleGetQuizAnswers();
                })
            }
        }
        else if (this.getQuizAnswersDataApiCallId === apiRequestCallId) {
            this.setState({ answerData: responseJson })
        }
        else if (this.sendQuestionFeedbackApiCallId === apiRequestCallId) {
            if (responseJson?.data) {
                this.setState({
                    openModel: false,
                    feedbackText: '',
                })
            }
            if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
                this.setState({ isLimitModalOpen: true })
            }
        } else if (this.getQuestionFeedbackApiCallId === apiRequestCallId) {
            if(responseJson && responseJson.data && responseJson.data.length > 0){
            this.setState({ feedbackText: responseJson?.data?.[responseJson?.data.length - 1]?.attributes?.description })
            }
            else { this.setState({ feedbackPlaceHolder: 'Write your feedback here', })}
        } else if (this.sendOverallFeedbackApiCallId === apiRequestCallId) {
            if (responseJson.message === "Done") {
                this.setState({ isModalOpen: true })
            }  else if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
                this.setState({ isLimitModalOpen: true })
            } else if(responseJson.message === 'Please give all the feedback'){
                this.handleOpenAlertModal();
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const authToken = await getStorageData("authToken");

        this.setState({
            authToken: authToken,
        });

        const pathname = window.location.pathname;
        const parts = pathname.split('/');
        const student_id = parts[2];
        const quiz_id = parts[3];

        this.setState({ quizId: quiz_id, studentId: student_id }, () => {
            this.handleGetQuizData();
        })

        const prevPagePath = await getStorageData("prevPagePath");
        const prevPageId = await getStorageData("prevPageId");

        if(prevPagePath && prevPageId)
        this.setState({
            prevPagePath: prevPagePath,
            prevPageId: prevPageId,
        })

        const currentRole = await getStorageData("role");

        if(currentRole !== "Teacher"){
            const checkMsg = new Message(getName(MessageEnum.NavigationMessage));
            checkMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
            checkMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(checkMsg);
        }
    }

    handleGetQuizData = () => {
        this.setState({
            isQuizLoading: true
        })

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.authToken,
        };

        const getQuizDataMsgs = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getQuizDataApiCallId = getQuizDataMsgs.messageId;
        getQuizDataMsgs.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getQuizDataApiEndPoint}?quiz_id=${this.state.quizId}`
        );
        getQuizDataMsgs.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuizDataMsgs.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getQuizDataMsgs.id, getQuizDataMsgs);
    }

    handleSendQuestionFeedback = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.authToken
        };

        const body = {
            "quiz_markup_tool_id": this.state.currentQuestionId,
            "description": this.state.feedbackText,
            "student_id": this.state.studentId,
        }

        const sendQuestionFeedback = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        sendQuestionFeedback.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendQuizQuestionFeedback
        );

        sendQuestionFeedback.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        sendQuestionFeedback.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        sendQuestionFeedback.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        this.sendQuestionFeedbackApiCallId = sendQuestionFeedback.messageId;

        runEngine.sendMessage(sendQuestionFeedback.id, sendQuestionFeedback);
    }

    getFeedbackForQuestion = () => {

        const { authToken, currentQuestionId } = this.state;

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken,
        };

        const verifyCodeSignMes = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        verifyCodeSignMes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getQuizQuestionFeedback}${currentQuestionId}&student_id=${this.state.studentId}`
        );

        verifyCodeSignMes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        verifyCodeSignMes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        this.getQuestionFeedbackApiCallId = verifyCodeSignMes.messageId;

        runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
    }

    sendOverallFeedback = (is_complete_now: boolean) => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.authToken
        };

        const body = {
            "markup_tools_id": Number(this.state.first_markup_tools_id),
            "student_id": Number(this.state.studentId),
            "type": "quiz",
            "quiz_id": this.state.quizId,
            "is_complete_now": is_complete_now,
        }

        const sendOverallFeedbackMsgs = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        sendOverallFeedbackMsgs.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendOverallFeedbackEndPoint
        );

        sendOverallFeedbackMsgs.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        sendOverallFeedbackMsgs.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `PATCH`
        );

        sendOverallFeedbackMsgs.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        this.sendOverallFeedbackApiCallId = sendOverallFeedbackMsgs.messageId;

        runEngine.sendMessage(sendOverallFeedbackMsgs.id, sendOverallFeedbackMsgs);
    }

    handelNext = () => {
        if (this.state.currentStep < this.state.allData.length - 1) {
            this.setState(prevState => ({
                currentStep: prevState.currentStep + 1,
                current_markup_tool_id: this.state.allData[this.state.currentStep + 1].id,
            }), () => this.handleGetQuizAnswers());
        }
    }

    handleBack = () => {
        this.setState(prevState => ({
            currentStep: Math.max(prevState.currentStep - 1, 0),
            current_markup_tool_id: this.state.allData[Math.max(prevState.currentStep - 1, 0)].id,
        }), () => this.handleGetQuizAnswers());
    };

    backToTaskList = () => {
        const { prevPagePath, prevPageId } = this.state;
        this.props.navigation.navigate(prevPagePath, { id: prevPageId })
    }

    handleGetQuizAnswers = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.authToken,
        };
        const getQuizAnswersDataMsgs = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getQuizAnswersDataApiCallId = getQuizAnswersDataMsgs.messageId;
        getQuizAnswersDataMsgs.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getQuizAnswersEndPoint}${this.state.current_markup_tool_id}&student_id=${this.state.studentId}`
        );
        getQuizAnswersDataMsgs.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuizAnswersDataMsgs.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getQuizAnswersDataMsgs.id, getQuizAnswersDataMsgs);
    }

    getAnswerStyle = (valueAnswer: string, data: AnswerApiResponse, type: string) => {
        const teacherAnswer = data && data.teacher_correct_answer;
        const studentAnswer = data && data.student_answer;
        const studentAnswerPoll = data && data.answers && data.answers[0] && data.answers[0].answer && data.answers[0].answer.includes(valueAnswer);

        if (valueAnswer === teacherAnswer || (type !== "MCQ" && studentAnswerPoll)) {
            return {
                background: "#00A52C",
                width: "92%",
                borderRadius: "20px", position: "relative" as "relative",
                margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
                cursor: "pointer",
                border: "2px solid #00A52C",
                fontFamily: "Poppins", lineHeight: "16px"
            }
        } else if (valueAnswer !== teacherAnswer && valueAnswer !== studentAnswer) {
            return {
                cursor: "pointer",
                fontFamily: "Poppins", lineHeight: "16px", position: "relative" as "relative",
                width: "92%",
                border: "1px solid #2B3D7D",
                borderRadius: "20px",
                margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
            };
        } else {
            return {
                position: "relative" as "relative",
                width: "92%",
                border: "1px solid #FF0000", borderRadius: "20px",
                margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
                background: "#FF0000",
                fontFamily: "Poppins", lineHeight: "16px",
                cursor: "pointer",
            }
        }
    };

    handleCloseModal = () => {
        this.setState({ isModalOpen: false })
        const { prevPagePath, prevPageId } = this.state;
        this.props.navigation.navigate(prevPagePath, { id: prevPageId })
    }

    handleDoneAfterSendFeedback = () => {
        const { prevPagePath, prevPageId } = this.state;
        this.props.navigation.navigate(prevPagePath, { id: prevPageId })
    }

    setInnerHTML = (text: string | null) => {
        return text ? text : '';
    }

    checkIsMcqOrPolling = (text: string) => {
        return text === "MCQ" ? "Multiple Choice Question" : "Polling Question"
    }

    addFeedback = (questionId: number | string) => {
        this.setState({ openModel: true, currentQuestionId: questionId }, () => {
            this.getFeedbackForQuestion();
        });
    }

    handelFeedbackWrite = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            feedbackText: event.target.value
        })
    }

    handleClose = () => {
        this.setState({ openModel: false, feedbackText: '', feedbackPlaceHolder: '' });
    };

    checkStatus = (text: string) => {
        return text === 'incomplete' ? 'Pending' : 'Completed'
    }

    getFeedbackBtnTxt = (text: string) => {
        return text !== 'Pending' ? 'View Feedback' : 'Feedback'
    }

    checkIsPendingQuiz = (text: string) => {
        return text !== 'Pending' ? false : true;
    }

    handleCloseAlertModal = () => {
        this.setState({isAlertModalOpen: false})
    }

    handleOpenAlertModal = () => {
        this.setState({isAlertModalOpen: true})
    }

    handleCloseSnackbar  = () => {
        this.setState({ isLimitModalOpen: false })
    }

    canvasImageCondition = (data: QuizMarkupTool) => {
        let quiz_canvas_data = data && data.attributes && data.attributes.canvas_data && JSON.parse(data.attributes.canvas_data)
        let quizCanvass = quiz_canvas_data && JSON.parse(quiz_canvas_data.canvas)
        let quizCanvassSize = quiz_canvas_data && quiz_canvas_data.canvasSize

        return { quizCanvass, quizCanvassSize }
    }
    // Customizable Area End
}
