import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
interface AssignmentMarkupTool {
  id: string;
  type: string;
  attributes: {
      assignment_stage_id: number;
      assignments_id: number;
      stage_number: string;
      title: string | null;
      question: string | null;
      videos_url: (string | null)[];
      description: (string | { topic: string; answer: string })[] | null;
      countdown_timer: number | null;
      assignment_stage_name: string;
      assignment_sub_stage_name: string[] | null;
      pairs: Pair[];
      questions: Question[] | null;
      background_image: string | null;
      images: string | null;
      videos: string | null;
      canvas_data: any,
  };
}

interface Question {
  question: {
      id: number;
      assignment_markup_tools_id: number;
      question: string;
      created_at: string;
      updated_at: string;
  };
  question_background_image: string | null;
  options: Option[];
}

interface Pair {
  id: number;
  pair_question: string;
  pair_answer: string;
  pair_correct_answer: string;
  order_no: number;
  lesson_markup_tools_id: number | null;
  created_at: string;
  updated_at: string;
  quiz_markup_tools_id: number | null;
  assignment_markup_tools_id: number;
}

interface Option {
  id: number;
  assignment_mcq_questions_id: number;
  answer: string;
  marked_as_right_answer: boolean;
  order_no: number | null;
  created_at: string;
  updated_at: string;
}

interface AssignmentResponse {
  data: AssignmentMarkupTool[];
  meta: {
      assignment_name: string;
  };
}
interface Questionss {
  questionId?: string;
  question?: string;
  answer?: string;
  isSubmit?: boolean;
}

interface GetAnswerStyleData {
  base: AssignmentMarkupTool;
  subStage?: AssignmentMarkupTool;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  newData: string[];
  slectedOption: Questionss;
  rightArray: string[];
  dragItem: null | number;
  dragOverItem: null | number;
  dragSomething: boolean;
  dragSomethingSubmit: boolean;
  allData: AssignmentMarkupTool[];
  totalStep: null | number;
      currentStep: number;
      authToken: string;
      allSelectedOption:{ answer: string; isSubmit: boolean }[];
      inputValues: {
        [keys: string]: string;
      };

      isSubmitWordCloud:boolean;
      isSubmitForChart: boolean;
      inputValuesForChart:{
        topic:string;answer:string
      }[];
      openEndedText:{
        topic:string;answer:string;isSubmit:boolean
      };
      pairsMatching:{question:string; answer:string|null}[];
      allDataSave: any[]
  

      className: string;
      dueDate: string;
      status: string;
      assignmentName: string;
  assignmentsId: number | string;
  teacher_class_id: number | string;
  assignmentDueDate: string;
  assignmentDueTime: string;
  assign_class_id: number | string;
  isModalOpen: boolean;
  allDataLoading: boolean;
  studentId: string | number;
  markup_tool_id: string | number;
  submittedAnswersIds: (string | number)[];
  submittedAnswerCount: number;
  validAnswersLength: number;
  isLimitModalOpen: boolean;
  selectedAnswers:any;
  selectedAnswersQuestion: string;
  isMultiplePollAns: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCountryApiId:string="";
  submitPostApiId:string="";
  markAsCompletedApiCallId: string = "";
  createLibraryAssignmentApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      newData:["true","flase","not sure"],
      slectedOption:{questionId: "",
        question: "",
        answer: "",
        isSubmit: false,},
      rightArray: [],
      dragItem: null,
      dragOverItem: null,
      dragSomething: false,
      dragSomethingSubmit: false,
      allData:[],
      totalStep: null,
      currentStep:0,
      authToken:"",
      allSelectedOption:[],
      inputValues:{},
      isSubmitWordCloud:false,
      isSubmitForChart:false,
      inputValuesForChart:[],
      openEndedText:{topic:"", answer:"", isSubmit:false},
      pairsMatching:[],
      allDataSave:[],
      className:"",
      dueDate:"",
      status:"",
      assignmentName:"",
      assignmentsId: "",
      teacher_class_id: "",
      assignmentDueDate: "",
      assignmentDueTime: "",
      assign_class_id: "",
      isModalOpen: false,
      allDataLoading: false,
      studentId: 0,
      markup_tool_id: 0,
      submittedAnswersIds: [],
      submittedAnswerCount: 0,
      validAnswersLength: 0,
      isLimitModalOpen: false,
      selectedAnswers: [],
      selectedAnswersQuestion: "",
      isMultiplePollAns: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (this.getCountryApiId === apiRequestCallId) {
      this.getApiData(responseJson)

    } else if(this.submitPostApiId === apiRequestCallId){
      if(responseJson?.data?.id){

        this.submitApiCall()
      }
      if(responseJson && (responseJson.error === 'Request size exceeds the maximum allowed size of 200 MB')){
        this.setState({ isLimitModalOpen: true })
      }
    } else if(this.markAsCompletedApiCallId === apiRequestCallId){
      if(responseJson && responseJson.message && responseJson.message === 'Done'){
        this.createAssignmentStudentLibraryData()
        this.setState({isModalOpen: true})
      } else if(responseJson && (responseJson.error === 'Request size exceeds the maximum allowed size of 200 MB')){
        this.setState({
          isLimitModalOpen: true,
        })
      }
    } else if(this.createLibraryAssignmentApiCallId === apiRequestCallId){
      if(responseJson && (responseJson.error === 'Request size exceeds the maximum allowed size of 200 MB')){
        this.setState({
          isLimitModalOpen: true,
        })
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();

    
    const pathname = window.location.pathname;
    const lastSegment = pathname.substring(pathname.lastIndexOf('/') + 1);


    const auth = await getStorageData("authToken");
    const studentRole = await getStorageData("role");

    if(studentRole !== "Student"){
      const studentRoleMsg = new Message(getName(MessageEnum.NavigationMessage));
      studentRoleMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
      studentRoleMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(studentRoleMsg);
    }

    this.setState({
      authToken: auth
    })
    this.handleCountryApi(auth, lastSegment);

    let assignmentData = await getStorageData("assignmentData");
    assignmentData = JSON.parse(assignmentData)

    if(assignmentData){
      this.setState({
        teacher_class_id: assignmentData?.attributes?.teacher_classes_id,
        assignmentDueDate: assignmentData?.attributes?.end_date,
        assign_class_id: assignmentData?.attributes?.teacher_classes_id,
        status: assignmentData?.attributes?.assignment_status,
        className: assignmentData?.attributes?.class_name,
        dueDate: assignmentData?.attributes?.days_left,
      })
    }

    let student_id = await getStorageData("id");
    this.setState({studentId: student_id})
  }

  selectAnswer = (value: string, question: string, index: string) => {
    this.setState({
      slectedOption: {
        answer: value, isSubmit: false, question: question, questionId: index
      }
    })
  };

  selectMultipleAnswers = (value: string, question:string, isMultiple:boolean) => {
    
    const { selectedAnswers } = this.state;
    this.setState({
      selectedAnswersQuestion:question,
      isMultiplePollAns:isMultiple
    })
  
    if (selectedAnswers.includes(value)) {
      this.setState({
        selectedAnswers: selectedAnswers.filter((answer: string) => answer !== value),
      });
    } else {
      this.setState({
        selectedAnswers: [...selectedAnswers, value],
      });
    }
  };

  submitAnswer = (index: string, stageName: string, assignment_id: number, mainIndex: number) => {
    const { submittedAnswerCount, submittedAnswersIds } = this.state;

    if (!submittedAnswersIds.includes(assignment_id)) {
        this.setState({
            submittedAnswersIds: [...submittedAnswersIds, assignment_id],
            submittedAnswerCount: submittedAnswerCount + 1
        });
    }
    
    let bodyData: {topic?:string ; answer?: string }[];
    const { selectedAnswersQuestion, selectedAnswers,slectedOption, openEndedText, inputValues, inputValuesForChart, pairsMatching,rightArray, currentStep, allDataSave } = this.state;

    const updateAllDataSave = (newData: Questionss | { topic: string; answer: string | null; }[] | string[]) => {
      
      
      const updatedAllDataSave = allDataSave.filter((entry: { id: string |number; }) => entry.id !== currentStep);
      updatedAllDataSave.push({ id: currentStep, data: newData });
      return updatedAllDataSave;
    };

    if (stageName === "add_text" || stageName === "MCQ" || stageName === "polling_question" || stageName === "add_multimedia") {
      bodyData = [{
        topic: this.state.isMultiplePollAns ? selectedAnswersQuestion : slectedOption.question,
        answer: this.state.isMultiplePollAns ? selectedAnswers : slectedOption.answer
      }];

      this.setState(prevState => ({
        
        slectedOption: {
          ...prevState.slectedOption,
          isSubmit: true,
        },
        allDataSave: updateAllDataSave(this.state.slectedOption),
        
      }), () => {
        this.handleSubmitApi(index, assignment_id, bodyData, mainIndex);
      });
    }

    if (stageName === "add_word_cloud") {
      bodyData = Object.values(inputValues).map(value => ({ answer: value }));

      this.setState(prevState => ({
        isSubmitWordCloud: true,
        allDataSave: updateAllDataSave(inputValues)
      }), () => {
        this.handleSubmitApi(index, assignment_id, bodyData, mainIndex);
      });
    }

    if (["kwl_chart", "t_chart", "seed_discussion", "venn_diagram", "wh_chart"].includes(stageName)) {
      this.setState(prevState => ({
        isSubmitForChart: true,
        allDataSave: updateAllDataSave(inputValuesForChart)
      }), () => {
        this.handleSubmitApi(index, assignment_id, inputValuesForChart, mainIndex);
      });
    }

    if (stageName === "open_ended_question") {
      bodyData = [{
        topic: openEndedText.topic,
        answer: openEndedText.answer
      }];

      this.setState(prevState => ({
        openEndedText: {
          ...prevState.openEndedText,
          isSubmit: true,
        },
        allDataSave: updateAllDataSave(prevState.openEndedText)
      }), () => {
        this.handleSubmitApi(index, assignment_id, bodyData, mainIndex);
      });
    }

    if (stageName === "matching_question") {
      this.setState(prevState => ({
        dragSomethingSubmit: true,
        allDataSave: updateAllDataSave(rightArray)
      }), () => {
        this.handleSubmitApi(index, assignment_id, pairsMatching, mainIndex);
      });
    }
  };


  countExcludedStages = (data: AssignmentMarkupTool[]) => {
      const filteredData = data.filter(item => {
          const stageName = item.attributes.assignment_stage_name;
          return stageName !== 'add_text' && stageName !== 'add_multimedia';
      });

      this.setState({
        validAnswersLength: filteredData.length
      })
      
      return filteredData.length;
  }


  wordCloudHandel = (event: ChangeEvent<HTMLInputElement>, keys: string) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      inputValues: {
        ...prevState.inputValues,
        [keys]: value,
      },
    }));
  };

  handelOpenEnded = (event: ChangeEvent<HTMLTextAreaElement>, topic: string | null) => {
    this.setState({
      openEndedText: {
        topic: topic ? topic : "",
        answer: event.target.value,
        isSubmit: false
      }
    })
  }


  chartQuestionHandel = (event: ChangeEvent<HTMLTextAreaElement>, topic: string) => {
    const { value } = event.target;
    this.setState(prevState => {
      const inputValuesForChart = prevState.inputValuesForChart.filter(item => item.topic !== topic);
      inputValuesForChart.push({ topic, answer: value });
      return { inputValuesForChart };
    });
  };

  editData = () => {
    const step = this.state.currentStep
    const dataTypes = this.state.allData[step]?.attributes.assignment_stage_name;
    const datas = this.state.allDataSave.find((value:{id:string |number})=> value.id === step);

    switch(dataTypes) {
      case "add_multimedia":
      case "polling_question":
        case "MCQ":
          case "add_text":
            const textData = datas ? {
                questionId: datas.data.questionId,
                question: datas.data.question,
                answer: datas.data.answer,
                isSubmit: false
            } : {
                questionId: "",
                question: "",
                answer: "",
                isSubmit: false
            };
            this.setState({
                slectedOption: textData
            });
            break;

        case "add_word_cloud":
            this.setState({
                inputValues: datas ? datas.data : {},
                isSubmitWordCloud: false
            });
            break;

        case "kwl_chart":
        case "t_chart":
        case "seed_discussion":
        case "venn_diagram":
        case "wh_chart":
            this.setState({
                inputValuesForChart: datas ? datas.data : [],
                isSubmitForChart: false
            });
            break;

            case "matching_question":
              this.setState({
                  rightArray: datas ? datas.data : [],
                  dragSomethingSubmit: false
              });
              break;

        case "open_ended_question":
            const openEndedData = datas ? {
                topic: datas.data.topic,
                answer: datas.data.answer,
                isSubmit: false
            } : {
                topic: "",
                answer: "",
                isSubmit: false
            };
            this.setState({
                openEndedText: openEndedData
            });
            break;

        

        default:
            break;
    }
  };


  setData = (data: Pair[]) => {
    const fullData = data.map((pair) => ({ question: pair.pair_question, answer: pair.pair_answer }))
    if (this.state.rightArray.length < 1) {
      this.setState({
        rightArray: data.map((value) => value.pair_answer),
        pairsMatching: fullData

      }
      )
    }

  };
  handleSort = () => {
    const { dragItem, dragOverItem, rightArray } = this.state;
  
    if (dragItem == null || dragOverItem == null) return;
  
    let _rightArray = [...rightArray];
    
    let temp = _rightArray[dragItem];
    _rightArray[dragItem] = _rightArray[dragOverItem];
    _rightArray[dragOverItem] = temp;
  
    let _pairs = _rightArray.map((answer, index) => ({
      question: this.state.pairsMatching[index].question,  
      answer: answer
    }));
  
    this.setState({
      rightArray: _rightArray,
      dragItem: null,
      dragOverItem: null,
      dragSomething: true,
      pairsMatching: _pairs
    });
  };
  

  handleDragStart = (index: number) => {
    this.setState({ dragItem: index });
  };

  handleDragEnter = (index: number) => {
    this.setState({ dragOverItem: index });
  };

  handleCountryApi = (auth: string, index:string) => {
    this.setState({allDataLoading: true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: auth
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCountryApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assignmentApiEndpoint + `?assignment_id=${index}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getApiData = (responseJson: AssignmentResponse): void => {

    const validData = responseJson.data.filter(item => {
      const stageNumber = Number(item.attributes.stage_number);
      return !isNaN(stageNumber);
    });

    if(responseJson && responseJson.data)
    this.setState({
      allData: responseJson.data,
      totalStep: validData.length,
      assignmentName: responseJson?.meta?.assignment_name,
      assignmentsId: responseJson?.data[0]?.attributes?.assignments_id,
      markup_tool_id: responseJson.data[0].id,
      allDataLoading: false,
    }, () => {
      this.countExcludedStages(this.state.allData)
    });
  };

  handelNext = () => {
    if (this.state.currentStep < this.state.allData.length - 1) {
      this.setState(prevState => ({
        currentStep: prevState.currentStep + 1
      }),()=>{this.setOldData(this.state.currentStep)});
    }
    this.setState({
      slectedOption: {
        questionId: "",
        question: "",
        answer: "",
        isSubmit: false
      },
      dragSomething: false,
      dragSomethingSubmit: false,
      isSubmitWordCloud: false,
      isSubmitForChart: false,
      inputValuesForChart: [],
      openEndedText: { topic: "", answer: "", isSubmit: false },
    })

  }

  setOldData = (step:number) => {
    const dataType = this.state.allData[step]?.attributes.assignment_stage_name;
    const data = this.state.allDataSave.find((value:{id:string |number})=> value.id === step);

    switch(dataType) {
        case "add_text":
        case "MCQ":
        case "polling_question":
        case "add_multimedia":
            const textData = data ? {
                questionId: data.data.questionId,
                question: data.data.question,
                answer: data.data.answer,
                isSubmit: true
            } : {
                questionId: "",
                question: "",
                answer: "",
                isSubmit: false
            };
            this.setState({
                slectedOption: textData
            });
            break;

        case "add_word_cloud":
            this.setState({
                inputValues: data ? data.data : {},
                isSubmitWordCloud: !!data
            });
            break;

        case "kwl_chart":
        case "t_chart":
        case "seed_discussion":
        case "venn_diagram":
        case "wh_chart":
            this.setState({
                inputValuesForChart: data ? data.data : [],
                isSubmitForChart: !!data
            });
            break;

        case "open_ended_question":
            const openEndedData = data ? {
                topic: data.data.topic,
                answer: data.data.answer,
                isSubmit: true
            } : {
                topic: "",
                answer: "",
                isSubmit: false
            };
            this.setState({
                openEndedText: openEndedData
            });
            break;

        case "matching_question":
            this.setState({
              rightArray: data ? data.data : [],
                dragSomethingSubmit: !!data,
                dragSomething: !!data
            });
            break;

        default:
            break;
    }
}


  handelBack = () => {
    this.setState(prevState => ({
      currentStep: Math.max(prevState.currentStep - 1, 0)
  }),()=>{this.setOldData(this.state.currentStep)});
    this.setState({
      slectedOption: {
        questionId: "",
        question: "",
        answer: "",
        isSubmit: false
      },
      dragSomething: false,
      dragSomethingSubmit: false,
      isSubmitWordCloud: false,
      isSubmitForChart: false,
      inputValuesForChart: [],
      openEndedText: { topic: "", answer: "", isSubmit: false },
    })
    
    if(this.state.currentStep == 0){
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage),
      );
      message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "TaskList"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage),
      );
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
    
  }

  handleSubmitApi = (index: string, assignment_id: number, thatData: {question?: string, topic?:string ; answer?: string | null}[], mainIndex: number) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authToken
    };

    const body = {
      "assignment_id": mainIndex,
      "assignment_stage_id": assignment_id,
      "answers": thatData,
    }

    const verifyCodeSignMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/student_answer`
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.submitPostApiId = verifyCodeSignMes.messageId;
    runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
  };

  setTypeForChart = (dataType: string) => {
    let type;
    if (dataType === "kwl_chart") {
      type = "KWL Chart Question"
    } else if (dataType === "t_chart") {
      type = "T-Chart Question"
    } else {
      type = "Seed Discussion Question"
    }
    return type
  };

  getOptionStyle = (value: string) => {



    if (this.state.slectedOption.answer === value || this.state.selectedAnswers.includes(value)) {
      if (this.state.slectedOption.isSubmit) {
        return {
          width: "92%", background: "#2B3D7D", borderRadius: "20px", position: "relative",
          margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
          cursor: "pointer", border: "2px solid #2B3D7D",
          fontFamily: "Poppins", lineHeight: "16px"
        } as React.CSSProperties;
      } else {
        return {
          width: "92%", border: "2px solid #2B3D7D", borderRadius: "20px", position: "relative",
          margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
          cursor: "pointer",
          fontFamily: "Poppins", lineHeight: "16px"
        };
      }
    } else {
      return {
        width: "92%", border: "1px solid #2B3D7D", borderRadius: "20px",
        margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
        cursor: "pointer",
        fontFamily: "Poppins", lineHeight: "16px"
      };
    }
  };

  submitApiCall = () => {
    this.setState({
      isMultiplePollAns:false
    })
  }

  handleBack = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TaskList");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleMarkAsCompleted = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken,
    };

    const httpBody = {
      "markup_tools_id": this.state.markup_tool_id,
      "student_id": this.state.studentId
    }

    const feedbackMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsCompletedApiCallId = feedbackMsg.messageId;

    feedbackMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markAsCompletedApiEndPoint}${this.state.assignmentsId}`
    );

    feedbackMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    feedbackMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    feedbackMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsCompletedAMethod
    );
    runEngine.sendMessage(feedbackMsg.id, feedbackMsg);
  }

  handleCloseModal = () => {
    this.setState({isModalOpen: !this.state.isModalOpen})

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TaskList");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  createAssignmentStudentLibraryData = () => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.authToken
    };
    
    const body = {
        assignment_id: this.state.assignmentsId,
    }

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createStudentLibraryAssignment
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethodType
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
    );
    this.createLibraryAssignmentApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
};
handleCloseSnackbar = () => {
  this.setState({
    isLimitModalOpen: false,
  })
}

textQuesCanvasCond = (assignmentData: GetAnswerStyleData) => {

  let canvas_data = assignmentData.base && assignmentData.base.attributes && assignmentData.base.attributes.canvas_data
  const parsedData = typeof canvas_data == "object"? canvas_data : JSON.parse(canvas_data);

  let canvas = parsedData && typeof parsedData == "object" ?  JSON.parse(parsedData.canvas) : parsedData.canvas; 
  let canvasSize = canvas_data && canvas_data.canvasSize;

  let descCanvas = parsedData && typeof parsedData == "object" ?  JSON.parse(parsedData.AddTextCanvas) : parsedData.AddTextCanvas; 
  let addTextCanvasSize = canvas_data && canvas_data.addTextCanvasSize;
  
  let canvas_dataForSub = assignmentData.subStage && assignmentData.subStage.attributes && JSON.parse(assignmentData.subStage.attributes.canvas_data)
  let canvasForSub = canvas_dataForSub && JSON.parse(canvas_dataForSub.canvas)
  let canvasSizeForSub = canvas_dataForSub && canvas_dataForSub.canvasSize;

  return {
    canvas,
    canvasSize,

    descCanvas,
    addTextCanvasSize,

    canvasForSub,
    canvasSizeForSub,
  };
}

commonCanvasImageCondition = (assignmentData: AssignmentMarkupTool) => {
  let canvasData = assignmentData &&  assignmentData.attributes.canvas_data && JSON.parse(assignmentData.attributes.canvas_data)
  let canvas = canvasData && JSON.parse(canvasData.canvas)
  let canvasSize = canvasData && canvasData.canvasSize;
  return { canvas, canvasSize }
}

multiMediaCanvasImageCondition = (data: AssignmentMarkupTool) => {
  let canvasDatas = data && data.attributes.canvas_data && JSON.parse(data.attributes.canvas_data)
  let canva = canvasDatas && JSON.parse(canvasDatas.canvas)
  let canvaSize = canvasDatas && canvasDatas.canvasSize;
  return { canva, canvaSize }
}

canvasImageParseCondition = (assignmentData: AssignmentMarkupTool) => {
  let canvasData = assignmentData && assignmentData.attributes && assignmentData.attributes.canvas_data
  const parsedData = canvasData && typeof canvasData == "object"? canvasData : JSON.parse(canvasData);

  let canvas = parsedData && JSON.parse(parsedData.canvas)
  let canvasSize = parsedData && parsedData.canvasSize;
  return { canvas, canvasSize }
}
  // Customizable Area End
}
