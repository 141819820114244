Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area StartCfQuestionBank2
exports.dashboardGetUrl = "bx_block_dashboard/dashboards";
exports.classListGetUrl = "/bx_block_content_management/teacher_flows/teacher_class_index";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.deleteConfirmation = "Do you want to delete this event?";
exports.calendarEventsGetUrl = "bx_block_content_management/assignments/assignment_index_for_teacher";
exports.calendarEventDeletetUrl = "bx_block_content_management/teacher_flows/delete_events";
exports.getTeacherDashboardData = "bx_block_content_management/teacher_flows/current_user_classes_list";
exports.getStudentClasslistData = "bx_block_peoplemanagement2/people_managements/student_classes_list";
exports.Delete = "DELETE"
exports.selectOptionText = "Select an option to create"
exports.selectAssignOption = "Select an option to Assign New"
exports.createLesson="/bx_block_content_management/lessons"
exports.getTeacherEventsApi='bx_block_calendar/calendar_events'
exports.Post="POST"
exports.GetMethod="GET"
exports.createAssignment="bx_block_content_management/assignments"
exports.deleteAssignment="bx_block_content_management/assignments/delete_assignment"
exports.editEventsAPiEndPoint = '/bx_block_calendar/calendar_events'
exports.createQuizEndPoint = "bx_block_content_management/teacher_flows/create_quiz"
exports.updateQuizEndPoint = "/bx_block_content_management/teacher_flows/update_quiz"
exports.deleteQuizEndPoint = "bx_block_content_management/teacher_flows/delete_quiz"
exports.getClassesDataApi = "bx_block_content_management/teacher_flows/current_user_classes_list"
exports.getFoldersListApi = "bx_block_content_management/assignments/assignment_folder_list" 
exports.getQuizFoldersListApi = "/bx_block_content_management/teacher_flows/quiz_folder_list"
exports.getLessonsFoldersListApi = "/bx_block_content_management/lessons/lesson_folder_list"
exports.deleteTeacherCalendarEvents = "/bx_block_calendar/calendar_events/delete"
exports.assignTaskApi = "/bx_block_tasks/tasks"
exports.showLessonsFolder = "/bx_block_content_management/lessons/show_folder"
exports.showQuizFolder = "/bx_block_content_management/teacher_flows/show_quiz_folder"
exports.searchUserGroupApi = "/bx_block_tasks/tasks/search_user_group"
exports.searchStudentsMailAPi = "bx_block_tasks/tasks/search_students_with_email"
exports.getStudentEventList = "bx_block_calendar/calendar_events/student_calendar_events"
exports.userGroupApi = "/bx_block_tasks/tasks/search_user_group" 
exports.editTaskApi = "/bx_block_tasks/tasks/update_task"
exports.deleteTaskApi = "/bx_block_tasks/tasks/delete"
exports.backText = "Back"
exports.saveUser = "Save User"
exports.viewQuiz="View Quiz"
exports.showAssignmentFolder="bx_block_content_management/assignments/show_assignment_folder";
// Customizable Area End