import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ACTIONS, COURSES_PAGES } from "../../../components/src/types";
import { gapi } from 'gapi-script';
import { createAndUploadJsonFile } from "./Teacher/creatAndUploadJsonFile";
import StorageProvider from "../../../framework/src/StorageProvider";
import { getStorageData } from "../../../framework/src/Utilities";

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/drive';


interface LessonData {
  id: string;
  type: string;
  attributes: {
    [key:string]:any
    lesson_markup_tools_attributes: MarkupTool[];
  };
}

interface MarkupTool {
  stage_number: string;
  [key: string]: any;
}

interface AssignmentDataIn {
  id: string;
  type: string;
  attributes: {
    [key: string]: any;
    assignment_markup_tools: {data:MarkupToolAS[]};
  };
}

interface MarkupToolAS {
  [key: string]: any;
  attributes:{
    stage_number: string;
    [key: string]: any;
  }
}
interface QuizResponseInterface {
  errors: any;
  data: {
      id: string;
      type: string;
      attributes: {
          quiz_stage_id: number;
          quiz_id: number;
          stage_number: number;
          point: boolean;
          double_point: boolean;
          title: string;
          description: string;
          countdown_timer: null | number;
          quiz_stage_name: string;
      };
  };
}

interface QuizResponse {
  meta: {
    quiz_name: string;
  };
  data: StageData[];
};

interface StageData {
  stageNumber: string;
  attributes: {
    quiz_stage_name: string;
  };
};

interface QuizPayLoad   {
  id: number,  text: string
}
type DescriptionType = { [key: string]: string }[] | string[] | string;
interface Values{
  checkedA: boolean;
  checkedB: boolean;
  checkedC: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  canvas?:any
  handleRef?: any
  handleCanvas?: any
  setCanvasObj?:any
  isCanvasFocused?:boolean
  setCanvasFocused?:React.Dispatch<React.SetStateAction<boolean>>
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  role: string|null;
  selectedView:string;
  lessonId:number;
  lessonName:string;
  stages:any[],
  quizName:string,
  currentStageCount:number,
  editId: string,
  assignmentId:number,
  loading:boolean,
  canvas: fabric.Canvas | null;
  canvasHistory: any[];
  redoHistory: any[];
  selectedFontFamily: string;
  selectedFontSize: string
  open: boolean;
  textModal: boolean;
  penStop: boolean;
  colorPicker: boolean
  undoStack: any,
  selectedIcon: string,
  color:string,
  onlyPenDat: any,
  selectedAlign: string,
  zoom: boolean,
  defaultBold: string,
  currentIndex: number
  buttonType:string;
  updatedData: any,
  isUndoRedo: boolean,
  saveLessonLib:boolean,
  saveModalOpen:boolean,
  showSaveSuccess:boolean,
  isLimitModalOpen: boolean,
  isSignedIn: boolean,
  stageDetails:any,
  saveToDrive:boolean,
  isActive:string;
  isDoublePointActve :string; 
  openEndedState: {
    checkedA: boolean;
    checkedB: boolean;
    checkedC: boolean;
  };
  mcqSettingState: {
    checkedA: boolean;
    checkedB: boolean;
    checkedC: boolean;
  };
  pollSettingState:{
    checkedA: boolean;
    checkedB: boolean;
    checkedC: boolean;
  },
  matchSetState:{
    checkedA: boolean;
    checkedB: boolean;
    checkedC: boolean;
  }
  isMusic?: boolean;
  isLimitError: boolean;
  gDrivefile: File | null;
  addingModal: boolean;
  gdriveModal:boolean;
  uploadModal:boolean;
  oldUserId: number;
  assignUserId: number;
  mistmatchTypeErr: string;
  innerModalOpen:boolean;
  isLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfCourseCreation23Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiAddTextStage:string =""
  apiAddChartStage:string= ""
  apiGetStages:string=""
  apiAddMultiMediaStage:string =""
  apiUpdateMultimediaStage :string=""
  apiAddWordCloudStage:string = ""
  apiaddMcqStage:string = ""
  apiaddPollStage:string = ""
  apiaddOpenEndedStage:string = ""
  apiAddTextStageForAssignment:string = ""
  apiGetStagesForAssignment:string=""
  apiGetStagesForQuiz:string=""
  apiAddMatchingQ:string=""
  apiAddChartStageForAssignment:string=""
  apiaddMcqStageForAssignment:string=""
  apiaddPollStageForAssignment:string=""
  apiAddWordCloudStageForAssignment:string=""
  apiaddOpenEndedStageForAssignment:string=""
  apiAddMatchingQForAssignment:string=""
  apiAddMultiMediaStageForAssignment:string=""
  deleteStageApiId:string=""
  updateStageApiId:string=""
  updateSubStageApiId:string=""
  apiUpdateTextStage:string=""
  apiUpdateQuizStage: string = ""
  apiAddMatchingQForQuiz: string = ""
  apiaddPollStageforQuiz: string = ""
  apiaddOpenEndedStageForQuiz: string = "";
  apiaddMcqStageForQuiz: string = ""
  saveLeesonLibApiId:string=""
  apiAssignmentUpdateStage:string=""
  uplaodFileCallId:string=""
  apiUpdateQuizMusic:string="";

  allFontFamily= [
    'Arial Black',
    'Arial Narrow',
    'Arimo',
    'Century Gothic',
    'Comic Neue',
    'Courier',
    'Courier New',
    'Garamond',
    'Georgia',
    'Gill Sans',
    'Helvetica',
    'Impact',
    'monospace',
    'Palatino',
    'Poppins',
    'Roboto',
    'sans-serif',
    'Tahoma',
    'Times',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana'
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      role:"",
      selectedView: "",
      lessonId:-1,
      stages:[],
      currentStageCount:0,
      lessonName:"",
      quizName:"",
      editId: "",
      defaultBold: "1",
      currentIndex: -1,
      assignmentId:-1,
      loading:false,
      canvas: null,
      canvasHistory: [],
      redoHistory: [],
      selectedFontFamily:"",
      selectedFontSize: "11px",
      open: false,
      textModal:false,
      penStop: false,
      colorPicker: false,
      undoStack: [],
      selectedIcon: "1",
      color: "red",
      onlyPenDat:null,
      selectedAlign: "1",
      zoom: false,
      buttonType : "",
      updatedData: null,
      isUndoRedo: false,
      saveLessonLib:false,
      saveModalOpen:false,
      showSaveSuccess:false,
      isLimitModalOpen: false,
      isSignedIn: false,
      stageDetails: [],
      saveToDrive: false,
      isDoublePointActve:"",
      isActive:"",
      openEndedState: {
        checkedA: false,
        checkedB: false,
        checkedC: false
      },
      mcqSettingState :{
        checkedA: false,
        checkedB: false,
        checkedC: false,
      },
      pollSettingState:{
        checkedA: false,
        checkedB: false,
        checkedC: false,
      },
      matchSetState:{
        checkedA: false,
        checkedB: false,
        checkedC: false,
      },
      isMusic: false,
      isLimitError:false,
      gDrivefile: null,
      addingModal:false,
      gdriveModal:false,
      uploadModal:false,
      oldUserId: 0,
      assignUserId: 0,
      mistmatchTypeErr: "",
      innerModalOpen: false,
      isLoading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    let cfResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let cfError= message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    ); 
    const cfCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    ); 

    this.handleGetStageResponse(cfCallId,cfResponse,cfError)
    if(cfCallId == this.apiAddMultiMediaStage || cfCallId == this.apiAddMultiMediaStageForAssignment){
      if(!cfResponse){
        if(cfError){
          this.setState({
            isLimitModalOpen: true,
            isLimitError: false,
            loading:false
          })
        }
        return
      }
    }
    this.handleStageAddedResponse(cfCallId,cfResponse,cfError)
    this.handleMultiMediaUpdatedResponse(cfCallId,cfResponse,cfError)
    this.handleStageAddedForAssignmentResponse(cfCallId,cfResponse,cfError)
    this.handleDeleteStageResponse(cfCallId)
    this.handleStageUpdatedResponse(cfCallId,cfResponse,cfError)
    this.handleQuizUpdatedResponse(cfCallId,cfResponse,cfError)
    this.handleStageAddedQuizResponse(cfCallId,cfResponse,cfError)
    this.handlesaveLesson(cfCallId,cfResponse)
    this.handleAssignmentUpdatedResponse(cfCallId,cfResponse,cfError)
    this.setState({
      loading:false
    })
    // Customizable Area End
  }
  
  handlesaveLesson( apiRequestCallId: string,
    responseJson: any){
      
      if(apiRequestCallId != null && (apiRequestCallId ===this.saveLeesonLibApiId )){
        this.setState({
          showSaveSuccess:true,
          stageDetails: responseJson
        },()=>{
          createAndUploadJsonFile(this.state.stageDetails.data)
          this.setState({saveToDrive:false})
        })        
      }
  }


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.handleClientLoad();
    this.setState({
      role:window.localStorage.getItem("role"),
      lessonId:this.props.navigation.getParam("id"),
      selectedView:this.props.navigation.getParam("type"),
      lessonName:decodeURIComponent(this.props.navigation.getParam("name")),
      assignmentId:this.props.navigation.getParam("id")
    })
    StorageProvider.set("oldLessonId",this.props.navigation.getParam("id"))
    this.getStages(this.props.navigation.getParam("id"),this.props.navigation.getParam("type"))
    localStorage.removeItem('canvasdata')
    localStorage.removeItem('addTextCanvasdata')
  }
  redirectToDashboard(){
    this.props.navigation.navigate("Dashboard")
  }
  getStages(id:number,type:string){
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if(type===COURSES_PAGES.LESSON){
      this.apiGetStages = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getStagesApi}?lesson_id=${id}`
      );
    }
   if(type===COURSES_PAGES.ASSIGNMENT){
      this.apiGetStagesForAssignment = requestMessage.messageId;
      requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStageApiForAssignment}?assignment_id=${id}`
    );
}

    if (type === COURSES_PAGES.QUIZ) {
      this.apiGetStagesForQuiz = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAllThQuiz}?quiz_id=${id}`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  addTextStage(data:{ type: string, title: string, description: [], image: string,id?:number,multiple:boolean}, buttonType : string){
    this.setState({buttonType: buttonType});
    let dataText:any = localStorage.getItem('canvasdata')
    let data2 = dataText ? JSON.parse(dataText) :{objects:[{text:""}]}
    if(buttonType != 'save') data.id = 0;
    data.id = data.id ?? 0
    
    if(data.id >0){
      data.type==="ASSIGNMENT"?this.updateAssignmentStageBasedOnType(data,ACTIONS.ADD_TEXT):this.updateStageBasedOnType(data,ACTIONS.ADD_TEXT)
    }
    else{
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: window.localStorage.getItem("authToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      let canvas = {
        "canvas": localStorage.getItem('canvasdata'),
        "canvasSize":localStorage.getItem('canvasSize'),
        "AddTextCanvas":localStorage.getItem('addTextCanvasdata'),
        "addTextCanvasSize":localStorage.getItem('addTextCanvasSize')
        
      }
      if(data.type === COURSES_PAGES.LESSON){
       
        const body={
          stage_type: "add_text",
          lesson_id: this.state.lessonId,
          title:  data.title ? data.title : data2.objects[0].text,
          description: data.description,
          stage_number:this.state.currentStageCount+1,
          canvas_data: canvas
        }
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
        this.apiAddTextStage = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addTextApi}`
        );
        
      }
      if(data.type === COURSES_PAGES.ASSIGNMENT){
        const body={
          stage_type: "add_text",
          assignment_id: this.state.assignmentId,
          title: data.title,
          description: data.description,
          stage_number:this.state.currentStageCount+1,
          canvas_data: canvas

        }
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
        this.apiAddTextStageForAssignment = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addTextApiForAssignment}`
        );
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    localStorage.setItem("canvasdata",JSON.stringify({}))
    localStorage.setItem("addTextCanvasdata",JSON.stringify({}))
    
    return true;
  }

  addDescriptionToStage = (formData:FormData,title:string,stageType:string,description?: {[key: string]: string}[]| string[],multiple?:boolean) => {
    if(!description) return
    switch(stageType){
      case ACTIONS.ADD_WORD_CLOUD:
        description.forEach((item:any) => formData.append("description[]", JSON.stringify(item)))
        break;
      case ACTIONS.ADD_MCQ:
      case ACTIONS.ADD_POLL:
        formData.append("questions[][question]", title)
        description.forEach((item:any) => {
          formData.append("questions[][options][][option]", item.text)
          stageType == ACTIONS.ADD_MCQ && formData.append("questions[][options][][marked_as_right_answer]", item.isCorrect)    
        })
        stageType== ACTIONS.ADD_POLL && formData.append("multiple", JSON.stringify(multiple))
        break;
      case ACTIONS.ADD_TEXT:
      case ACTIONS.KWL_CHART:
        description.forEach((item:{[key: string]: string}|string) => {
          //text
          if(typeof item == "string"){
            formData.append(`description[]`, item)
          }
          else{
            Object.entries(item).forEach(curObject=>formData.append(`description[][${curObject[0]}]`, curObject[1]))   
          }
        })
        break;
    }
  }

  updateStageBasedOnType = (data:{ type: string, title: string, description?: DescriptionType,id?:number,question?:string,backgroundImage?:string,image?:string,pairs?:any,multiple:boolean},stageType:string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formData= new FormData();
    const curStage = this.state.stages.find((stage:any)=>stage.id == data.id)
    formData.append("stage_number", curStage.stageNumber);
    formData.append("id", (data?.id ?? 0).toString());

    this.addCanvasData(stageType,formData)
    data.question && formData.append("question", data.question);
   
    if(data.backgroundImage){
      formData.append("background_image",data.backgroundImage)
    }
    else if(data.image){
      formData.append("background_image", data.image)
    }
    
    formData.append("title", data.title);

    if(data.pairs){
      const lessonOptions = data.pairs
      for(let i=0;i<lessonOptions.length;i++){
        formData.append("options[][order_no]", (i+1).toString());
        formData.append("options[][pair_question]", lessonOptions[i].pair_question);
        formData.append("options[][pair_answer]", lessonOptions[i].pair_answer);
        formData.append("options[][pair_correct_answer]", lessonOptions[i].pair_correct_answer);
      }
    }
    if(typeof data.description == "string"){
      formData.append("description", data.description);
    }
    else{
      this.addDescriptionToStage(formData,data.title,stageType,data.description,data.multiple)
    }
   
    this.apiUpdateTextStage = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateLessonStageMcq}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
    );
    const header = {
      token: window.localStorage.getItem("authToken"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PATCH
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

addCanvasData=(stageType:string,formData:FormData)=>{
  if(stageType == ACTIONS.ADD_TEXT){
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
      "AddTextCanvas":localStorage.getItem('addTextCanvasdata'),
      "addTextCanvasSize":localStorage.getItem('addTextCanvasSize')
    }
    if(canvas.canvas!==null || canvas.AddTextCanvas != null){
      formData.append("canvas_data", JSON.stringify(canvas));
    }
  }
  else{
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
    }
    if(canvas.canvas!==null){
      formData.append("canvas_data", JSON.stringify(canvas));
    }
  }
}
  updateAssignmentStageBasedOnType = (data:{ type: string, title: string, description?: DescriptionType,id?:number,question?:string,backgroundImage?:string,image?:string,pairs?:any,multiple:boolean},stageType:string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const formData= new FormData();
    const curStage = this.state.stages.find((stage:any)=>stage.id == data.id)
    formData.append("stage_number", curStage.stageNumber);
    data.id && formData.append("id",data.id.toString());

    this.addCanvasData(stageType,formData)
    
    data.question && formData.append("question", data.question);
   
    if(data.image){
      formData.append("background_image", data.image)
    }
    else if(data.backgroundImage){
      formData.append("background_image",data.backgroundImage)
    }
    formData.append("title", data.title);

    if(data.pairs){
      const options = data.pairs
      // Iterating options to push to formData
      for(let i=0;i<options.length;i++){
        formData.append("options[][order_no]", (i+1).toString());
        formData.append("options[][pair_question]", options[i].pair_question);
        formData.append("options[][pair_answer]", options[i].pair_answer);
        formData.append("options[][pair_correct_answer]", options[i].pair_correct_answer);
      }
    }
    if(typeof data.description == "string"){
      formData.append("description", data.description);
    }
    else{
      this.addDescriptionToStage(formData,data.title,stageType,data.description,data.multiple)
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
    );
    this.apiAssignmentUpdateStage = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAssignmentStage}`
    );
    const header = {
      token: window.localStorage.getItem("authToken"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PATCH
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateQuizForStage = (data:{ point:string,double_point:string,countdown_timer?:string,type: string, title: string, description?: DescriptionType,id?:number,question?:string,backgroundImage?:string,image?:string,pairs?:{ pair_question: string, pair_answer: string, pair_correct_answer: string }[],multiple:boolean},stageType:string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formData= new FormData();
    const curStage = this.state.stages.find((stage:any)=>stage.id == data.id)
    formData.append("point", data.point);
    formData.append("double_point", data.double_point);
    if(data.pairs){
      const options = data.pairs
      for(let inFoData=0;inFoData<options.length;inFoData++){
        formData.append("options[][order_no]", (inFoData+1).toString());
        formData.append("options[][pair_question]", options[inFoData].pair_question);
        formData.append("options[][pair_answer]", options[inFoData].pair_answer);
        formData.append("options[][pair_correct_answer]", options[inFoData].pair_correct_answer);
      }
    }
    if (data.countdown_timer !== undefined) {
      formData.append("countdown_timer", data.countdown_timer);
      }
      let canvas = {
        "canvas": localStorage.getItem('canvasdata'),
        "canvasSize":localStorage.getItem('canvasSize'),
      }
      if(canvas.canvas!==null){
        formData.append("canvas_data", JSON.stringify(canvas));
      }
    formData.append("title", data.title);
    formData.append("stage_type", curStage?.stageType);
    formData.append("stage_number", curStage?.stageNumber);
    formData.append("id", (data.id ?? 0).toString());
    data.question && formData.append("question", data.question);
    if (data.image) {
      formData.append("background_image", data.image);
  }
    if(typeof data.description == "string"){
      formData.append("description", data.description);
    }
    else{
      this.addDescriptionToStage(formData,data.title,stageType,data.description,data.multiple)
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
    );
    this.apiUpdateQuizStage = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateQuizEndPoint}`
    );
    const header = {
      token: window.localStorage.getItem("authToken"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addChartStage(data:{ type: string, title: string, description: {[key: string]: string}[],id?:number,question:string,multiple:boolean}, stageType:string, buttonType: string='create' ){
    this.setState({ buttonType: buttonType });
    if(buttonType != 'save') data.id = 0;
    data.id = data.id ?? 0
    
    if(data.id > 0){
      data.type==="ASSIGNMENT"?this.updateAssignmentStageBasedOnType(data,ACTIONS.KWL_CHART): this.updateStageBasedOnType(data,ACTIONS.KWL_CHART)
    }
    else{
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: window.localStorage.getItem("authToken"),
      };
      let canvas = {
        "canvas": localStorage.getItem('canvasdata'),
        "canvasSize":localStorage.getItem('canvasSize'),
      }
      if(data.type===COURSES_PAGES.LESSON){
       
        const body={
          stage_type: stageType,
          lesson_id: this.state.lessonId,
          question: data.question,
          stage_number:this.state.currentStageCount+1,
          description: data.description,
          title:data.title,
          canvas_data: canvas
        }
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
        this.apiAddChartStage = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addKwlChartApi}`
        );
      }
      if(data.type===COURSES_PAGES.ASSIGNMENT){
        const body={
          stage_type: stageType,
          assignment_id: this.state.assignmentId,
          question: data.question,
          description: data.description,
          stage_number:this.state.currentStageCount+1,
          title:data.title,
          canvas_data: canvas
        }
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
        this.apiAddChartStageForAssignment = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addTextApiForAssignment}`
        );
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
     requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        data.id>0?configJSON.PUT:configJSON.POST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }   
    
    return true;
  }

  addMultiMediaStage(data:any,type:string,buttonType:string='create'){
    this.setState({
      loading:true,
      buttonType:buttonType
    })
    const fileArray: File[] = [];
    const thumbnailUrlArray: string[] = [];

    if (Array.isArray(data)) {
    data?.forEach((item:any) => {
      if ('thumbnailUrl' in item) {
        thumbnailUrlArray.push(item.thumbnailUrl);
      }else{
        fileArray.push(item as File);
      }
    });
    }
    
    const header = {
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formData= new FormData();
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
    }
    if(type===COURSES_PAGES.LESSON){
      formData.append("lesson_id", this.state.lessonId.toString()); 
    }
     if(type===COURSES_PAGES.ASSIGNMENT){
      formData.append("assignment_id", this.state.assignmentId.toString());
     }
    formData.append("stage_type", "add_multimedia"); 
    if(canvas.canvas!==null){
      formData.append("canvas_data", JSON.stringify(canvas));
    }
    let stageCount= this.state.currentStageCount + 1 
    fileArray.forEach((file, index) => {
      const isVideo = file.type.includes('video');
      formData.append("multimedia_attachment[][title]", "multimedia");
      formData.append(isVideo ? 'multimedia_attachment[][video]' : 'multimedia_attachment[][image]', file);
      formData.append("multimedia_attachment[][stage_number]", JSON.stringify(stageCount));
      stageCount++
    });
    thumbnailUrlArray.forEach((url, index) => {
      formData.append("multimedia_attachment[][title]", "multimedia");
      formData.append("multimedia_attachment[][video_url]", JSON.stringify(url));
      formData.append("multimedia_attachment[][stage_number]", JSON.stringify(stageCount));
      stageCount++
    });
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    if(type===COURSES_PAGES.LESSON){
      this.apiAddMultiMediaStage = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.addMultiMediaApi}`
      );
    }
    if(type===COURSES_PAGES.ASSIGNMENT){
      this.apiAddMultiMediaStageForAssignment = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.addMultimediaApiForAssignment}`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addAssignmentWC = (formData:FormData,data:any,requestMessage:Message,stageType:string) =>{
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
    }
    formData.append("assignment_id", this.state.assignmentId.toString());  
    formData.append("stage_type", stageType); 
    formData.append("title", data.title); 
    formData.append("stage_number", JSON.stringify(this.state.currentStageCount+1));
    if(canvas.canvas!==null){
      formData.append("canvas_data", JSON.stringify(canvas));
    }
    data.description.forEach((item:any) => formData.append("description[]", JSON.stringify(item)))
    if(data.backgroundImage){
      formData.append("background_image",data.backgroundImage)
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
    );
    this.apiAddWordCloudStageForAssignment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addTextApiForAssignment}`
    );
  }
  updateWordCloudByType=(data:{ type: string, title: string, description: {[key: string]: string}[], backgroundImage: string,id?:number,multiple:boolean })=>{
    data.type==="ASSIGNMENT"?this.updateAssignmentStageBasedOnType(data,ACTIONS.ADD_WORD_CLOUD):this.updateStageBasedOnType(data,ACTIONS.ADD_WORD_CLOUD)

  }

  addWordCloudStage(data:{ type: string, title: string, description: {[key: string]: string}[], backgroundImage: string,id?:number,multiple:boolean }, stageType:string,buttonType: string='create'){
    this.setState({ buttonType: buttonType });
    if(buttonType != 'save') data.id = 0;
    data.id = data.id ?? 0 

   
    if(data.id>0){
      this.updateWordCloudByType(data)
    }
    else {
      const header = {
        token: window.localStorage.getItem("authToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      const formData= new FormData();
      if(data.type===COURSES_PAGES.LESSON){
        let canvas = {
          "canvas": localStorage.getItem('canvasdata'),
          "canvasSize":localStorage.getItem('canvasSize'),
        }
        formData.append("lesson_id", this.state.lessonId.toString());  
        formData.append("stage_type", stageType); 
        formData.append("stage_number", JSON.stringify(this.state.currentStageCount+1));
        data.description.forEach((item:any) => formData.append("description[]", JSON.stringify(item)))
        formData.append("title", data.title); 
        if(canvas.canvas!==null){
          formData.append("canvas_data", JSON.stringify(canvas));
        }
        if(data.backgroundImage){
          formData.append("background_image",data.backgroundImage)
        }
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
        );
        this.apiAddWordCloudStage = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addWordCloudApi}`
        );
      }
      
      if(data.type===COURSES_PAGES.ASSIGNMENT){
        this.addAssignmentWC(formData,data,requestMessage,stageType)
      }
      this.addStageNumber(formData,data)
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POST
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } 
    
    return true;
  }

  updateMultimediaForAssignment = (title:string,id:string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const formData= new FormData();
    const curStage = this.state.stages.find((stage:any)=>stage.id == id)
    formData.append("id",id);
    formData.append("stage_number", curStage.stageNumber);
    formData.append("title", title);
    this.addCanvasData(ACTIONS.MULTIMEDIA,formData)

    this.apiAssignmentUpdateStage = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    
    const header = {
      token: window.localStorage.getItem("authToken"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAssignmentStage}`
    );
     
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PATCH
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );  
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  updateMultiMediaStage(editMultiMediaId:any, title :any,buttonType:string='create'){  
    this.setState({
      buttonType:buttonType
    })  

    if(this.state.selectedView == COURSES_PAGES.ASSIGNMENT){
      this.updateMultimediaForAssignment(title,editMultiMediaId)
      return
    }
    const header = {
      token: window.localStorage.getItem("authToken")
    };
    
    const formData= new FormData();
    formData.append("title", title);
    this.addCanvasData(ACTIONS.MULTIMEDIA,formData)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.apiUpdateMultimediaStage = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateMultimediaApi}?id=${editMultiMediaId}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  
  addStageNumber = (formData:FormData,data:any) => {
    if(data.parentStage>0){
      formData.append("stage_number", this.getSubStageNumber(data.parentStage.toString()));
    }
    else{
      formData.append("stage_number", JSON.stringify(this.state.currentStageCount+1));
    }
  }

  handleQuizType(data: { double_point: string, point: string, type: string, title: string, description: [], image: string, id?: number, background_image:string, countdown_timer:string}) {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const formData = new FormData();
  
    formData.append("stage_type", "MCQ");
    formData.append("quiz_id", this.state.assignmentId.toString());
  
    data.description.forEach((item: { isCorrect: string, text: string }) => {
      formData.append("questions[][options][][option]", item.text);
      formData.append("questions[][options][][marked_as_right_answer]", item.isCorrect);
    });
    formData.append("questions[][question]", data.title);
    formData.append("point", data.point);
    formData.append("double_point", data.double_point);
    formData.append("countdown_timer", data.countdown_timer);
    if (data.image) {
      formData.append("background_image", data.image);
    }
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
    }
    if(canvas.canvas!==null){
      formData.append("canvas_data", JSON.stringify(canvas));
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    this.apiaddMcqStageForQuiz = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addQuizMcqApi}`);
  
    this.addStageNumber(formData, data);
    const header = {
      token: window.localStorage.getItem("authToken"),
    };
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    const method = (data.id ?? 0) > 0 ? configJSON.PATCH : configJSON.POST;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handAssignmentType(data: { double_point: string, point: string, type: string, title: string, description: [], image: string, id?: number, background_image:string, countdown_timer:string}) {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const formData = new FormData();
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
    }
    formData.append("stage_type", "MCQ");
    formData.append("assignment_id", this.state.assignmentId.toString()); 
    data.description.forEach((item:{isCorrect:string, text:string}) => {
      formData.append("questions[][options][][option]", item.text)
      formData.append("questions[][options][][marked_as_right_answer]", item.isCorrect)
    })
    formData.append("questions[][question]", data.title)
    formData.append("point", data.point)  
    formData.append("double_point", data.double_point) 
    if(canvas.canvas!==null){
      formData.append("canvas_data", JSON.stringify(canvas));
    }
    if (data.image) {
    formData.append("questions[][question_background_image]", data.image)};
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    this.apiaddMcqStageForAssignment = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addMCQApiForAssignment}`);
  
    this.addStageNumber(formData, data);
    const header = {
      token: window.localStorage.getItem("authToken"),
    };
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    const method = (data.id ?? 0) > 0 ? configJSON.PATCH : configJSON.POST;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateMCQ = (data: { double_point:string, countdown_timer:string,point:string, type: string, title: string, description: [], image: string, id?:number, background_image : string,multiple:boolean }) => {
    if(data.type=="QUIZ"){
      this.updateQuizForStage(data,ACTIONS.ADD_MCQ)
    }
    else if(data.type=="ASSIGNMENT"){
      this.updateAssignmentStageBasedOnType(data,ACTIONS.ADD_MCQ)
    }
    else{
      this.updateStageBasedOnType(data,ACTIONS.ADD_MCQ)
    }
  }
  addMcqStage(data: { double_point:string, countdown_timer:string,point:string, type: string, title: string, description: [], image: string, id?:number, background_image : string,multiple:boolean  }, buttonType: string ) {
    this.setState({ buttonType: buttonType });
    if(buttonType != 'save') data.id = 0;
    data.id = data.id ?? 0
   
    if(data.id >0){
      this.updateMCQ(data)
    }
    else{
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const formData= new FormData();
      if(data.type===COURSES_PAGES.LESSON){
        let canvas = {
          "canvas": localStorage.getItem('canvasdata'),
          "canvasSize":localStorage.getItem('canvasSize'),
        }
        formData.append("stage_type", "MCQ");
        formData.append("lesson_id", this.state.lessonId.toString());  
        
        formData.append("questions[][question]", data.title)
        if(canvas.canvas!==null){
          formData.append("canvas_data", JSON.stringify(canvas));
        }
        data.description.forEach((item:any) => {
        formData.append("questions[][options][][option]", item.text)
        formData.append("questions[][options][][marked_as_right_answer]", item.isCorrect)
        
      })
       data.image && formData.append("questions[][question_background_image]", data.image)
    
       requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
        );
        this.apiaddMcqStage = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addMcqApi}`
        );
      }
      else if(data.type===COURSES_PAGES.QUIZ){
        this.handleQuizType(data);
        return true;
      }
      else if(data.type===COURSES_PAGES.ASSIGNMENT){
        this.handAssignmentType(data);
        return true;
      }
      else {
        return false; 
      }
      this.addStageNumber(formData,data)
      const header = {
        token: window.localStorage.getItem("authToken"),
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );   
     requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    return true;
  }

  addAssignmentPoll = (data:any,requestMessage:Message) => {
    const formData= new FormData();
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
    }
      formData.append("assignment_id", this.state.assignmentId.toString());  
      formData.append("questions[][question]", data.title)
      formData.append("stage_type", "polling_question");
      formData.append("stage_number", JSON.stringify(this.state.currentStageCount+1));
      if(canvas.canvas!==null){
        formData.append("canvas_data", JSON.stringify(canvas));
      }
      data.image && formData.append("questions[][question_background_image]", data.image)
      data.description.forEach((item:{text:string}) => {
        formData.append("questions[][options][][option]", item.text)
      })
      formData.append("multiple", data.multiple);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
      );
      
      this.apiaddPollStageForAssignment = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.addPollStageApiForAssignment}`
      );
  }

  addLessonPoll = (data:any,requestMessage:Message) => {
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
    }
    const formData= new FormData();
    formData.append("lesson_id", this.state.lessonId.toString());  
    formData.append("stage_type", "polling_question"); 
    formData.append("questions[][question]", data.title)
    formData.append("stage_number", JSON.stringify(this.state.currentStageCount+1));
    formData.append("multiple", data.multiple);

    data.image && formData.append("questions[][question_background_image]", data.image)
    data.description.forEach((item:any) => {
      formData.append("questions[][options][][option]", item.text) 
    })
    
    if(canvas.canvas!==null){
      formData.append("canvas_data", JSON.stringify(canvas));
    }

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
    );
    
    this.apiaddPollStage = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addPollApi}`
    );
  }

  addQuizPoll = (data:{ countdown_timer:string,double_point:string,point:string,type: string, title: string, description: {text:string}[], image: string, id?:number,multiple:boolean },requestMessage:Message) => {
    const formData= new FormData();
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
    }
    if(canvas.canvas!==null){
      formData.append("canvas_data", JSON.stringify(canvas));
    }
    formData.append("quiz_id", this.state.lessonId.toString());  
    formData.append("stage_type", "polling_question"); 
    formData.append("questions[][question]", data.title)
    formData.append("point", data.point)
    formData.append("double_point", data.double_point)
    formData.append("stage_number", JSON.stringify(this.state.currentStageCount+1));
    formData.append("multiple", JSON.stringify(data.multiple));
    formData.append("countdown_timer", data.countdown_timer);
    data.image && formData.append("background_image", data.image)
    data.description.forEach((item: {text:string}) => {
      formData.append("questions[][options][][option]", item.text)
    });
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.apiaddPollStageforQuiz = requestMessage.messageId;        
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addPollApiEndPoint}`
    );
  }

  updatePoll = (data:{ countdown_timer:string,double_point:string,point:string,type: string, title: string, description: {text:string}[], image: string, id?:number, multiple:boolean }) => {
    if(data.type=="QUIZ"){
      this.updateQuizForStage(data,ACTIONS.ADD_POLL)
    }
    else if(data.type=="ASSIGNMENT"){
      this.updateAssignmentStageBasedOnType(data,ACTIONS.ADD_POLL)
    }
    else{
      this.updateStageBasedOnType(data,ACTIONS.ADD_POLL)
    }
  }
  addPollStage(data:{ countdown_timer:string,double_point:string,point:string,type: string, title: string, description: {text:string}[], image: string, id?:number,multiple:boolean }, buttonType: string = 'create'){
    this.setState({ buttonType: buttonType });
    if(buttonType != 'save') data.id = 0;
    data.id = data.id ?? 0
    
    if(data.id >0){
      this.updatePoll(data)
    }
    else{
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        token: window.localStorage.getItem("authToken"),
      };
      if(data.type===COURSES_PAGES.LESSON){
        this.addLessonPoll(data,requestMessage)
      }
      if(data.type===COURSES_PAGES.QUIZ){
        this.addQuizPoll(data,requestMessage)
      }  
      if(data.type===COURSES_PAGES.ASSIGNMENT){
        this.addAssignmentPoll(data,requestMessage)
      }      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    return true;
  }

  getSubStageNumber = (parentStageString:string) => {
    let stageNumber = `${parentStageString}A`
    const thisParentChilds = this.state.stages.filter((stage)=>stage.stageNumber.startsWith(parentStageString) && stage.stageNumber.length>parentStageString.length).sort((a, b) => b.stageNumber.localeCompare(a.stageNumber));
    if(thisParentChilds.length>0){
      const maxStageNumber = thisParentChilds[0].stageNumber;
      const lastChar = maxStageNumber[maxStageNumber.length - 1]
      if(lastChar=='Z'){
        stageNumber=`${maxStageNumber}A`
      }
      else{
        stageNumber=`${maxStageNumber.substring(0, maxStageNumber.length - 1)}${String.fromCharCode(lastChar.charCodeAt(0) + 1)}`
      }
    }
    return stageNumber
  }
  addLessonOpenEnded = (formData:FormData,data:any,requestMessage:Message) => {
    
    formData.append("stage_type", "open_ended_question");
    formData.append("lesson_id", this.state.lessonId.toString()); 
    formData.append("title", data.title)
    formData.append("description", data.description)
    data.image && formData.append("background_image", data.image)
   
    this.apiaddOpenEndedStage = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addOpenEndedApi}`
    );
  }
  addOpenEndedQuiz=(formData:FormData,requestMessage:Message,data:{ countdown_timer:string,point:string, double_point:string, type: string, title: string, description: string, image: string, id?:number })=>{
    formData.append("stage_type", "open_ended_question");
    formData.append("quiz_id", this.state.lessonId.toString()); 
    formData.append("title", data.title)
    formData.append("description", data.description)
    formData.append("point", data.point)
    formData.append("double_point", data.double_point)
    formData.append("countdown_timer", data.countdown_timer);
    data.image && formData.append("background_image", data.image)
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
    );
    this.apiaddOpenEndedStageForQuiz = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.openEndedForQuiz}`
    );
  }
  updateOpenEnded = (data: { countdown_timer:string,point:string, double_point:string, type: string, title: string, description: string, image: string, id?:number,multiple:boolean  }) => {
    if(data.type=="QUIZ"){
      this.updateQuizForStage(data,ACTIONS.OPEN_ENDED_QUESTION)
    }
    else if(data.type=="ASSIGNMENT"){
      this.updateAssignmentStageBasedOnType(data,ACTIONS.OPEN_ENDED_QUESTION)
    }
    else{
      this.updateStageBasedOnType(data,ACTIONS.OPEN_ENDED_QUESTION)
    }
  }
  addOpenEndedStage(data: { countdown_timer:string,point:string, double_point:string, type: string, title: string, description: string, image: string, id?:number,multiple:boolean }, buttonType: string='create'){
    this.setState({ buttonType: buttonType });
    if(buttonType != 'save') data.id = 0;
    data.id = data.id ?? 0 
    
    const formData= new FormData();
    if(data.id>0){
      this.updateOpenEnded(data)
    }
    else
    {
      const header = {
        token: window.localStorage.getItem("authToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      let canvas = {
        "canvas": localStorage.getItem('canvasdata'),
        "canvasSize":localStorage.getItem('canvasSize'),
      }
      if(canvas.canvas!==null){
        formData.append("canvas_data", JSON.stringify(canvas));
      }
      if(data.type===COURSES_PAGES.LESSON){
        this.addLessonOpenEnded(formData,data,requestMessage)
      }
      if(data.type===COURSES_PAGES.ASSIGNMENT){
        formData.append("stage_type", "open_ended_question");
        formData.append("assignment_id", this.state.assignmentId.toString());   
        formData.append("title", data.title)
        formData.append("description", data.description)
        
        data.image && formData.append("background_image", data.image)
    
        this.apiaddOpenEndedStageForAssignment = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addTextApiForAssignment}`
        );
      }
      if(data.type===COURSES_PAGES.QUIZ){
        this.addOpenEndedQuiz(formData,requestMessage,data)
      
      }
  
      this.addStageNumber(formData,data)
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POST
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
   
  }

  parseVideos=(obj: any, baseId: number, newStages: any)=>{
    obj.attributes?.videos.forEach((video: any, index: number) => {
      const stage: any = {
        id: baseId,
        title: obj.attributes.title,
        stageNumber: obj.attributes.stage_number + index,
        stageType: obj.attributes.lesson_stage_name,
        attributes: {
          ...obj.attributes,
          videos: video.url,
          images: [],
          videos_url: []
        },
      };
      newStages.push(stage);
      baseId++;
    });
  }

  parseDefaultStage=(obj: any, newStages: any)=>{
    const stage: any = {
      id: obj.id,
      title: obj.attributes?.title,
      stageNumber: obj.attributes?.stage_number,
      stageType: obj.attributes?.lesson_stage_name || obj.attributes?.assignment_stage_name || obj.attributes?.quiz_stage_name ,
      attributes: obj.attributes,
    };

    newStages.push(stage);
  }

  parseMCQStage=(obj: any, newStages: any)=>{
    const stage: any = {
      id: obj.id,
      title: obj.attributes?.questions[0]?.question?.question,
      stageNumber: obj.attributes?.stage_number,
      stageType: obj.attributes.lesson_stage_name ||  obj.attributes.assignment_stage_name || obj.attributes.quiz_stage_name ,
      attributes: obj.attributes,
    };

    newStages.push(stage);
  }
  parsePOLLStage=(obj: any, newStages: any)=>{
    const stage: any = {
      id: obj.id,
      title: obj.attributes?.questions[0]?.question?.question,
      stageNumber: obj.attributes?.stage_number,
      stageType: obj.attributes?.lesson_stage_name,
      attributes: obj.attributes,
    };

    newStages.push(stage);
  }

  handleGetStages=(obj:any,newStages:any)=>{
    let stageType= obj?.attributes.lesson_stage_name || obj?.attributes.assignment_stage_name || obj?.attributes.quiz_stage_name
   if(stageType==="MCQ" || stageType==="polling_question"){
    this.parseMCQStage(obj, newStages)
   }else {
    this.parseDefaultStage(obj, newStages)
  }  
  }
  handleGetQuizStages=(obj:{attributes:{quiz_stage_name :string}},newStages:string[])=>{
    let stageType= obj?.attributes.quiz_stage_name 
   if(stageType==="MCQ" || stageType==="polling_question"){
    this.parseMCQStage(obj, newStages)
   }
  }
  handleGetStageResponse( apiRequestCallId: string,
    responseJson: any,
    errorReponse: string){
      if(apiRequestCallId != null && (apiRequestCallId === this.apiGetStages || apiRequestCallId === this.apiGetStagesForAssignment || apiRequestCallId === this.apiGetStagesForQuiz)){
        this.setState({isLoading:false})
        if(responseJson.data.length===0){
          this.setState({
            stages: [],
            currentStageCount: 0,
            lessonName: responseJson.meta?.lesson_name || responseJson.meta?.quiz_name
          });
          return
        }
        if (responseJson.data) {
          const newStages: any[] = [];
          responseJson.data.forEach((obj: any) => {
            this.handleGetStages(obj, newStages)
          });
          const sortedStages = [...newStages].sort((a: any, b: any) => a.stageNumber - b.stageNumber);
          const mainStages = sortedStages.filter(obj => /^\d+$/.test(obj.stageNumber));
          this.setState({
            stages: sortedStages,
            currentStageCount: mainStages.length > 0 ? Number(mainStages[mainStages.length - 1].stageNumber) : 0,
            lessonName: responseJson.meta?.lesson_name,
            isMusic:responseJson.meta?.is_music
          });
        } 
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
  }
  
  addVideoUrls=(videosUrlArray: any, baseId: number, responseJson: any)=>{
    const newStages = videosUrlArray.map((videoUrl: string[], index: any) => {
      const stage: any = {
        id: baseId,
        title: responseJson.data?.attributes.title,
        stageNumber: responseJson.data?.attributes.stage_number + index,
        stageType: responseJson.data?.attributes?.lesson_stage_name|| responseJson.data?.attributes?.assignment_stage_name,
        attributes: {
          ...responseJson.data?.attributes,
          images: [],
          videos: [],
          videos_url: videoUrl,
        },
      };
      baseId++;
      return stage;
    });

    this.setState({
      stages: [...this.state.stages, ...newStages],
      currentStageCount: Number(newStages[newStages.length - 1].stageNumber),
    });  
  }

  addImages=(imagesUrlArray: any, baseId: number, responseJson: any)=>{
    const newStages = imagesUrlArray.map((image: any, index: any) => {
      const stage: any = {
        id: baseId,
        title: responseJson.data?.attributes.title,
        stageNumber: responseJson.data?.attributes.stage_number + index,
        stageType: responseJson.data?.attributes?.lesson_stage_name||responseJson.data?.attributes?.assignment_stage_name,
        attributes: {
          ...responseJson.data?.attributes,
          images: image.url,
          videos: [],
          videos_url: []
        },
      };
      baseId++;
      return stage;
    });
    this.setState({
      stages: [...this.state.stages, ...newStages],
      currentStageCount: Number(newStages[newStages.length - 1].stageNumber),
    }); 
  }

  addVideos=(videoUrlArray: any, baseId: number, responseJson: any)=>{
    const newStages = videoUrlArray.map((video: any, index: any) => {
      const stage: any = {
        id: baseId,
        title: responseJson.data?.attributes.title,
        stageNumber: responseJson.data?.attributes.stage_number + index,
        stageType: responseJson.data?.attributes.lesson_stage_name,
        attributes: {
          ...responseJson.data?.attributes,
          videos: video.url,
          images: [],
          videos_url: []
        },
      };
      baseId++;
      return stage;
    });
    this.setState({
      stages: [...this.state.stages, ...newStages],
      currentStageCount: Number(newStages[newStages.length - 1].stageNumber),
    }); 
  }

  addDefaultStage=(data: any)=>{
    const stage:any={
      id:data.id,
      title:data.attributes?.title,
      stageNumber:data.attributes?.stage_number,
      stageType:data.attributes?.lesson_stage_name || data.attributes?.assignment_stage_name || data.attributes?.quiz_stage_name,
      attributes:data.attributes,
      canvas_data:data?.attributes?.canvas_data 
    }
    this.setState({
      stages: [...this.state.stages, stage],
      currentStageCount:/^\d+$/.test(stage.stageNumber)?Number(stage.stageNumber):this.state.currentStageCount
    });
  }

  addMCQStage=(responseJson: any)=>{
    const stage:any={
      id:responseJson.data.id,
      title:responseJson.data.attributes?.questions[0]?.question?.question,
      stageNumber:responseJson.data.attributes?.stage_number,
      stageType:responseJson.data.attributes?.lesson_stage_name || responseJson.data.attributes?.assignment_stage_name || responseJson.data.attributes?.quiz_stage_name,
      attributes:responseJson.data.attributes
    }
        this.setState({
      stages: [...this.state.stages, stage],
      currentStageCount:/^\d+$/.test(stage.stageNumber)?Number(stage.stageNumber):this.state.currentStageCount
    })
  }
  addPOLLStage=(responseJson: any)=>{
    
    const stage:any={
      id:responseJson.data.id,
      title:responseJson.data.attributes?.questions[0]?.question?.question,
      stageNumber:responseJson.data.attributes?.stage_number,
      stageType:responseJson.data.attributes?.lesson_stage_name || responseJson.data.attributes?.assignment_stage_name || responseJson.data.attributes?.quiz_stage_name,
      attributes:responseJson.data.attributes
    }
    this.setState({
      stages:[...this.state.stages,stage],
      currentStageCount:Number(stage.stageNumber)
    })
    
  }
  
  handleStageAddedResponse( apiRequestCallId: string,
    responseJson: any,
    errorReponse: string){
      
      if(apiRequestCallId != null && (apiRequestCallId === this.apiAddTextStage || apiRequestCallId === this.apiAddChartStage|| apiRequestCallId === this.apiAddMultiMediaStage  || apiRequestCallId === this.apiaddPollStage || apiRequestCallId === this.apiaddMcqStage || apiRequestCallId===this.apiAddWordCloudStage || apiRequestCallId===this.apiaddOpenEndedStage|| apiRequestCallId === this.apiAddMatchingQ )){
        localStorage.removeItem('canvasdata')
        localStorage.removeItem('addTextCanvasdata')
        if(responseJson.error === 'Request size exceeds the 2 GB limit.'){
          this.setState({
            isLimitModalOpen: true,
            isLimitError:true
          })
        }
        if(responseJson.errors){
          return
        }
        if(responseJson.data){
          let stageType= responseJson.data.attributes?.lesson_stage_name;
          if (Array.isArray(responseJson.data)) {
            responseJson.data.forEach((data: any)=>{
              this.addDefaultStage(data)
            })
          }else if(stageType === "MCQ"){
            this.addMCQStage(responseJson)
          }else if(stageType === "polling_question"){
            this.addPOLLStage(responseJson)
          }
          else if(stageType !== "MCQ" && stageType !== "polling_question"){
            this.addDefaultStage(responseJson.data)
          }
        }
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
  }

  handleStageUpdatedResponse= (apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) =>{
      if(apiRequestCallId != null && apiRequestCallId === this.apiUpdateTextStage){
        if(responseJson.data){
          let stageType= responseJson.data.attributes?.lesson_stage_name;

          const data = responseJson.data;
          let stages = this.state.stages;
          switch(stageType){
            case ACTIONS.ADD_TEXT:
            case ACTIONS.OPEN_ENDED_QUESTION:
            case ACTIONS.ADD_MATCHING_Q:
            case ACTIONS.ADD_WORD_CLOUD:
            case ACTIONS.KWL_CHART:
            case ACTIONS.SEED_DISCUSSION:
            case ACTIONS.T_CHART:
            case ACTIONS.WH_CHART:
            case ACTIONS.VENN_DIAGRAM:
              const textStage:any={
                    id:data.id,
                    title:data.attributes?.title,
                    stageNumber:data.attributes?.stage_number,
                    stageType:data.attributes?.lesson_stage_name,
                    attributes:data.attributes,
                    canvas_data:data?.attributes?.canvas_data 
              }
              const stageTextIndex = this.state.stages.findIndex(curTextStage=>curTextStage.id==data.id)
              stages[stageTextIndex] = textStage              
              break;
            case ACTIONS.MCQ:            
            case ACTIONS.ADD_MCQ:
            case ACTIONS.ADD_POLL:
            case ACTIONS.POLLING_Q:
              const mcqStage:any={
                id:responseJson.data.id,
                title:responseJson.data.attributes?.questions[0]?.question?.question,
                stageNumber:responseJson.data.attributes?.stage_number,
                stageType:responseJson.data.attributes?.lesson_stage_name,
                attributes:responseJson.data.attributes
              }
              const mcqStageIndex = this.state.stages.findIndex(curStage=>curStage.id==data.id)
              stages[mcqStageIndex] = mcqStage
              break;
          }
          this.setState({
            stages: stages,                  
          });
        }
      }
  }

  handleAssignmentUpdatedResponse= (apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) =>{
      if(apiRequestCallId != null && apiRequestCallId === this.apiAssignmentUpdateStage){
        if(responseJson.data){
          let stageType= responseJson.data.attributes?.assignment_stage_name;

          const data = responseJson.data;
          let stages = this.state.stages;
          switch(stageType){
            case ACTIONS.ADD_TEXT:
            case ACTIONS.OPEN_ENDED_QUESTION:
            case ACTIONS.ADD_MATCHING_Q:
            case ACTIONS.ADD_WORD_CLOUD:
            case ACTIONS.KWL_CHART:
            case ACTIONS.SEED_DISCUSSION:
            case ACTIONS.T_CHART:
            case ACTIONS.WH_CHART:
            case ACTIONS.VENN_DIAGRAM:
            case ACTIONS.MULTIMEDIA:
              
              const textStage:any={
                    id:data.id,
                    title:data.attributes?.title,
                    stageNumber:data.attributes?.stage_number,
                    stageType:data.attributes?.assignment_stage_name,
                    attributes:data.attributes,
                    canvas_data:data?.attributes?.canvas_data 
              }
              const textStageIndex = this.state.stages.findIndex(curStage=>curStage.id==data.id)
              stages[textStageIndex] = textStage              
              break;
            case ACTIONS.ADD_MCQ:
            case ACTIONS.MCQ:            
            case ACTIONS.POLLING_Q:
            case ACTIONS.ADD_POLL:
              const mcqStage:any={
                id:responseJson.data.id,
                title:responseJson.data.attributes?.questions[0]?.question?.question,
                stageNumber:responseJson.data.attributes?.stage_number,
                stageType:responseJson.data.attributes?.assignment_stage_name,
                attributes:responseJson.data.attributes
              }
              const mcqStageIndex = this.state.stages.findIndex(curStage=>curStage.id==data.id)
              stages[mcqStageIndex] = mcqStage
              break;
          }
          this.setState({
            stages: stages,                  
          });
        }
      }
  }


  handleQuizUpdatedResponse = (apiRequestCallId: string,
    responseJson: {data:{attributes:{questions: any;title:string,stage_number:number,quiz_stage_name:string,canvas_data :string},id:string}, error: string},
    errorReponse: string) =>{
      if(apiRequestCallId != null && apiRequestCallId === this.apiUpdateQuizStage){
        if(responseJson.data){
          let stageType= responseJson.data.attributes.quiz_stage_name;
          const data = responseJson.data;
          let stages = this.state.stages;
          switch(stageType){
            case ACTIONS.OPEN_ENDED_QUESTION:
            case ACTIONS.ADD_MATCHING_Q:
              const textStage={
                    id:data.id,
                    title:data.attributes.title,
                    stageNumber:data.attributes.stage_number,
                    stageType:data.attributes.quiz_stage_name,
                    attributes:data.attributes,
                    canvas_data:data.attributes.canvas_data 
              }
              const textStageIndex = this.state.stages.findIndex(curStage=>curStage.id==data.id)
              stages[textStageIndex] = textStage              
              break;
            case ACTIONS.ADD_MCQ:
            case ACTIONS.MCQ:            
            case ACTIONS.POLLING_Q:
            case ACTIONS.ADD_POLL:
              const mcqStage={
                id:responseJson.data.id,
                title:responseJson.data.attributes.title,
                stageNumber:responseJson.data.attributes.stage_number,
                stageType:responseJson.data.attributes.quiz_stage_name,
                attributes:responseJson.data.attributes
              }
              const mcqStageIndex = this.state.stages.findIndex(curStage=>curStage.id==data.id)
              stages[mcqStageIndex] = mcqStage
              break;
          }
          this.setState({
            stages: stages,                  
          });
        }
        if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
          this.setState({
            isLimitModalOpen: true,
            isLimitError:true
          })
        }
      }
  }
  handleMultiMediaUpdatedResponse( apiRequestCallId: string,
    responseJson: any,
    errorReponse: string){
      if(apiRequestCallId != null && (apiRequestCallId === this.apiUpdateMultimediaStage)){
        if(responseJson.errors){
          return
        }
        if(responseJson.data){
           this.getStages(this.props.navigation.getParam("id"),this.props.navigation.getParam("type"))
          
        }
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
  }

  handleStageAddedForAssignmentResponse( apiRequestCallId: string,
    responseJson: any,
    errorReponse: string){
      
      if(apiRequestCallId != null && (apiRequestCallId === this.apiAddTextStageForAssignment|| apiRequestCallId===this.apiAddChartStageForAssignment || apiRequestCallId===this.apiaddMcqStageForAssignment || apiRequestCallId===this.apiaddPollStageForAssignment||apiRequestCallId===this.apiAddWordCloudStageForAssignment||apiRequestCallId===this.apiaddOpenEndedStageForAssignment||apiRequestCallId===this.apiAddMatchingQForAssignment || apiRequestCallId===this.apiAddMultiMediaStageForAssignment) ){
        if(responseJson.errors){
          return
        }
        if(responseJson.data){
          let stageType= responseJson.data.attributes?.assignment_stage_name;
          if (Array.isArray(responseJson.data)) {
            responseJson.data.forEach((data: any)=>{
              this.addDefaultStage(data)
            })
           } else if(stageType==="MCQ" || stageType === "polling_question"){
            this.addMCQStage(responseJson)
          }else if(stageType !== "MCQ" && stageType !== "polling_question"){
            this.addDefaultStage(responseJson.data)
          }
          
        }
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      
  }

  handleDataIdGreaterThanZero(data:{ point:string,double_point:string,type: string, title: string, id?:number,pairs: {pair_question:string,pair_answer:string,pair_correct_answer:string}[],multiple:boolean}) {
    if (data.type === "QUIZ") {
        this.updateQuizForStage(data, ACTIONS.ADD_MATCHING_Q);
    } 
    else if(data.type=="ASSIGNMENT"){
      this.updateAssignmentStageBasedOnType(data,ACTIONS.ADD_MATCHING_Q)
    }
    else {
        this.updateStageBasedOnType(data, ACTIONS.ADD_MATCHING_Q);
    }
}


  addLessonMatching = (formData:FormData,data:any,requestMessage:Message) => {
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
    }
    formData.append("stage_type", ACTIONS.ADD_MATCHING_Q);
    formData.append("lesson_id", this.state.lessonId.toString());  
    formData.append("stage_number", JSON.stringify(this.state.currentStageCount+1));
    formData.append("title", data.title);
    if(canvas.canvas!==null){
      formData.append("canvas_data", JSON.stringify(canvas));
    }
    const options = data.pairs

    // Iterating options to push to formData
    for(let i=0;i<options.length;i++){
      formData.append("options[][order_no]", (i+1).toString());
      formData.append("options[][pair_question]", options[i].pair_question);
      formData.append("options[][pair_answer]", options[i].pair_answer);
      formData.append("options[][pair_correct_answer]", options[i].pair_correct_answer);
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.apiAddMatchingQ = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addMatchingQApi}`
    );
  }

  addMatchingQStage(data:{ countdown_timer:string,point:string,double_point:string,type: string, title: string, id?:number,pairs: {pair_question:string,pair_answer:string,pair_correct_answer:string}[],multiple:boolean,image?:string }, buttonType: string='create'){
    this.setState({ buttonType: buttonType });
    if(buttonType != 'save') data.id = 0;
    data.id = data.id ?? 0;
    
    if(data.id>0){
      this.handleDataIdGreaterThanZero(data);
      return true;
    }
    else {
      const header = {
        token: window.localStorage.getItem("authToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      const formData= new FormData();
      if(data.type===COURSES_PAGES.LESSON){
        this.addLessonMatching(formData,data,requestMessage)
      }
      else if(data.type===COURSES_PAGES.ASSIGNMENT){
        let canvas = {
          "canvas": localStorage.getItem('canvasdata'),
          "canvasSize":localStorage.getItem('canvasSize'),
        }
        formData.append("stage_type", ACTIONS.ADD_MATCHING_Q);
        formData.append("assignment_id", this.state.assignmentId.toString());  
        formData.append("stage_number", JSON.stringify(this.state.currentStageCount+1));
        if(canvas.canvas!==null){
          formData.append("canvas_data", JSON.stringify(canvas));
        }
        const options = data.pairs
        for(let i=0;i<options.length;i++){
          formData.append("options[][order_no]", (i+1).toString());
          formData.append("options[][pair_answer]", options[i].pair_answer);
          formData.append("options[][pair_question]", options[i].pair_question);
          formData.append("options[][pair_correct_answer]", options[i].pair_correct_answer);
        }
        formData.append("title", data.title);
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
        this.apiAddMatchingQForAssignment = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addMatchingQApiForAssignment}`
        );
      }
      else if(data.type===COURSES_PAGES.QUIZ){ 
        this.handleQuizTypeForMatchingQ(data);
        return true;
      }else {
        return false;
      }
  
      this.addStageNumber(formData,data)
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POST
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    return true;
  }

  handleQuizTypeForMatchingQ(data: { countdown_timer:string,point: string, double_point: string, type: string, title: string, id?: number, image?:string, pairs: { pair_question: string, pair_answer: string, pair_correct_answer: string }[] }) {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const formData = new FormData();
    formData.append("stage_number", JSON.stringify(this.state.currentStageCount + 1));
    formData.append("point", data.point);
    formData.append("double_point", data.double_point);
    formData.append("stage_type", ACTIONS.ADD_MATCHING_Q);
    formData.append("quiz_id", this.state.assignmentId.toString());
    data.image && formData.append("background_image", data.image)
    let canvas = {
      "canvas": localStorage.getItem('canvasdata'),
      "canvasSize":localStorage.getItem('canvasSize'),
    }
    if(canvas.canvas!==null){
      formData.append("canvas_data", JSON.stringify(canvas));
    }
    const options = data.pairs;
    for (let infodata = 0; infodata < options?.length; infodata++) {
      formData.append("options[][order_no]", (infodata + 1).toString());
      formData.append("options[][pair_answer]", options[infodata].pair_answer);
      formData.append("options[][pair_question]", options[infodata].pair_question);
      formData.append("options[][pair_correct_answer]", options[infodata].pair_correct_answer);
    }
    formData.append("title", data.title);
    formData.append("countdown_timer", data.countdown_timer);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    this.apiAddMatchingQForQuiz = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.matchingApiQuiz}`);
    this.addStageNumber(formData, data);
    const header = {
      token: window.localStorage.getItem("authToken"),
    };
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeleteStageResponse=(apiRequestCallId: string)=>{
     if (apiRequestCallId == this.deleteStageApiId){
        this.getStages(this.state.lessonId,this.state.selectedView)
      }
      if (apiRequestCallId == this.updateStageApiId || apiRequestCallId == this.updateSubStageApiId){
        this.getStages(this.state.lessonId,this.state.selectedView)
      }
    
      
    
  }
  deleteStage = (id:number,type:string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteStageApiId = requestMessage.messageId;
    if(this.state.selectedView == COURSES_PAGES.LESSON){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteStageFromLesson}?lesson_stage_id=${id}`
      );
    }
    else if(this.state.selectedView == COURSES_PAGES.QUIZ){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deletStageApiEndpoint}?quiz_markup_tool_id=${id}`
      );
    }
    else if(this.state.selectedView == COURSES_PAGES.ASSIGNMENT){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteStageFromAssignment}?id=${id}`
      );
    }
        
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  changeBodyonType = (stage:any,stageNumber:string,requestMessage:Message)=>{
    console.log("@@@@@@@@@@ghjsegjfkerg",stage)
    if(this.state.selectedView==COURSES_PAGES.LESSON){
      const body={
        stage_number: stageNumber ,
        title: stage.attributes.title
      } 
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateLessonStage}?id=${stage.id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PUT
      );
    }
    else if (this.state.selectedView==COURSES_PAGES.ASSIGNMENT){
      const body={
        id: stage.attributes.assignment_stage_id,
        assignment_id: stage.attributes.assignments_id,
        stage_number:stageNumber,
        stage_type:stage.attributes.assignment_stage_name
      } 
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateAssignmentStage}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PATCH
      );
    }
    else if (this.state.selectedView==COURSES_PAGES.QUIZ){
      const formData= new FormData(); 
      if(stage.stageType== "matching_question"){
        formData.append("stage_type", "matching_question")  
      } else if (stage.stageType== "MCQ"){
        formData.append("stage_type", "MCQ")  
      }
      else if (stage.stageType== "polling_question"){
        formData.append("stage_type", "polling_question")  
      }
      else if (stage.stageType== "open_ended_question"){
        formData.append("stage_type", "open_ended_question")  
      }
      formData.append("title", stage.title);
      formData.append("stage_number", stageNumber);
      formData.append("id", stage.id);
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateQuizEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putMethod
      );
    }
  }


  handleStageAddedQuizResponse( apiRequestCallId: string,
    responseJson: {data:{attributes:{quiz_stage_name:string}},errors:string, error: string},
    errorReponse: string){
      
      if(apiRequestCallId != null && (apiRequestCallId === this.apiaddMcqStageForQuiz || apiRequestCallId === this.apiaddPollStageforQuiz || apiRequestCallId === this.apiaddOpenEndedStageForQuiz || apiRequestCallId === this.apiAddMatchingQForQuiz)){
       
        if(responseJson.data){
          let typeStages= responseJson.data.attributes?.quiz_stage_name;
          if (Array.isArray(responseJson.data)) {
            responseJson.data.forEach((data: any)=>{
              this.addDefaultStage(data)
            })
          }else if(typeStages === "polling_question"){
            this.addPOLLStage(responseJson)
          }else if(typeStages === "MCQ"){
            this.addMCQStage(responseJson)
          }
          else if(typeStages !== "MCQ" && typeStages !== "polling_question"){
            this.addDefaultStage(responseJson.data)
          }
        }
        if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
          this.setState({ isLimitModalOpen: true,isLimitError:true })
        }
      }
  }
  reorderStage = (stage:any,stageNumber : string,isMainStage:boolean = true) => {
    const header : { [key: string]: string | null }= {
      token: window.localStorage.getItem("authToken"),
    };

    if (this.state.selectedView==COURSES_PAGES.LESSON) {
      header["Content-Type"] = configJSON.validationApiContentType;
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateStageApiId = requestMessage.messageId;

    this.changeBodyonType(stage,stageNumber,requestMessage)
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    if(isMainStage){
      const thisParentChilds = this.state.stages.filter((eachStage)=>eachStage.stageNumber.toString().startsWith(stage.stageNumber.toString()) && eachStage.stageNumber != stage.stageNumber.toString()).sort((a, b) => b.stageNumber.localeCompare(a.stageNumber));
      if(thisParentChilds.length>0){
        thisParentChilds.forEach((eachStage:any)=>{
          
          const requestMessageSub = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.updateSubStageApiId = requestMessageSub.messageId;
      
          requestMessageSub.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          this.changeBodyonType(eachStage,stageNumber+eachStage.stageNumber.toString().slice(stage.stageNumber.toString().length) ,requestMessageSub)
          runEngine.sendMessage(requestMessageSub.id, requestMessageSub);
        })  
      }
    }
    return true;
  }

  sendSaveLessonRequest = (endpoint: string, body: any, method: string, header: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  
    this.saveLeesonLibApiId = requestMessage.messageId;
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  saveLesson = (saveToPublic: boolean, saveToDrive: boolean) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
  
    const body = {
      "type": this.state.selectedView.toLowerCase(),
      "id": this.state.lessonId
    };
  
    if (saveToDrive) {
      if (this.state.isSignedIn) {
        this.setState({saveToDrive:true})
        this.sendSaveLessonRequest(
          `${configJSON.saveLessonPublicLib}`,
          body,
          configJSON.PATCH,
          header
        );
      } else {
        this.handleAuthClick();
      }
    }
  
    if (saveToPublic) {
      this.sendSaveLessonRequest(
        `${configJSON.saveLessonPublicLib}`,
        body,
        configJSON.PATCH,
        header
      );
    }
    
    if (!saveToPublic && !saveToDrive) {
      this.setState({
        showSaveSuccess:true,
      })
    }

  };
  
  saveModalClose=()=>{
    this.setState({saveModalOpen:false,showSaveSuccess:false})
  }
  saveModalOpen=()=>{
    this.setState({saveModalOpen:true,showSaveSuccess:false})
  }

  redirectToLibrary = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TeacherLibrary");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleClientLoad = () => {
    gapi.load('client:auth2', this.initClient);
  };

  initClient = () => {
    gapi.client.init({
      apiKey: configJSON.WebAPIKey,
      clientId: configJSON.WebClienctID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    }).then(() => {
      gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
      this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    });
  };
  updateSigninStatus = (isSignedIn: boolean) => {
    if (isSignedIn) {
      this.setState({isSignedIn: true})
    }
  };

  handleAuthClick = () => {
    gapi.auth2.getAuthInstance().signIn();
  };

  handleCloseLimitSnackbar = () => {
    this.setState({
      isLimitModalOpen: false
    })
  }

  handleActiveSiled = (index : string) => {
     this.setState({isActive : index})
   }


  handleDoublePointActive = (index : string) => {
    this.setState({isDoublePointActve : index})
  }

  handleOpenEndedChange = (value:boolean, name:string) => {
  this.setState({
    openEndedState: {
      ...this.state.openEndedState,
      [name]: value
    }
  });
 };

 handleOpenEndedClick = (values:Values) => {
  this.setState({
    openEndedState: values
  });
 };

 handleMcqChange = (value:boolean, name:string) => {
  this.setState({
    mcqSettingState: {
      ...this.state.mcqSettingState,
      [name]: value
    }
  });
 };

 handleMcqClick = (values:Values) => {
  this.setState({
    mcqSettingState: values
  });
 };

 handlePollChange = (value: boolean, name: string) => {
  this.setState({
    pollSettingState: {
      ...this.state.pollSettingState,
      [name]: value
    }
  });
};

handlePollClick = (values:Values) => {
  this.setState({
    pollSettingState: values
  });
 };

 handleMatchClick = (values:Values) => {
  this.setState({
    matchSetState: values
  });
 };

handleMatchChange = (value: boolean, name: string) => {
  this.setState({
    matchSetState: {
      ...this.state.matchSetState,
      [name]: value
    }
  });
};

updateQuizMusic =(isMusic:boolean)=> {
  const header = {
    token: localStorage.getItem("authToken"),
    "Content-Type": configJSON.validationApiContentType,
  };

  const httpBody = {
    "is_music": isMusic
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiUpdateQuizMusic = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.updateQuiz + `?id=${this.state.lessonId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PATCH"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

setgDrivefile = (file: File) => {
  this.setState({
    gDrivefile: file
  })
}

setAddingModal = (addingModal: boolean) => {
  this.setState({
    addingModal,
  })
}

setGDriveModal = (gdriveModal: boolean) => {
  this.setState({
    gdriveModal,
  })
}

setUploadModal = (uploadModal:boolean) => {
  this.setState({
    uploadModal,
  })
}

setOldId = (oldUserId:number) => {
  this.setState({
    oldUserId
  })
}

setAssignOldId = (assignUserId:number) => {
  this.setState({
    assignUserId
  })
}

setInnerModal = (innerModalOpen:boolean) => {
  this.setState({
    innerModalOpen
  })
}

resetError = () => {
  this.setState({
    mistmatchTypeErr:""
  })};

readJSONFile = (file: File): Promise<any> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const fileContent = event.target?.result;
      try{
          let jsonData = JSON.parse(fileContent as string) as LessonData|AssignmentDataIn;
          resolve({jsonData:jsonData,error:false});               
        }
        catch(e){
          resolve({jsonData:"",error:true});  
        }
    };

    reader.readAsText(file);
  });
};

getUpdatedStageNUmberForAssignment = (jsonData:AssignmentDataIn,maxStageNumber:number) => {
  return jsonData.attributes.assignment_markup_tools.data.map((assMarkup)=>{
    const stageMatch = assMarkup.attributes.stage_number.match(/^(\d+)([A-Z]*)$/);
    if (stageMatch) {
      const numberStage = parseInt(stageMatch[1], 10);
      const aplhabetStage = stageMatch[2] || "";
      const newStageNumber = (numberStage + maxStageNumber).toString() + aplhabetStage;        
      return { ...assMarkup, attributes:{...assMarkup.attributes,stage_number: newStageNumber }};
    }
    return assMarkup;
  });
}

getUpdatedStageNumberForLesson = (jsonData:LessonData,maxStageNumber:number) => {
  return jsonData.attributes.lesson_markup_tools_attributes.map((markup)=>{
    const match = markup.stage_number.match(/^(\d+)([A-Z]*)$/);          
    if (match) {
      const numericPart = parseInt(match[1], 10);
      const alphabeticPart = match[2] || "";
      const updatedStageNumber = (numericPart + maxStageNumber).toString() + alphabeticPart;
      return { ...markup, stage_number: updatedStageNumber };
    }
    return markup;
  });
}

getNewFile = async (jsonData:LessonData|AssignmentDataIn,isLesson:boolean,isAssignment:boolean,fileName:string) : Promise<File> => {
  const stages = this.state.stages;
  const parentStages = stages?.filter((stage:any)=>/^\d+$/.test(stage.stageNumber)) ?? []
  const maxStageNumber = parentStages.length>0 ? Math.max(
    ...parentStages.map((stage:any) => parseInt(stage.stageNumber, 10))
  ) : 0;
  if(isAssignment){
    jsonData = jsonData as AssignmentDataIn;
    jsonData.attributes.assignment_markup_tools.data = this.getUpdatedStageNUmberForAssignment(jsonData,maxStageNumber)
  }
  else if(isLesson){
    jsonData = jsonData as LessonData;
    jsonData.attributes.lesson_markup_tools_attributes = this.getUpdatedStageNumberForLesson(jsonData,maxStageNumber)
  }
  const jsonString = JSON.stringify(jsonData);
  const blob = new Blob([jsonString], { type: 'application/json' });
  return new File([blob], fileName, { type: 'application/json' });
}

getFilesGdrive = async (file: File | null) => {
  const config = require("../../../framework/src/config");
  const token = await getStorageData("authToken");  
  if (!file) return;

  const {jsonData,error} = await this.readJSONFile(file);  
  if(error){
    this.setState({ mistmatchTypeErr: `Please import a valid json file` });
    return;
  }
  const isLessonView = this.state.selectedView === "LESSON";
  const isAssignmentView = this.state.selectedView === "ASSIGNMENT";
  const fileType = jsonData.type;

  if (!this.isValidFileType(isLessonView, isAssignmentView, fileType)) {

    this.setState({ mistmatchTypeErr: `You can't import ${fileType} into ${this.state.selectedView.toLowerCase()}` });
    return;
  }
  const updatedFile = await this.getNewFile(jsonData as LessonData|AssignmentDataIn,isLessonView,isAssignmentView,file.name)
  this.setgDrivefile(file)
  const formData = this.createFormData(updatedFile, isLessonView);
  const baseURL = this.getBaseURL(isLessonView, config);
  this.setState({isLoading:true})
  const response = await this.uploadFile(baseURL, token, formData);

  this.setState({ mistmatchTypeErr: "" });
  this.setAddingModal(false);
  this.setGDriveModal(true);
  this.setUploadModal(false);

  this.getStages(isLessonView ? this.state.oldUserId : this.state.assignUserId, isLessonView ? COURSES_PAGES.LESSON : COURSES_PAGES.ASSIGNMENT);

  return await response.json();
};

isValidFileType = (isLessonView: boolean, isAssignmentView: boolean, fileType: string) => {
  return !(isLessonView && fileType !== "lesson") && !(isAssignmentView && fileType !== "assignments");
};

createFormData = (file: string | Blob, isLessonView: boolean) => {
  const formData = new FormData();
  formData.append("json_files[]", file);
  formData.append(isLessonView ? "lesson_id" : "assignment_id", (isLessonView ? this.state.oldUserId : this.state.assignUserId).toString());
  return formData;
};

getBaseURL = (isLessonView: boolean, config: { baseURL: any; }) => {
  return isLessonView
    ? `${config.baseURL}/bx_block_cfcoursecreation23/lesson_markups/upload_json`
    : `${config.baseURL}/bx_block_cfcoursecreation23/assignment_markups/upload_assignment_json`;
};

uploadFile = async (baseURL: RequestInfo, token: any, formData: FormData) => {
  return await fetch(baseURL, {
    method: 'POST',
    headers: { token },
    body: formData
  });
};



  // Customizable Area End
}
